import React, { useContext } from 'react'
import 'materialize-css'
import { Row, Col, Card } from 'react-materialize'

import { GlobalContext } from '../../context/GlobalState';

export const GestioneCittadinoAnagrafica = ({dettaglio, redirect}) => {
    // const debug = process.env.REACT_APP_DEBUG === "true"
    const { cittadino } = useContext(GlobalContext);

    
    if (dettaglio) {   
        let url = '/gestione_cittadino/anagrafica'
        redirect(url);
    }


    return (
        <>
            <h4 style={{marginTop: 0}}>Anagrafica Cittadino</h4>
            <Card>
                <Row>
                    <Col>
                        <b>Codice fiscale:</b>
                    </Col>
                    <Col>
                        {cittadino?.cf}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <b>Nome:</b>
                    </Col>
                    <Col>
                        {cittadino?.nome}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <b>Cognome:</b>
                    </Col>
                    <Col>
                        {cittadino?.cognome}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <b>Indirizzo:</b>
                    </Col>
                    <Col>
                        {cittadino?.indirizzo}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <b>Email:</b>
                    </Col>
                    <Col>
                        {cittadino?.email}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <b>Cellulare:</b>
                    </Col>
                    <Col>
                        {cittadino?.cellulare}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <b>Anno di nascita:</b>
                    </Col>
                    <Col>
                        {cittadino?.annoNascita}
                    </Col>
                </Row>
            </Card>
        </>
    );
}