import React from 'react'
import 'materialize-css'

import { Card, Preloader } from 'react-materialize'

export const LoadingSmall = () => {
    return (
        <Card style={{textAlign: 'center'}}>
            <Preloader
                active
                color="blue" 
            />
        </Card>
    );
}