import React from 'react'
import 'materialize-css'

import { Preloader } from 'react-materialize'

export const LoadingFull = () => {
    return (
        <div 
            style={{
                position: 'fixed', 
                top: 0, 
                bottom: 0, 
                left: 0, 
                right: 0, 
                backgroundColor: 'rgba(0, 0, 0, 0.25)', 
                zIndex: '100',
            }}
        >
            <div 
                style={{
                    position: 'absolute', 
                    top: '50%', 
                    left: '50%', 
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <Preloader
                    active
                    color="blue"
                    size="large" 
                />
            </div>
        </div>
    );
}