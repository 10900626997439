import React, { useContext, useState, useEffect } from 'react'
import 'materialize-css'
import { Row, Col, Card, Button,  DatePicker, ProgressBar } from 'react-materialize'

import { GlobalContext } from '../../context/GlobalState';

import { useTokenCittadino } from "../hooks/useTokenCittadino"


export const GestioneEnteScaricaRendicontazioni = ({ redirect }) => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const { token } = useTokenCittadino()
    const { datiEnte, cittadino } = useContext(GlobalContext);
    const [ loading, setLoading ] = useState(false)

    if (debug) console.log('ente', datiEnte)

    const filterFields = {
        dataDa: "",
        dataA: ""
    }
    
    const [ filter, setFilter ] = useState(filterFields);

    const intestazione = [
        'ID FLUSSO',
        'DATA FLUSSO',
        'STATO FLUSSO',
        'ID PSP FLUSSO',
        'NOME PSP FLUSSO',
        'DATA REGOLAMENTO',
        'IMPORTO TOTALE',
        'NUMERO PAGAMENTI',
        'ID DOMINIO',
        'NOME DOMINIO',
        'ID PENDENZA',
        'DESCRIZIONE PENDENZA',
        'IUV',
        'IUR',
        'INDICE',
        'IDENTIFICATIVO DEBITORE',
        'NOME DEBITORE',
        'SERVIZIO',
        'CAUSALE',
        'ANNO DI RIFERIMENTO',
        'IMPORTO PENDENZA',
        'IMPORTO PAGATO',
        'IMPORTO INCASSATO',
        'DATA CARICAMENTO',
        'DATA VALIDITA',
        'DATA SCADENZA',
        'DATA PAGAMENTO',
        'STATO'
    ]


    const updateDate = (e) => {
        let field = e.target.id.split('_')[1]
        let value = e.target.value

        if (debug) console.log(field, value)

        setFilter({
            ...filter,
            [field]: value
        })
    }


    const showDate = (date) => {
        let result = ""

        if (date) {
            let dd = date.getDate().toString().padStart(2, '0')
            let mm = date.getMonth()
            mm += 1
            mm = mm.toString().padStart(2, '0')
            let yyyy = date.getFullYear().toString()

            result = dd + '/' + mm + '/' + yyyy
        }

        return result
    }


    const stampaCsv = (csv) => {
        let csvText = ""

        for (let i = 0; i < csv.length; i++) {
    
            let line = ""
            let row = csv[i]
    
            row.forEach(item => {
                let text = item !== undefined ? item : ""
                line += ('"' + text + '"' + ';')
            });
    
            line += "\r\n"
    
            csvText += line
        }
    
        if (debug) console.log(csvText)
        var downloadLink = document.createElement("a");
        var blob = new Blob(["\ufeff", csvText]);
        var url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "rendicontazione.csv";
    
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);  

        setLoading(false)
    }


    const compilaCSV = (dati) => {
        let csv = []
        let csvData = []
        let keys = Object.keys(dati)

        keys.forEach(key => {
            let datiFlusso = dati[key]

            for (let i = 0; i < datiFlusso.rendicontazioni.length; i++) {
                let rowData = {}
    
                rowData['ID FLUSSO'] = datiFlusso.idFlusso
                rowData['DATA FLUSSO'] = datiFlusso.dataFlusso
                rowData['STATO FLUSSO'] = datiFlusso.stato
                rowData['ID PSP FLUSSO'] = datiFlusso.idPsp
                rowData['NOME PSP FLUSSO'] = datiFlusso.ragioneSocialePsp
                rowData['DATA REGOLAMENTO'] = datiFlusso.dataRegolamento
                rowData['IMPORTO TOTALE'] = datiFlusso.importoTotale
                rowData['NUMERO PAGAMENTI'] = datiFlusso.numeroPagamenti
                rowData['ID DOMINIO'] = datiFlusso.idDominio
                rowData['NOME DOMINIO'] = datiFlusso.ragioneSocialeDominio
                rowData['ID PENDENZA'] = datiFlusso.rendicontazioni[i]?.riscossione?.vocePendenza?.idVocePendenza
                rowData['DESCRIZIONE PENDENZA'] = datiFlusso.rendicontazioni[i]?.riscossione?.vocePendenza?.descrizione
                rowData['IUV'] = datiFlusso.rendicontazioni[i]?.iuv
                rowData['IUR'] = datiFlusso.rendicontazioni[i]?.iur
                rowData['INDICE'] = datiFlusso.rendicontazioni[i]?.indice
                rowData['IDENTIFICATIVO DEBITORE'] = datiFlusso.rendicontazioni[i]?.riscossione?.vocePendenza?.pendenza?.soggettoPagatore?.identificativo
                rowData['NOME DEBITORE'] = datiFlusso.rendicontazioni[i]?.riscossione?.vocePendenza?.pendenza?.soggettoPagatore?.anagrafica
                rowData['SERVIZIO'] = datiFlusso.rendicontazioni[i]?.riscossione?.vocePendenza?.pendenza?.tipoPendenza?.descrizione
                rowData['CAUSALE'] = datiFlusso.rendicontazioni[i]?.riscossione?.vocePendenza?.pendenza?.causale
                rowData['ANNO DI RIFERIMENTO'] = datiFlusso.rendicontazioni[i]?.riscossione?.vocePendenza?.pendenza?.annoRiferimento
                rowData['IMPORTO PENDENZA'] = datiFlusso.rendicontazioni[i]?.riscossione?.vocePendenza?.pendenza?.importo
                rowData['IMPORTO PAGATO'] = datiFlusso.rendicontazioni[i]?.riscossione?.vocePendenza?.pendenza?.importoPagato
                rowData['IMPORTO INCASSATO'] = datiFlusso.rendicontazioni[i]?.riscossione?.vocePendenza?.pendenza?.importoIncassato
                rowData['DATA CARICAMENTO'] = datiFlusso.rendicontazioni[i]?.riscossione?.vocePendenza?.pendenza?.dataCaricamento
                rowData['DATA VALIDITA'] = datiFlusso.rendicontazioni[i]?.riscossione?.vocePendenza?.pendenza?.dataValidita
                rowData['DATA SCADENZA'] = datiFlusso.rendicontazioni[i]?.riscossione?.vocePendenza?.pendenza?.dataScadenza
                rowData['DATA PAGAMENTO'] = datiFlusso.rendicontazioni[i]?.riscossione?.vocePendenza?.pendenza?.dataPagamento
                rowData['STATO'] = datiFlusso.rendicontazioni[i]?.riscossione?.vocePendenza?.pendenza?.stato

                csvData.push(rowData)
            }
        });

        csv.push(intestazione)

        csvData.forEach(row => {
            let rowParsed = []

            intestazione.forEach(key => {
                rowParsed.push(row[key])
            })

            csv.push(rowParsed)
        })

        if (debug) console.log(csv)

        stampaCsv(csv)
    } 


    const scaricaDati = async () => {
        let response = await fetch(process.env.REACT_APP_SERVER_URL + 'ente/scaricaRendicontazioni/' + datiEnte.idDominio, {
            method: 'POST',
            body: JSON.stringify({ token, filter, richiedente: cittadino.cf })
        })

        let res = await response.json()

        if (debug) console.log("FLUSSO RENDICONTAZIONI", res)

        if (res.valid) {
            compilaCSV(res.result)
        } else {
            redirect("/")
        }
    }


    const startLoading = () => {
        setLoading(true)       
    }


    useEffect(() => {
        if (loading) {
            scaricaDati()
        }
    }, [loading])


    return (
        <>
            <Card>
                <Row>
                    <Col s={12} m={12} l={6}>
                        <DatePicker s={12} m={12} label='Data da' id="field_dataDa" onChange={(newDate) => {updateDate({target:{id: "field_dataDa", value: newDate}})}} value={showDate(filter.dataDa)} />
                    </Col>
                    <Col s={12} m={12} l={6}>
                        <DatePicker s={12} m={12} label='Data a' id="field_dataA" onChange={(newDate) => {updateDate({target:{id: "field_dataA", value: newDate}})}} value={showDate(filter.dataA)} />
                    </Col>
                    <Col s={12} m={12}>
                        <Button style={{width: '100%'}} onClick={startLoading} disabled={loading}>SCARICA FLUSSO RENDICONTAZIONE</Button>
                    </Col>
                </Row>
                {
                    loading ?
                    <Row>
                        <Col>
                            <ProgressBar />
                        </Col>
                    </Row> :
                    <></>
                }
            </Card>
        </>        
    );
}