import React, { useContext, useState } from 'react'
import readXlsxFile from 'read-excel-file'
import 'materialize-css'
import { Card, Row, Col, TextInput, Button, Select } from 'react-materialize'
import { GlobalContext } from '../../context/GlobalState'
// custom hooks
// import { useTokenCittadino } from "../hooks/useTokenCittadino"
import { useCreaFlussoGovPay } from "../hooks/useCreaFlussoGovPay"

export const GestioneEnteConvertiFlusso = ({ dettaglio, redirect }) => {
    const debug = process.env.REACT_APP_DEBUG === "true"

    if (dettaglio) {   
        let url = '/gestione_ente/converti_flusso'
        redirect(url);
    }

    const [ servizio, setServizio ] = useState({})
    const [ message, setMessage ] = useState('')

    const creaFlussoGovPay = useCreaFlussoGovPay()
    
    const { datiEnte } = useContext(GlobalContext);

    if (debug) console.log('DATI ENTE', datiEnte)


    const readServizio = (e) => {
        let id = e.target.value
        let descrizione = e.target.selectedOptions[0].text
        let servizio = { id, descrizione }

        setServizio(servizio)
    }


    const readFile = (e) => {
        let file = e.target.files[0]
        let fileExtension = file ? file.name.toLowerCase().split('.').pop() : ''

        switch (fileExtension.toLowerCase()) {
            case 'csv':
                if (debug) console.log('file CSV')
                setMessage('USARE UN FILE DI TIPO .XLSX COME COME ORIGINE')
                break
            case 'xls':
                setMessage('USARE UN FILE DI TIPO .XLSX COME COME ORIGINE')
                break
            case 'xlsx':
                if (debug) console.log('file EXCEL')
                setMessage('CONVERSIONE FLUSSO...')
                readXlsxFile(file).then(
                    (rows) => {
                        const { csv, success, message } = creaFlussoGovPay(rows, servizio)

                        console.log(csv, success, message)

                        setMessage(message)

                        if (success) {
                            scaricaFlusso(csv)
                        }
                    }
                )      
                break
            default:
                if (debug) console.log('file non corretto')
                setMessage('USARE UN FILE DI TIPO .XLSX COME COME ORIGINE')
                break
        }
    }


    const scaricaFlusso = (csv) => {   
        if (debug) console.log(csv)
        var downloadLink = document.createElement("a");
        var blob = new Blob(["\ufeff", csv]);
        var url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "flusso_completo.csv";
    
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);  
    }    

    return (
        <>
            <Card>
                <Row>
                    <Col className="center" s={12}>
                        <h1>CONVERTI FLUSSO</h1>
                    </Col>
                </Row>
                <Row>
                    <Col s={12}>
                        <Select id="field_idTipoPendenza" s={12} onChange={readServizio}>
                            <option value="">Tipo Servizio</option>
                            {datiEnte.entrate.filter(item => item.abilitato).map(
                                (item) => (
                                    <option value={item.idEntrata} key={item.idEntrata}>{item.tipoEntrata.descrizione}</option>
                                )
                            )}
                        </Select>
                    </Col> 
                </Row>
                <Row>
                    <Col s={12}>
                        <TextInput s={12} id="fileUpload" label="CARICA FLUSSO DA CONVERTIRE" type="file" onChange={readFile} />
                    </Col>
                </Row>
                <Row>
                    <Col s={12}>
                        <Button onClick={scaricaFlusso}>SCARICA FLUSSO CONVERTITO</Button>
                    </Col>
                </Row>
            </Card>
            { 
                message == ''
                ? <></>
                : <Card>
                    <Row>
                        <Col s={12}>
                            <p>{message}</p>
                        </Col>
                    </Row>
                </Card>
            }
        </>
    );
}