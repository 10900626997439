import React, { useContext, useState } from 'react'
import 'materialize-css'
import { Card, Button, Row, Col, TextInput, DatePicker, Select } from 'react-materialize'

import { GlobalContext } from '../context/GlobalState';


export const CardFiltriEnte = ({tipo, onFilterSaved, idTipiPendenza, selectTipiPendenza}) => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const { ente, datiEnte, cittadino } = useContext(GlobalContext);

    if (debug) console.log("DATI ENTE", datiEnte, cittadino)

    const filterFields = {
        idDominio: ente ? ente : "",
        stato: "",
        dataDa: "",
        dataA: "",
        idTipoPendenza: idTipiPendenza
    }

    const statiPagamento = [
        'IN_CORSO',
        'ESEGUITO',
        'NON_ESEGUITO',
        'ESEGUITO_PARZIALE',
        'ANNULLATO',
        'FALLITO'
    ]

    const statiPendenza = [
        'ESEGUITA',
        'NON_ESEGUITA',
        'ESEGUITA_PARZIALE',
        'ANNULLATA',
        'SCADUTA',
        'INCASSATA',
        'ANOMALA'
    ]

    const statiRendicontazione = [
        'Acquisito',
        'Anomalo',
        'Rifiutato'
    ]

    const statiRiscossione = [
        'RISCOSSA',
        'INCASSATA'
    ]


    const [filter, setFilter] = useState(filterFields)


    const updateField = (e) => {
        let field = e.target.id.split('_')[1]
        let value = e.target.value

        if (field === 'idTipoPendenza') {
            if (value === '') {
                setFilter({
                    ...filter,
                    [field]: idTipiPendenza
                })
            } else {
                setFilter({
                    ...filter,
                    [field]: [value]
                })
            }
        } else {
            setFilter({
                ...filter,
                [field]: value
            })
        }
    }


    const updateDate = (e) => {
        let field = e.target.id.split('_')[1]
        let value = e.target.value

        if (debug) console.log(field, value)

        setFilter({
            ...filter,
            [field]: value
        })
    }


    const showDate = (date) => {
        let result = ""

        if (date) {
            let dd = date.getDate().toString().padStart(2, '0')
            let mm = date.getMonth()
            mm += 1
            mm = mm.toString().padStart(2, '0')
            let yyyy = date.getFullYear().toString()

            result = dd + '/' + mm + '/' + yyyy
        }

        return result
    }


    const saveFilter = () => {
        if(onFilterSaved) {
            onFilterSaved(filter)
        }
    }


    const renderSwitch = (tipo) => {
        switch(tipo) {
            case 'pendenze':
                return <>
                    <Col s={12} m={6} l={3}>
                        <DatePicker s={12} m={12} label='Data da' id="field_dataDa" onChange={(newDate) => {updateDate({target:{id: "field_dataDa", value: newDate}})}} value={showDate(filter.dataDa)} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <DatePicker s={12} m={12} label='Data a' id="field_dataA" onChange={(newDate) => {updateDate({target:{id: "field_dataA", value: newDate}})}} value={showDate(filter.dataA)} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <Select id="field_stato" onChange={updateField}  value={filter.stato} s={12}>
                            <option value="">Stato</option>
                            {statiPendenza.map(
                                (item) => (
                                    <option value={item} key={item}>{item}</option>
                                )
                            )}
                        </Select>
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <TextInput s={12} label="Identificativo Debitore" id="field_idDebitore" onChange={updateField} value={filter.idDebitore} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <TextInput s={12} label="Identificativo Pendenza" id="field_idPendenza" onChange={updateField} value={filter.idPendenza} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <TextInput s={12} label="IUV" id="field_iuv" onChange={updateField} value={filter.iuv} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <Select id="field_idTipoPendenza" onChange={updateField}  value={filter.idTipoPendenza} s={12}>
                            <option value="">Tipo Pendenza</option>
                            {selectTipiPendenza.map(
                                (item) => (
                                    <option value={item.idEntrata} key={item.idEntrata}>{item.tipoEntrata.descrizione}</option>
                                )
                            )}
                        </Select>
                    </Col> 
                    <Col s={12} m={6} l={3}>
                        <br />
                        <Button style={{width: '100%'}} onClick={ saveFilter }>CERCA</Button>
                    </Col>
                </>
            case 'pagamenti':
                return <>
                    <Col s={12} m={6} l={3}>
                        <DatePicker s={12} m={12} label='Data da' id="field_dataDa" onChange={(newDate) => {updateDate({target:{id: "field_dataDa", value: newDate}})}} value={showDate(filter.dataDa)} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <DatePicker s={12} m={12} label='Data a' id="field_dataA" onChange={(newDate) => {updateDate({target:{id: "field_dataA", value: newDate}})}} value={showDate(filter.dataA)} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <Select id="field_stato" onChange={updateField}  value={filter.stato} s={12}>
                            <option value="">Stato</option>
                            {statiPagamento.map(
                                (item) => (
                                    <option value={item} key={item}>{item}</option>
                                )
                            )}
                        </Select>
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <TextInput s={12} label="Identificativo Versante" id="field_versante" onChange={updateField} value={filter.versante} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <TextInput s={12} label="ID Sessione Portale" id="field_idSessionePortale" onChange={updateField} value={filter.idSessionePortale} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <Select id="field_verificato" onChange={updateField}  value={filter.stato} s={12}>
                            <option value="">Verificato</option>
                            <option value="false">NO</option>
                            <option value="true">SI</option>
                        </Select>
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <TextInput s={12} label="Identificativo Debitore" id="field_idDebitore" onChange={updateField} value={filter.idDebitore} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <TextInput s={12} label="Identificativo Pendenza" id="field_idPendenza" onChange={updateField} value={filter.idPendenza} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <br />
                        <Button style={{width: '100%'}} onClick={ saveFilter }>CERCA</Button>
                    </Col>
                </>
            case 'rendicontazioni':
                return <>
                    <Col s={12} m={6} l={3}>
                        <DatePicker s={12} m={12} label='Data da' id="field_dataDa" onChange={(newDate) => {updateDate({target:{id: "field_dataDa", value: newDate}})}} value={showDate(filter.dataDa)} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <DatePicker s={12} m={12} label='Data a' id="field_dataA" onChange={(newDate) => {updateDate({target:{id: "field_dataA", value: newDate}})}} value={showDate(filter.dataA)} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <Select id="field_stato" onChange={updateField}  value={filter.stato} s={12}>
                            <option value="">Stato</option>
                            {statiRendicontazione.map(
                                (item) => (
                                    <option value={item} key={item}>{item}</option>
                                )
                            )}
                        </Select>
                    </Col>    
                    <Col s={12} m={6} l={3}>
                        <Select id="field_incassato" onChange={updateField}  value={filter.incassato} s={12}>
                            <option value="">Incassato</option>
                            <option value="false">NO</option>
                            <option value="true">SI</option>
                        </Select>
                    </Col>           
                    <Col s={12} m={6} l={3}>
                        <br />
                        <Button style={{width: '100%'}} onClick={ saveFilter }>CERCA</Button>
                    </Col>
                </>
            case 'riconciliazioni':
                return <>
                    <Col s={12} m={6} l={3}>
                        <DatePicker s={12} m={12} label='Data da' id="field_dataDa" onChange={(newDate) => {updateDate({target:{id: "field_dataDa", value: newDate}})}} value={showDate(filter.dataDa)} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <DatePicker s={12} m={12} label='Data a' id="field_dataA" onChange={(newDate) => {updateDate({target:{id: "field_dataA", value: newDate}})}} value={showDate(filter.dataA)} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <TextInput s={12} label="SCT" id="field_sct" onChange={updateField} value={filter.sct} />
                    </Col> 
                    <Col s={12} m={6} l={3}>
                        <br />
                        <Button style={{width: '100%'}} onClick={ saveFilter }>CERCA</Button>
                    </Col>
                </>
            case 'riscossioni':
                return <>
                    <Col s={12} m={6} l={3}>
                        <DatePicker s={12} m={12} label='Data da' id="field_dataDa" onChange={(newDate) => {updateDate({target:{id: "field_dataDa", value: newDate}})}} value={showDate(filter.dataDa)} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <DatePicker s={12} m={12} label='Data a' id="field_dataA" onChange={(newDate) => {updateDate({target:{id: "field_dataA", value: newDate}})}} value={showDate(filter.dataA)} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <Select id="field_stato" onChange={updateField}  value={filter.stato} s={12}>
                            <option value="">Stato</option>
                            {statiRiscossione.map(
                                (item) => (
                                    <option value={item} key={item}>{item}</option>
                                )
                            )}
                        </Select>
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <TextInput s={12} label="idA2A" id="field_idA2A" onChange={updateField} value={filter.idA2A} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <TextInput s={12} label="Identificativo Pendenza" id="field_idPendenza" onChange={updateField} value={filter.idPendenza} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <Select id="field_idUnita" onChange={updateField}  value={filter.idUnita} s={12}>
                            <option value="">Unità Operativa</option>
                            {datiEnte.unitaOperative.map(
                                (item) => (
                                    <option value={item.idUnita} key={item.idUnita}>{item.ragioneSociale}</option>
                                )
                            )}
                        </Select>
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <Select id="field_idTipoPendenza" onChange={updateField}  value={filter.idTipoPendenza} s={12}>
                            <option value="">Tipo Pendenza</option>
                            {selectTipiPendenza.map(
                                (item) => (
                                    <option value={item.idTipoPendenza} key={item.idTipoPendenza}>{item.descrizione}</option>
                                )
                            )}
                        </Select>
                    </Col>   
                    <Col s={12} m={6} l={3}>
                        <br />
                        <Button style={{width: '100%'}} onClick={ saveFilter }>CERCA</Button>
                    </Col>
                </>
            default:
                return <>
                    <Col s={12} m={6} l={3}>
                        <DatePicker s={12} m={12} label='Data da' id="field_dataDa" onChange={(newDate) => {updateDate({target:{id: "field_dataDa", value: newDate}})}} value={showDate(filter.dataDa)} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <DatePicker s={12} m={12} label='Data a' id="field_dataA" onChange={(newDate) => {updateDate({target:{id: "field_dataA", value: newDate}})}} value={showDate(filter.dataA)} />
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <br />
                        <Button style={{width: '100%'}} onClick={ saveFilter }>CERCA</Button>
                    </Col>
                </>
        }
    }


    return (
        <Card title="filtra i risultati">
            <Row style={{margin: 0}}>
                {
                    renderSwitch(tipo)
                }
            </Row>
        </Card>
    )
}
