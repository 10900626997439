import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useTokenCittadino } from "./useTokenCittadino"
import { GlobalContext } from '../../context/GlobalState';

export const useCheckToken = (amministrazione) => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const { token } = useTokenCittadino()

    const history = useHistory()

    const {
        cittadino,
        setCittadino
    } = useContext(GlobalContext);


    const checkToken = () => {
        if (debug) console.log('CHECKING TOKEN')
        fetch(process.env.REACT_APP_SERVER_URL + 'cittadino/check_token', {
            method: 'POST',
            body: JSON.stringify({token})
        })
            .then(res => res.json())
            .then(res => {
                if (debug) console.log(res)
                if (res.valid) {
                    if (!cittadino || cittadino?.cf !== res.payload.fiscalNumber) {
                        let data = {
                            nome: res.payload?.given_name,
                            cognome: res.payload?.family_name,
                            cf: res.payload?.fiscalNumber,
                            email: res.payload?.email,
                            cellulare: res.payload?.mobilePhone,
                            annoNascita: res.payload?.dateOfBirth,
                            indirizzo: res.payload?.address,
                            permessi: res.permessi
                        }
                        setCittadino(data)
                    } else if (amministrazione) {
                        if (debug) console.log("PERMESSI", cittadino?.permessi)
                        if (!cittadino?.permessi) {
                            localStorage.clear()
                            history.push('/')
                        }
                    }
                    // salva dati utente nello state
                } else {
                    localStorage.clear()
                    history.push("/")
                }
            })      
    }


    return checkToken
}