import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import 'materialize-css'
import { Container, Row, Col, Card, Button, TextInput } from 'react-materialize'
import { HeaderCittadino } from '../components/HeaderCittadino'
import { SelezionaProvincia } from '../components/SelezionaProvincia'
import { SelezionaEnte } from '../components/SelezionaEnte'
import { SelezionaServizio } from '../components/SelezionaServizio'
import { PageFooter } from '../components/PageFooter'
import { useTokenCittadino } from "../components/hooks/useTokenCittadino"
import { useCheckToken } from "../components/hooks/useCheckToken"

import { GlobalContext } from '../context/GlobalState';


export const HomePage = () => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const history = useHistory()
    const { token } = useTokenCittadino()
    const checkToken = useCheckToken()

    const {
        strutturaSelettori, 
        setStrutturaSelettori, 
        provincia, 
        ente, 
        servizio,
        setNuovoAvviso,
        carrello,
        cittadino
    } = useContext(GlobalContext);

    const avvisoStruct = {
        idDominio: "",
        numeroAvviso: "",
        idDebitore: ""
    }

    const [avviso, setAvviso] = useState(avvisoStruct)


    const loadSelettori = () => {
        if (debug) console.log("strutturaSelettori PRE", strutturaSelettori)

        let richiedente = cittadino ? cittadino.cf : 'anonimo'
    
        fetch(process.env.REACT_APP_SERVER_URL + 'getStrutturaSelettori?richiedente=' + richiedente)
            .then(response => response.json())
            .then(data => {
                if (debug) console.log("strutturaSelettori POST", data)
                setStrutturaSelettori(data);
            })
    }


    useEffect(() => {
        if (strutturaSelettori === null) {
            loadSelettori()
        }
    }, [strutturaSelettori, loadSelettori])


    useEffect(() => {
        if (token) {
            checkToken()
        } else {
            localStorage.clear()
            history.push("/")
        }
    }, [token])
        

    const updateAvviso = (e) => {
        let field = e.target.id
        let value = e.target.value
        
        setAvviso({
            ...avviso,
            [field]: value
        })
    }


    const disableSendAvviso = () => {
        let canGo = true
        
        if (avviso.idDominio.length !== 11) canGo = false
        if (avviso.idDebitore.length !== 11 && avviso.idDebitore.length !== 16) canGo = false
        if (avviso.numeroAvviso.length !== 18) canGo = false
        
        return !canGo
    }


    const inviaAvviso = () => {
        setNuovoAvviso(avviso)
        history.push('/pagamento/verifica_avviso')
    }


    const disableSendPagamento = () => {
        let canGo = provincia && ente && servizio
        return !canGo
    }


    const sendPagamento = () => {
        let url = "/pagamento"
        let p = provincia !== "" ? "/"+provincia : ""
        let e = ente !== "" ? "/"+ente : ""
        let s = servizio !== "" ? "/"+servizio : ""

        history.push(url+p+e+s)        
    }


    return (
        <Container>
            <HeaderCittadino history={history} />
            <Row>
                <Col s={12}>
                    <Card>
                        <p>Al fine di agevolare il rispetto delle scadenze e degli adempimenti previsti all’attuazione della Misura del PNRR 1.4.3 Adozione PagoPA, si avvisano gli Enti che è stata predisposta una ulteriore modalità di creazione dei servizi di pagamento sul nodo, accessibile dal proprio back-office.<br />Con questa nuova procedura, garantendo comunque il consueto pieno supporto prestato, si dà all'Ente la piena autonomia nonché la possibilità di designare uno o più referenti abilitati all’attivazione dei servizi di pagamento.</p>
                        <hr />
                        <p>Se hai ricevuto un avviso di pagamento della tassa automobilistica clicca <a href="https://bollo.aci.it/#/main/home" target="_blank"><b>QUI</b></a></p>
                        <hr />
                        <p>Se hai ricevuto un avviso di pagamento, usa il Codice Avviso e il Codice Fiscale dell'Ente in esso contenuti insieme al tuo Codice Fiscale per pagare su PagoRA.<br />Utilizzando SPID potrai accedere alla tua area personale per verificare lo stato dei tuoi pagamenti.</p>
                        <hr />
                        <p>Su PagoRA puoi effettuare anche pagamenti spontanei verso le PA aderenti: puoi fare tutto on line.<br />Utilizzando SPID puoi anche creare e stampare un avviso di pagamento che ti servirà per pagare presso i centri autorizzati.</p>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col s={12} l={6}>
                    <Card>
                        <p><strong>PAGAMENTO SPONTANEO DI UN SERVIZIO</strong></p>
                        <Row>
                            <Col s={12}>
                                Vuoi effettuare un pagamento spontaneo?<br />
                                Seleziona qui sotto l'Ente e il Servizio e fai tutto on line!<br />
                            </Col>
                        </Row>
                        <Row>
                            <Col s={12}>
                                <SelezionaProvincia/>
                            </Col>
                            <Col s={12}>
                                <SelezionaEnte/>
                            </Col>
                            <Col s={12}>
                                <SelezionaServizio/>
                            </Col>
                        </Row>
                        <Row>
                            <Col s={12}>
                                {
                                    carrello.length > 4 ?
                                    <Button style={{width: '100%'}} disabled>CARRELLO PIENO</Button> :
                                    <Button style={{width: '100%'}} onClick={sendPagamento} disabled={disableSendPagamento()}>Procedi al Pagamento</Button>
                                }
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col s={12} l={6}>
                    <Card>
                        <p><strong>PAGAMENTO DI UN AVVISO</strong></p>
                        <Row>
                            <Col s={12}>
                                Hai ricevuto un avviso di pagamento?<br />
                                Inserisci i dati dell'avviso qui sotto per pagarlo on line!
                            </Col>
                        </Row>
                        <Row>
                            <Col s={12}>
                                <TextInput s={12} label="Codice Fiscale Soggetto Debitore (*)" id="idDebitore" value={avviso.idDebitore} onChange={updateAvviso} required validate />
                            </Col>
                            <Col s={12}>
                                <TextInput s={12} label="Codice Fiscale Ente Creditore (*)" id="idDominio" value={avviso.idDominio} onChange={updateAvviso} required validate/>
                            </Col>
                            <Col s={12}>
                                <TextInput s={12} label="Codice Avviso (*)" id="numeroAvviso" value={avviso.numeroAvviso} onChange={updateAvviso} required validate/>
                            </Col>
                        </Row>
                        <Row>
                            <Col s={12}>
                                {
                                    carrello.length > 4 ?
                                    <Button style={{width: '100%'}} disabled>CARRELLO PIENO</Button> :
                                    <Button style={{width: '100%'}} onClick={inviaAvviso} disabled={disableSendAvviso()}>Paga avviso</Button>
                                }
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col s={12}>
                    <Card>
                        <h5 style={{marginTop: 0}}>Cos'è PagoPA Regione Abruzzo?</h5>
                        <p>È il luogo dove puoi pagare bollette, tasse o altri pagamenti dovuti alla Pubblica Amministrazione regionale aderente al servizio in modo facile, intuitivo e veloce, senza trascurare la sicurezza delle transazioni.</p>
                        <p>Puoi farlo con un accesso libero oppure utilizzando le credenziali SPID.</p>
                        <p>Perché autenticarsi? Perché farlo è gratuito e ti permette di accedere allo storico dei tuoi pagamenti che hai effettuato sulla piattaforma, accedere alle tue pendenze e scaricare le relative ricevute.</p>
                        <p>PagoPA Regione Abruzzo aderisce al circuito nazionale PagoPA che è un'iniziativa che consente a cittadini e imprese di pagare in modalità elettronica la Pubblica Amministrazione.</p>                     
                    </Card>
                </Col>
                <Col s={12}>
                    <Card>
                        <h5 style={{marginTop: 0}}>Integrazione con il sistema PagoRA</h5>
                        <p>Per gli enti che desiderano integrare i propri applicativi con il sistema PagoRA sono a disposizione delle API specifiche e tutta l'assistenza necessaria per utilizzarle al meglio.</p>
                        <br />
                        <p>Tutta la documentazione sulle API di integrazione è disponibile <a href="https://govpay.readthedocs.io/it/latest/integrazione/api/index.html" target="_blank"><b>QUI</b></a></p>                   
                    </Card>
                </Col>
            </Row>
            <PageFooter />
        </Container>
    )
}
