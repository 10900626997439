import React, { useContext, useState, useEffect } from 'react'
import readXlsxFile from 'read-excel-file'
import 'materialize-css'
import { Card, Row, Col, TextInput, Button, Pagination, Icon } from 'react-materialize'
import { GlobalContext } from '../../context/GlobalState'
// custom hooks
import { useTokenCittadino } from "../hooks/useTokenCittadino"
// import { useCreaFlussoGovPay } from "../hooks/useCreaFlussoGovPay"

import { LoadingSmall } from '../loadingSmall';
import { LoadingFull } from '../loadingFull';

// import { CardFiltriEnte } from '../CardFiltriEnte'


export const GestioneEnteFlussiMassiviPendenze = ({ dettaglio, operazioni, redirect }) => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const { token } = useTokenCittadino()

    const [ filter, setFilter ] = useState(null);
    const [ message, setMessage ] = useState('')
    const [ flussi, setFlussi ] = useState(null)
    const [ flusso, setFlusso ] = useState(null)
    const [ ops, setOps ] = useState(null)
    const [ fileName, setFileName ] = useState(null)
    const [ fileContent, setFileContent ] = useState(null)
    const [ codiceEnte, setCodiceEnte ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    
    const { datiEnte, cittadino } = useContext(GlobalContext);

    const reader = new FileReader()
    reader.onload = async (e) => {
        const fileContent = (e.target.result)
        console.log('fileContent', fileContent)
        setFileContent(fileContent)
        // sendFile()
    }

    if (debug) console.log('DATI ENTE', datiEnte)


    if (!codiceEnte) {
        setFlussi(null)
        setCodiceEnte(datiEnte?.idDominio)
    } else if (codiceEnte !== datiEnte?.idDominio) {
        setCodiceEnte(null)
    }

    if (ops && !operazioni) {
        setOps(null)
    }


    const getFlussi = async (filterData, page) => {
        setLoading(true)
        let response = await fetch(process.env.REACT_APP_SERVER_URL + 'ente/pendenze/tracciati', {
            method: 'POST',
            body: JSON.stringify({ method: 'get', token, filter: filterData, page, idDominio: datiEnte.idDominio, richiedente: cittadino.cf })
        })

        let res = await response.json()

        if (debug) console.log('FLUSSI', res)
        if (res.valid) {
            if (flussi !== res.result) setFlussi(res.result)
            setLoading(false)
        } else {
            redirect("/")
        }
    }


    const getDettaglioFlussi = async () => {
        if (!flusso || flusso?.id != dettaglio) {
            let response = await fetch(process.env.REACT_APP_SERVER_URL + 'ente/pendenze/tracciati/' + dettaglio, {
                method: 'POST',
                body: JSON.stringify({method: 'get', token, richiedente: cittadino.cf})
            })

            let res = await response.json()

            if (debug) console.log(res); 
            if (res.valid) {
                setFlusso(res.result); 
            } else {
                redirect("/")
            }
        }
    }


    const getOps = async (filterData, page) => {
        let response = await fetch(process.env.REACT_APP_SERVER_URL + 'ente/pendenze/tracciati/' + dettaglio + '/operazioni', {
            method: 'POST',
            body: JSON.stringify({ method: 'get', token, filter: filterData, page, idDominio: datiEnte.idDominio, richiedente: cittadino.cf })
        })

        let res = await response.json()

        if (debug) console.log('OPERAZIONI', res)
        if (res.valid) {
            if (ops !== res.result) setOps(res.result)
        } else {
            redirect("/")
        }
    }


    useEffect(() => {
        if (token) {
            if (dettaglio) {
                if (dettaglio && flusso?.id != dettaglio) {
                    getDettaglioFlussi()
                }
            } else if (!dettaglio && !flussi) {
                getFlussi(filter, 1)
            }
        } else {
            redirect("/")
        }
    }, [token, redirect, dettaglio, getFlussi, getDettaglioFlussi])


    useEffect(() => {
        if (operazioni === 'operazioni') {
            if (!ops) {
                getOps({}, 1)
            }
        }
    }, [operazioni, ops, getOps])


    const readFile = async (e) => {
        let file = e.target.files[0]

        if (file) {
            let fileName = file.name
            let fileExtension = fileName.toLowerCase().split('.').pop()
            console.log('fileName', fileName)
        
            switch (fileExtension.toLowerCase()) {
                case 'csv':
                    if (debug) console.log('file CSV')
                    setMessage('FORMATO FILE CORRETTO')
                    setFileName(fileName)
                    reader.readAsText(file)
                    break
                default:
                    if (debug) console.log('file non corretto')
                    setMessage('USARE UN FILE DI TIPO .CSV COME COME ORIGINE')
                    break
            }
        } else {
            setMessage('')
            setFileName(null)
            setFileContent(null)
        }
    }

    const sendFile = async () => {
        let url = process.env.REACT_APP_SERVER_URL + 'ente/pendenze/tracciati/'
        if (debug) console.log('sendFile', url, fileName, fileContent)

        let response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({ token, method: 'post', fileName, fileContent, idDominio: datiEnte.idDominio, richiedente: cittadino.cf})
        })

        let responseJson = await response.json()

        if (debug) console.log(responseJson)

        setMessage('')
        setFileName(null)
        setFileContent(null)

        getFlussi({}, 1)
    }


    const visualizzaDettaglio = (idDettaglio) => {
        let url = '/gestione_ente/flussi_pendenze/' + idDettaglio
        redirect(url)
    }


    const pulsantiDettaglio = async (option) => {
        let url = process.env.REACT_APP_SERVER_URL + 'ente/pendenze/tracciati/' + dettaglio + '/' + option
        if (debug) console.log(url, datiEnte.idDominio)

        let response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({method: 'get', token, idDominio: datiEnte.idDominio, richiedente: cittadino.cf})
        })

        let res = await response.json()

        if (debug) console.log('option', res); 

        switch (option) {
            case 'richiesta':
                scaricaCsv(flusso.nomeFile, res.result)
                break
            case 'esito':
                scaricaCsv('esito_' + flusso.nomeFile, res.result)
                break
            case 'operazioni':
                redirect('/gestione_ente/flussi_pendenze/' + dettaglio + '/operazioni')
                break
            case 'stampe':
                scaricaZip(res.result)
                break
        }
    }


    const scaricaCsv = (fileName, content) => {
        if (debug) console.log(content)
        var downloadLink = document.createElement("a");
        var blob = new Blob(["\ufeff", content]);
        var url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = fileName + ".csv";
    
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink); 
    } 


    const scaricaZip = (fileName) => {
        let url = process.env.REACT_APP_SERVER_URL + 'zip_flussi/' + fileName
        if (debug) console.log(url)
        window.open(url)
    }


    const filterSaved = (filter) => {
        if (debug) console.log('filter saved', filter)
        getFlussi(filter, 1)
    } 
    

    return (
        <>
            { loading ? <LoadingFull /> : null }
            {
                dettaglio ?
                (
                    ops ?
                    <>
                        <Row>
                            <Col>
                                <Button onClick={() => redirect('/gestione_ente/flussi_pendenze/' + dettaglio)}>indietro</Button>
                            </Col>
                            <Col>
                                <h4 style={{marginTop: 0}}>Operazioni flusso pendenze</h4>
                            </Col>
                        </Row>
                        <Pagination
                            activePage={ops.pagina}
                            items={ops.numPagine}
                            leftBtn={<Icon>chevron_left</Icon>}
                            maxButtons={10}
                            rightBtn={<Icon>chevron_right</Icon>}
                            onSelect={(page) => getOps(filter, page)}
                        />
                        {
                            ops?.risultati?.map((item) => <Card key={item.identificativoPendenza}>
                                <Row>
                                    <Col>
                                        <b>Identificativo pendenza:</b> {item.identificativoPendenza}<br />
                                        <b>Tipo operazione:</b> {item.tipoOperazione}<br />
                                        <b>Stato:</b> {item.stato}<br />
                                        <b>Ente Creditore:</b> {item.enteCreditore.ragioneSociale}<br />
                                        <b>Soggetto Pagatore:</b> {item.soggettoPagatore.anagrafica}<br />
                                        <b>Causale:</b> {item.richiesta.causale}<br />
                                        <b>Importo:</b> {item.richiesta.importo}<br />
                                        <br />
                                        <Button disabled>DETTAGLI</Button>
                                    </Col>
                                </Row>
                            </Card>)
                        }
                    </> :
                    <>
                        <Row>
                            <Col>
                                <Button onClick={() => redirect('/gestione_ente/flussi_pendenze')}>indietro</Button>
                            </Col>
                            <Col>
                                <h4 style={{marginTop: 0}}>Dettaglio flusso pendenze</h4>
                            </Col>
                        </Row>
                        {
                            flusso ?
                            <Card>
                                <Row>
                                    <Col>
                                        <h5>{flusso.nomeFile}</h5>
                                        <p>Caricato da: {flusso.operatoreMittente}</p>
                                        <p>Stato: {flusso.stato}</p>
                                        <p>{flusso.descrizioneStato}</p>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col s={12}><b>OPERAZIONI</b></Col>
                                    <Col s={4}>
                                        Operazioni totali: {flusso.numeroOperazioniTotali}
                                    </Col>
                                    <Col s={4}>
                                        Operazioni eseguite: {flusso.numeroOperazioniEseguite}
                                    </Col>
                                    <Col s={4}>
                                        Operazioni fallite: {flusso.numeroOperazioniFallite}
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col s={12}><b>AVVISI</b></Col>
                                    <Col s={4}>
                                        Avvisi totali: {flusso.numeroAvvisiTotali}
                                    </Col>
                                    <Col s={4}>
                                        Avvisi stampati: {flusso.numeroAvvisiStampati}
                                    </Col>
                                    <Col s={4}>
                                        Avvisi falliti: {flusso.numeroAvvisiFalliti}
                                    </Col>
                                </Row>
                                <hr />
                                <br />
                                <Row>
                                    <Col>
                                        <Button onClick={ () => pulsantiDettaglio('richiesta') }>Tracciato richiesta</Button>
                                    </Col>
                                    <Col>
                                        <Button onClick={ () => pulsantiDettaglio('esito') }>Tracciato di esito</Button>
                                    </Col>
                                    <Col>
                                        <Button onClick={ () => pulsantiDettaglio('operazioni') }>Operazioni effettuate</Button>
                                    </Col>
                                    <Col>
                                        <Button onClick={ () => pulsantiDettaglio('stampe') }>Avvisi generati</Button>
                                    </Col>
                                </Row>
                            </Card> :
                            <LoadingSmall />
                        }
                    </>
                ) :
                <>
                    <Card>
                        <Row>
                            <Col className="center" s={12}>
                                <h1>FLUSSI MASSIVI PENDENZE</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col s={12}>
                                <TextInput s={12} id="fileUpload" label="SELEZIONA FLUSSO COMPLETO" type="file" onChange={readFile} />
                            </Col>
                        </Row>
                        { 
                            message == ''
                            ? <></>
                            : <Row>
                                <Col s={12}>
                                    <p>{message}</p>
                                </Col>
                            </Row>
                        }
                        {
                            fileName && fileContent
                            ? <Row>
                                <Col s={12}>
                                    <Button onClick={sendFile}>ESEGUI CARICAMENTO FLUSSO</Button>
                                </Col>
                            </Row>
                            : <></>
                        }
                    </Card>
                    <Row>
                        <Col s={12}>
                            <h4 style={{marginTop: 0}}>Flussi</h4>
                            <p>Elenco dei flussi caricati dall'Ente</p>
                            {/* <CardFiltriEnte tipo="pendenze" onFilterSaved={filterSaved} /> */}
                            <h5>Flussi trovati: { flussi ? flussi?.numRisultati : "0" }</h5>
                        </Col>
                    </Row>
                    {
                        flussi ?
                        <>
                            <Pagination
                                activePage={flussi.pagina}
                                items={flussi.numPagine}
                                leftBtn={<Icon>chevron_left</Icon>}
                                maxButtons={10}
                                rightBtn={<Icon>chevron_right</Icon>}
                                onSelect={(page) => getFlussi(filter, page)}
                            />
                            {flussi?.risultati?.map(
                                (flusso) => (<Card key={flusso.id}>
                                    <h5 style={{marginTop: 0}}>{flusso.nomeFile}</h5>
                                    <Row>
                                        <Col>
                                            <b>Data di caricamento:</b> {flusso.dataOraCaricamento}
                                        </Col>
                                        <Col>
                                            <b>Ultimo aggiornamento:</b> {flusso.dataOraUltimoAggiornamento}
                                        </Col>
                                        <Col>
                                            <b>Stato:</b> {flusso.stato}
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <Button onClick={() => visualizzaDettaglio(flusso.id)}>DETTAGLI</Button>
                                        </Col>
                                    </Row>
                                </Card>)
                            )}
                        </> :
                        <LoadingSmall />
                    }
                </>
            }
        </>
    );
}