export default (state, action) => {
    switch(action.type) {
        case 'SET_STRUTTURA_SELETTORI':
            return {
                ...state,
                strutturaSelettori: action.payload
            }
        case 'DELETE_TRANSACTION':
            return {
                ...state,
                transactions: state.transactions.filter(transaction => transaction.id !== action.payload)
            }
        case 'ADD_TRANSACTION':
            return {
                ...state,
                transactions: [action.payload, ...state.transactions]
            }
        case 'SET_PROVINCIA':
            return {
                ...state,
                provincia: action.payload,
                ente: ""
            }
        case 'SET_ENTE':
            return {
                ...state,
                ente: action.payload,
                servizio: ""
            }
        case 'SET_DATI_ENTE':
            return {
                ...state,
                datiEnte: action.payload
            }
        case 'SET_SERVIZIO':
            return {
                ...state,
                servizio: action.payload
            }
        case 'SET_PROVINCIA_ENTE':
            return {
                ...state,
                provincia: action.payload.provincia,
                ente: action.payload.ente,
                servizio: ""
            }
        case 'SET_PROVINCIA_ENTE_SERVIZIO':
            return {
                ...state,
                provincia: action.payload.provincia,
                ente: action.payload.ente,
                servizio: action.payload.servizio
            }
        case 'ADD_PENDENZA_CARRELLO':
            return {
                ...state,
                carrello: [action.payload, ...state.carrello],
                nuovaPendenza: null,
                provincia: "",
                ente: "",
                servizio: ""
            }
        case 'REMOVE_PENDENZA_CARRELLO':
            return {
                ...state,
                carrello: state.carrello.filter((el, i) => i !== action.payload)
            }
        case 'AGGIORNA_PENDENZA_CARRELLO':
            console.log('payload', action.payload)
            let carrello = state.carrello
            carrello[action.payload.index] = action.payload.pendenza
            return {
                ...state,
                carrello: carrello
            }
        case 'SVUOTA_CARRELLO':
            return {
                ...state,
                carrello: state.carrello.filter(() => false)
            }
        case 'SET_NUOVA_PENDENZA':
            return {
                ...state,
                nuovaPendenza: action.payload
            }
        case 'SET_NUOVO_AVVISO':
            return {
                ...state,
                nuovoAvviso: action.payload
            }    
        case 'SET_CITTADINO':
            return {
                ...state,
                cittadino: action.payload
            }
        default:
            return state;
    }
}