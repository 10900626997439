export const useSigleNazioni = () => {

    const sigleNazioni = [
        {sigla: "AF", nome: "Afghanistan"},
        {sigla: "ZA", nome: "Africa del sud"},
        {sigla: "AL", nome: "Albania"},
        {sigla: "DZ", nome: "Algeria"},
        {sigla: "AD", nome: "Andorra"},
        {sigla: "AO", nome: "Angola"},
        {sigla: "AI", nome: "Anguilla"},
        {sigla: "AG", nome: "Antigua e Barbuda"},
        {sigla: "AQ", nome: "Antartico"},
        {sigla: "SA", nome: "Arabia Saudita"},
        {sigla: "AR", nome: "Argentina"},
        {sigla: "AM", nome: "Armenia"},
        {sigla: "AW", nome: "Aruba"},
        {sigla: "AU", nome: "Australia"},
        {sigla: "AT", nome: "Austria"},
        {sigla: "AZ", nome: "Azerbaigian"},
        {sigla: "BD", nome: "Bangladesh"},
        {sigla: "BS", nome: "Bahamas"},
        {sigla: "BH", nome: "Bahrein"},
        {sigla: "BB", nome: "Barbados"},
        {sigla: "BM", nome: "Bermuda"},
        {sigla: "BE", nome: "Belgio"},
        {sigla: "BJ", nome: "Benin"},
        {sigla: "BZ", nome: "Belize"},
        {sigla: "BT", nome: "Bhutan"},
        {sigla: "BY", nome: "Bielorussia"},
        {sigla: "BU", nome: "Birmania"},
        {sigla: "BO", nome: "Bolivia"},
        {sigla: "BQ", nome: "Bonaire, Sant'Eustachio e Saba"},
        {sigla: "BA", nome: "Bosnia-Erzegovina"},
        {sigla: "BW", nome: "Botswana"},
        {sigla: "BR", nome: "Brasile"},
        {sigla: "BN", nome: "Brunei Darussalam"},
        {sigla: "BG", nome: "Bulgaria"},
        {sigla: "BF", nome: "Burkina Faso"},
        {sigla: "BI", nome: "Burundi"},
        {sigla: "KH", nome: "Cambogia"},
        {sigla: "CM", nome: "Camerun"},
        {sigla: "CA", nome: "Canada"},
        {sigla: "CV", nome: "Capo Verde"},
        {sigla: "CS", nome: "Cecoslovacchia"},
        {sigla: "TD", nome: "Ciad"},
        {sigla: "CL", nome: "Cile"},
        {sigla: "CN", nome: "Cina"},
        {sigla: "CY", nome: "Cipro"},
        {sigla: "CO", nome: "Colombia"},
        {sigla: "KM", nome: "Comore"},
        {sigla: "CG", nome: "Congo"},
        {sigla: "CI", nome: "Costa D'Avorio"},
        {sigla: "CR", nome: "Costa Rica"},
        {sigla: "HR", nome: "Croazia"},
        {sigla: "CU", nome: "Cuba"},
        {sigla: "CW", nome: "Curaçao"},       
        {sigla: "DK", nome: "Danimarca"},
        {sigla: "DM", nome: "Dominica"},        
        {sigla: "EC", nome: "Ecuador"},
        {sigla: "EG", nome: "Egitto"},
        {sigla: "SV", nome: "El Salvador"},
        {sigla: "AE", nome: "Emirati Arabi Uniti"},
        {sigla: "ER", nome: "Eritrea"},
        {sigla: "EE", nome: "Estonia"},
        {sigla: "ET", nome: "Etiopia"},        
        {sigla: "FJ", nome: "Figi"},
        {sigla: "PH", nome: "Filippine"},
        {sigla: "FI", nome: "Finlandia"},
        {sigla: "FR", nome: "Francia"},
        {sigla: "FX", nome: "Francia metropolitana"},
        {sigla: "GA", nome: "Gabon"},
        {sigla: "GM", nome: "Gambia"},
        {sigla: "GE", nome: "Georgia"},
        {sigla: "GS", nome: "Georgia del sud"},
        {sigla: "DE", nome: "Germania"},
        {sigla: "GH", nome: "Ghana"},
        {sigla: "JM", nome: "Giamaica"},
        {sigla: "JP", nome: "Giappone"},
        {sigla: "GI", nome: "Gibilterra"},
        {sigla: "DJ", nome: "Gibuti"},
        {sigla: "JO", nome: "Giordania"},
        {sigla: "GR", nome: "Grecia"},
        {sigla: "GD", nome: "Grenada"},
        {sigla: "GL", nome: "Groenlandia"},
        {sigla: "GP", nome: "Guadalupa"},
        {sigla: "GT", nome: "Guatemala"},
        {sigla: "GU", nome: "Guam"},
        {sigla: "GY", nome: "Guiana"},
        {sigla: "GF", nome: "Guiana francese"},
        {sigla: "GN", nome: "Guinea"},
        {sigla: "GW", nome: "Guinea-Bissau"},
        {sigla: "GQ", nome: "Guinea equatoriale"},
        {sigla: "GG", nome: "Guernsey"},
        {sigla: "HT", nome: "Haiti"},
        {sigla: "HN", nome: "Honduras"},
        {sigla: "HK", nome: "Hong Kong"},
        {sigla: "IN", nome: "India"},
        {sigla: "ID", nome: "Indonesia"},
        {sigla: "IR", nome: "Iran"},
        {sigla: "IQ", nome: "Iraq"},
        {sigla: "IE", nome: "Irlanda"},
        {sigla: "AX", nome: "Isole Åland"},
        {sigla: "BV", nome: "Isola Bouvet"},
        {sigla: "KY", nome: "Isole Cayman"},
        {sigla: "CX", nome: "Isola Christmas (Australia)"},
        {sigla: "CC", nome: "Isole Cocos/Keeling"},
        {sigla: "CK", nome: "Isole Cook"},
        {sigla: "IM", nome: "Isola di Man"},
        {sigla: "FK", nome: "Isole Falkland"},
        {sigla: "FO", nome: "Isole Fær Øer"},
        {sigla: "HM", nome: "Isole Heard e McDonald"},
        {sigla: "MP", nome: "Isole Marianne settentrionali"},
        {sigla: "MH", nome: "Isole Marshall"},
        {sigla: "UM", nome: "Isole minori degli Stati Uniti"},
        {sigla: "SB", nome: "Isole Salomone"},
        {sigla: "TC", nome: "Isole Turks e Caicos"},
        {sigla: "VI", nome: "Isole Vergini americane"},
        {sigla: "VG", nome: "Isole Vergini britanniche"},
        {sigla: "IS", nome: "Islanda"},
        {sigla: "IL", nome: "Israele"},
        {sigla: "NF", nome: "Isola Norfolk"},
        {sigla: "IT", nome: "Italia"},
        {sigla: "YU", nome: "Iugoslavia"},
        {sigla: "JE", nome: "Jersey"},
        {sigla: "KZ", nome: "Kazakistan"},
        {sigla: "KE", nome: "Kenya"},
        {sigla: "KG", nome: "Kirghizistan"},
        {sigla: "KI", nome: "Kiribati"},
        {sigla: "KW", nome: "Kuwait"},     
        {sigla: "LA", nome: "Laos"},
        {sigla: "LS", nome: "Lesotho"},
        {sigla: "LV", nome: "Lettonia"},
        {sigla: "LB", nome: "Libano"},
        {sigla: "LR", nome: "Liberia"},
        {sigla: "LY", nome: "Libia"},
        {sigla: "LI", nome: "Liechtenstein"},
        {sigla: "LT", nome: "Lituania"},
        {sigla: "LU", nome: "Lussemburgo"},      
        {sigla: "MO", nome: "Macao"},
        {sigla: "MK", nome: "Macedonia (Ex-Repubblica Iugoslava di)"},
        {sigla: "MG", nome: "Madagascar"},
        {sigla: "MW", nome: "Malawi"},
        {sigla: "MV", nome: "Maldive"},
        {sigla: "MY", nome: "Malesia"},
        {sigla: "ML", nome: "Mali"},
        {sigla: "MT", nome: "Malta"},
        {sigla: "MA", nome: "Marocco"},
        {sigla: "MQ", nome: "Martinica"},
        {sigla: "MR", nome: "Mauritania"},
        {sigla: "MU", nome: "Maurizio"},
        {sigla: "YT", nome: "Mayotte"},
        {sigla: "MX", nome: "Messico"},
        {sigla: "FM", nome: "Micronesia"},
        {sigla: "MD", nome: "Moldavia"},
        {sigla: "MC", nome: "Monaco"},
        {sigla: "MN", nome: "Mongolia"},
        {sigla: "ME", nome: "Montenegro"},
        {sigla: "MS", nome: "Montserrat"},
        {sigla: "MZ", nome: "Mozambico"},
        {sigla: "MM", nome: "Myanmar (ex Birmania)"},        
        {sigla: "NA", nome: "Namibia"},
        {sigla: "NR", nome: "Nauru"},
        {sigla: "NP", nome: "Nepal"},
        {sigla: "NI", nome: "Nicaragua"},
        {sigla: "NE", nome: "Niger"},
        {sigla: "NG", nome: "Nigeria"},
        {sigla: "NU", nome: "Niue"},
        {sigla: "NO", nome: "Norvegia"},
        {sigla: "NC", nome: "Nuova Caledonia"},
        {sigla: "NZ", nome: "Nuova Zelanda"},        
        {sigla: "OM", nome: "Oman"},       
        {sigla: "NL", nome: "Paesi Bassi"},
        {sigla: "PK", nome: "Pakistan"},
        {sigla: "PW", nome: "Palau"},
        {sigla: "PA", nome: "Panama"},
        {sigla: "PG", nome: "Papua Nuova Guinea"},
        {sigla: "PY", nome: "Paraguay"},
        {sigla: "PE", nome: "Perù"},
        {sigla: "PN", nome: "Pitcairn"},
        {sigla: "PF", nome: "Polinesia Francese"},
        {sigla: "PL", nome: "Polonia"},
        {sigla: "PT", nome: "Portogallo"},
        {sigla: "PR", nome: "Portorico"},        
        {sigla: "QA", nome: "Qatar"},        
        {sigla: "GB", nome: "Regno Unito"},
        {sigla: "KP", nome: "Repubblica democratica popolare di Corea"},
        {sigla: "CZ", nome: "Repubblica Ceca"},
        {sigla: "CF", nome: "Repubblica Centrafricana"},
        {sigla: "KR", nome: "Repubblica di Corea"},
        {sigla: "CD", nome: "Repubblica democratica del Congo"},
        {sigla: "DD", nome: "Repubblica democratica tedesca"},
        {sigla: "DO", nome: "Repubblica Dominicana"},
        {sigla: "RE", nome: "Riunione"},
        {sigla: "RO", nome: "Romania"},
        {sigla: "RU", nome: "Russia"},
        {sigla: "RW", nome: "Ruanda"},        
        {sigla: "EH", nome: "Sahara occidentale"},
        {sigla: "BL", nome: "Saint Barthelémy"},
        {sigla: "MF", nome: "Saint Martin (parte francese)"},
        {sigla: "KN", nome: "Saint Kitts e Nevis"},
        {sigla: "LC", nome: "Saint Lucia"},
        {sigla: "PM", nome: "Saint-Pierre e Miquelon"},
        {sigla: "VC", nome: "Saint Vincent e Grenadine"},
        {sigla: "WS", nome: "Samoa"},
        {sigla: "AS", nome: "Samoa americane"},
        {sigla: "VA", nome: "Santa Sede (Stato della Città del Vaticano)"},
        {sigla: "SH", nome: "Sant'Elena"},
        {sigla: "SM", nome: "San Marino"},
        {sigla: "ST", nome: "Sao Tomé e Principe"},
        {sigla: "RS", nome: "Serbia"},
        {sigla: "SC", nome: "Seychelles"},
        {sigla: "SN", nome: "Senegal"},
        {sigla: "SL", nome: "Sierra Leone"},
        {sigla: "SG", nome: "Singapore"},
        {sigla: "SX", nome: "Sint Maarten (parte olandese)"},
        {sigla: "SY", nome: "Siria"},
        {sigla: "SK", nome: "Slovacchia"},
        {sigla: "SI", nome: "Slovenia"},
        {sigla: "SO", nome: "Somalia"},
        {sigla: "ES", nome: "Spagna"},
        {sigla: "LK", nome: "Sri Lanka"},
        {sigla: "US", nome: "Stati Uniti"},
        {sigla: "SD", nome: "Sudan"},
        {sigla: "SS", nome: "Sudan del sud"},
        {sigla: "SR", nome: "Suriname"},
        {sigla: "SJ", nome: "Svalbard e Isola Jan Mayen"},
        {sigla: "SE", nome: "Svezia"},
        {sigla: "CH", nome: "Svizzera"},
        {sigla: "SZ", nome: "Swaziland"},        
        {sigla: "TJ", nome: "Tagichistan"},
        {sigla: "TH", nome: "Tailandia"},
        {sigla: "TW", nome: "Taiwan"},
        {sigla: "TZ", nome: "Tanzania"},
        {sigla: "TF", nome: "Terre australi francesi"},
        {sigla: "IO", nome: "Territorio britannico dell'Oceano Indiano"},
        {sigla: "PS", nome: "Territorio Palestinese, occupato"},
        {sigla: "TL", nome: "Timor Est (Timor-Leste)"},
        {sigla: "TP", nome: "Timor orientale"},
        {sigla: "TG", nome: "Togo"},
        {sigla: "TK", nome: "Tokelau"},
        {sigla: "TO", nome: "Tonga"},
        {sigla: "TT", nome: "Trinidad e Tobago"},
        {sigla: "TM", nome: "Turkmenistan"},
        {sigla: "TN", nome: "Tunisia"},
        {sigla: "TR", nome: "Turchia"},
        {sigla: "TV", nome: "Tuvalu"},        
        {sigla: "UA", nome: "Ucraina"},
        {sigla: "UG", nome: "Uganda"},
        {sigla: "HU", nome: "Ungheria"},
        {sigla: "UY", nome: "Uruguay"},
        {sigla: "UZ", nome: "Uzbekistan"},        
        {sigla: "VU", nome: "Vanuatu"},
        {sigla: "VE", nome: "Venezuela"},
        {sigla: "VN", nome: "Vietnam"},
        {sigla: "WF", nome: "Wallis e Futuna"},
        {sigla: "YE", nome: "Yemen"},
        {sigla: "YD", nome: "Yemen democratico"},        
        {sigla: "ZR", nome: "Zaire"},
        {sigla: "ZM", nome: "Zambia"},
        {sigla: "ZW", nome: "Zimbabwe"}
    ]

    return sigleNazioni
    
}