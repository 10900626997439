export const useScaricaRicevuta = (richiedente) => {
    const debug = process.env.REACT_APP_DEBUG === "true"

    const scaricaRicevuta = (idDominio, iuv, ccp) => {
        let url = process.env.REACT_APP_SERVER_URL + 'rpp/' + idDominio + '/' + iuv + '/' + ccp + '/rt?richiedente=' + richiedente
        if (debug) console.log(url)
        fetch(url, {
            method: 'GET',
            headers: { 
                'Accept': 'application/text'
            }
        })
            .then(res => res.text())
            .then(res => {
                if (debug) console.log(res)
                window.open(process.env.REACT_APP_SERVER_URL + 'pdf_ricevute/' + res)
            })
    }


    return scaricaRicevuta

}