import React, { useContext } from 'react'
import 'materialize-css'
import { Row, Col, Card, Button } from 'react-materialize'

import { GlobalContext } from '../../context/GlobalState';

export const GestioneEnteEntrate = ({dettaglio, redirect, match}) => {
    // const debug = process.env.REACT_APP_DEBUG === "true"

    
    const { datiEnte } = useContext(GlobalContext);

    const codiceEnte = datiEnte?.idDominio;

    if (codiceEnte && dettaglio) {   
        let url = '/gestione_ente/entrate'
        redirect(url);
    }

    return (
        <>
            <Row>
                <Col s={10}>
                    <h4 style={{marginTop: 0}}>Entrate</h4>
                    <p>Entrate trovate: { datiEnte?.entrate ? datiEnte?.entrate?.length : "0" }</p>
                </Col>
            </Row>
            {
                datiEnte?.entrate?.map(
                    (item, i) => (<Card key={i}>
                        <Row style={{marginBottom: '5px'}}>
                            <Col>
                                <b>descrizione:</b> {item.tipoEntrata.descrizione}
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '5px'}}>
                            <Col>
                                <b>IBAN accredito:</b> {item.ibanAccredito}
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '5px'}}>
                            <Col>
                                <b>idEntrata:</b> {item.idEntrata}
                            </Col>
                            <Col>
                                <b>abilitato:</b> {item.abilitato ? "SI" : "NO"}
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '5px'}}>
                            <Col>
                                <b>codice contabilità:</b> {item.codiceContabilita}
                            </Col>
                            <Col>
                                <b>tipo contabilità:</b> {item.tipoContabilita}                               
                            </Col>
                        </Row>
                    </Card>)
                )
            }
        </>
    );
}