import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import 'materialize-css'
import { Container, Card, Row, Col } from 'react-materialize'
import { HeaderCittadino } from '../components/HeaderCittadino'
import { PageFooter } from '../components/PageFooter'
import { GlobalContext } from '../context/GlobalState'


export const EsitoPendenzaPage = () => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const history = useHistory()
    const location = useLocation()

    const { nuovaPendenza, setNuovaPendenza } = useContext(GlobalContext)

    if (nuovaPendenza) {
        setNuovaPendenza(null)
    }

    const queryString = require('query-string')
    const parsed = queryString.parse(location.search)
    if (debug) console.log(parsed)

    return (
        <Container>
            <HeaderCittadino history={history} />
            <Row>
                <Col s={12}>
                    <h1>GENERAZIONE AVVISO DI PAGAMENTO</h1>
                    <Card>
                        {
                            parsed.esito === "OK" ?
                            <>
                                <Row>
                                    <Col>
                                        <h4>Avviso di pagamento generato correttamente</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p>Puoi scaricare i tuoi avvisi di pagamento anche dal tuo profilo, alla sezione "PENDENZE"</p>
                                    </Col>
                                </Row>                        
                            </> :
                            <>
                                <Row>
                                    <Col>
                                        <h4>Avviso di pagamento non generato</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p>Si è verificato un problema nella generazione dell'avviso, controllare che tutti i dati siano corretti o riprovare tra qualche minuto</p>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Card>
                </Col>
            </Row>
            <PageFooter />
        </Container>
    )
}