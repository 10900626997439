import React, { useContext } from 'react'
import 'materialize-css'
import { Select } from 'react-materialize'

import { GlobalContext } from '../context/GlobalState';

export const SelezionaEnte = ({onChange}) => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const { strutturaSelettori, provincia, ente, setEnte } = useContext(GlobalContext);

    const enti = provincia ? strutturaSelettori[provincia].enti : []

    if (debug) console.log(provincia, enti)

    return (
        <Select
            s={12} 
            m={12}
            id="SelezionaEnte"
            multiple={false}
            onChange={ e => {setEnte(e.target.value); if (onChange) onChange(e.target.value)} }
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: false,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }}
            value={ ente }
        >
            <option
                value=""
            >
                SELEZIONA UN ENTE
            </option>
            {enti.map((item) => (<option key={item.idDominio} value={item.idDominio}>{item.ragioneSociale}</option>))}
        </Select>
    )
}
