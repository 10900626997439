import React from 'react'
import 'materialize-css'
import { Row, Col, Card, Button } from 'react-materialize'


export const GestioneCittadinoPagamentiListItem = ({ item, redirect }) => {
    // const debug = process.env.REACT_APP_DEBUG === "true"


    const visualizzaDettaglio = (idDettaglio) => {
        let url = '/gestione_cittadino/pagamenti/' + idDettaglio
        redirect(url)
    }


    const parseDateTime = (dateTime) => {
        let date = new Date(dateTime)

        let anno = date.getFullYear().toString()
        let mese = (date.getMonth() + 1).toString().padStart(2, '0')
        let giorno = date.getDate().toString().padStart(2, '0')
        let ora = date.getHours().toString().padStart(2, '0')
        let minuti = date.getMinutes().toString().padStart(2, '0')

        let result = giorno + '/' + mese + '/' + anno + ' - ' + ora + ':' + minuti

        return result
    } 


    return (
        <>
            <Card>
                <h4 style={{marginTop: 0}}>{item.nome} - €{item.importo}</h4>
                <h6 style={{marginTop: 0}}>ID: {item.id}</h6>
                <Row style={{margin: 0}}>
                    <Col s={12} m={6} l={3}>
                        <b>data richiesta:</b> { parseDateTime(item.dataRichiestaPagamento)}
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <b>modello:</b> { item.modello }
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <b>stato:</b> { item.stato }
                    </Col>
                    <Col s={12} m={6} l={3}>
                        <Button onClick={() => visualizzaDettaglio(item.id)} style={{width: '100%'}}>DETTAGLI</Button>
                    </Col>
                </Row>
            </Card>
        </>
    );
}