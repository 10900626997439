import React, { useState, useEffect, useContext } from 'react'
import 'materialize-css'
import { Row, Col,Pagination, Icon } from 'react-materialize'

import { useTokenCittadino } from "../hooks/useTokenCittadino"

import { LoadingSmall } from '../loadingSmall';
import { LoadingFull } from '../loadingFull';

import { CardFiltriCittadino } from '../CardFiltriCittadino'
import { GestioneCittadinoPendenzeListItem } from './GestioneCittadinoPendenzeListItem'
import { GestioneCittadinoPendenzeDetail } from './GestioneCittadinoPendenzeDetail'

import { SelezionaDelegato } from "../SelezionaDelegato"

import { GlobalContext } from '../../context/GlobalState';


export const GestioneCittadinoPendenze = ({redirect, dettaglio}) => {
    const debug = process.env.REACT_APP_DEBUG === "true"

    const { cittadino } = useContext(GlobalContext);

    const { token } = useTokenCittadino()
    const [ pendenze, setPendenze ] = useState(null);
    const [ pendenza, setPendenza ] = useState(null);
    const [ filter, setFilter ] = useState(null);
    const [ loading, setLoading ] = useState(false)
    const [ deleghe, setDeleghe ] = useState([])
    const [ delegaSelezionata, setDelegaSelezionata ] = useState("")


    if (!dettaglio && pendenza) {
        setPendenza(null)
    }

    
    const getPendenze = (filterData, page) => {
        setLoading(true)
        if (debug) console.log("filterData", filterData)
        fetch(process.env.REACT_APP_SERVER_URL + 'cittadino/pendenze', {
            method: 'POST',
            body: JSON.stringify({token, delegaSelezionata, filter: filterData, page, richiedente: cittadino.cf})
        })
            .then(res => res.json())
            .then(res => {
                if (debug) console.log(res)
                if (res.valid) {
                    if (pendenze !== res.result) setPendenze(res.result)
                    setLoading(false)
                } else {
                    redirect("/")
                }
            })        
    }


    const getDettaglioPendenza = () => {
        let [idA2A, id] = dettaglio.split('~')
        if (id !== pendenza?.idPendenza) {
            fetch(process.env.REACT_APP_SERVER_URL + 'cittadino/pendenze/' + idA2A + '/' + id, {
                method: 'POST',
                body: JSON.stringify({token, richiedente: cittadino.cf})
            })
                .then(res => res.json())
                .then(res => {
                    if (debug) console.log(res)
                    if (res.valid) {
                        setPendenza(res.result)
                    } else {
                        redirect("/")
                    }
                })         
        }
    }


    useEffect(() => {
        if (debug) console.log('USE EFFECT', dettaglio)
        if (token) {
            if (dettaglio) {
                getDettaglioPendenza()
            } else {
                getPendenze(filter, 1)
            }
        } else {
            redirect("/")
        }
    }, [delegaSelezionata, dettaglio])


    useEffect(() => {
        let deleghe = []

        if (cittadino?.permessi) {
            deleghe = cittadino.permessi.filter(permesso => {
                                                                let d = permesso.RUOLI.filter(ruolo => ruolo.tipo === 'DelegatoStampa')
                                                                return d.length > 0
                                                            })
        }

        setDeleghe(deleghe)
    }, [cittadino])


    const filterSaved = (filterData) => {
        setFilter(filterData)
        if (debug) console.log('filter saved', filterData)
        getPendenze(filterData, 1)
    } 


    const checkDelegato = () => {
        if (!cittadino?.permessi) {
            return false
        }

        let filter = cittadino.permessi.filter(permesso => {
                                                                let deleghe = permesso.RUOLI.filter(ruolo => ruolo.tipo === 'DelegatoStampa')
                                                                return deleghe.length > 0
                                                            })

        return filter.length > 0
    }


    const delegaChange = (event) => {
        console.log({event})
        setDelegaSelezionata(event)
    }


    return (
        <>
            { loading ? <LoadingFull /> : null }
            {
                dettaglio ?
                <>
                    {
                        pendenza ?
                        <GestioneCittadinoPendenzeDetail redirect={ redirect } pendenza={ pendenza } /> :
                        <LoadingSmall />
                    }
                </> : 
                <>
                    <Row>
                        {
                            checkDelegato() ?
                            <Col s={12}>
                                <SelezionaDelegato deleghe={ deleghe } delegaSelezionata={ delegaSelezionata } onChange={ delegaChange } />
                            </Col> :
                            <></>
                        } 
                        <Col s={12}>
                            <h4 style={{marginTop: 0}}>Pendenze</h4>
                            <p>{ delegaSelezionata.length ? 'Elenco delle pendenze intestate al codice fiscale delegante' : 'Elenco delle pendenze intestate al tuo codice fiscale'}</p>
                            <CardFiltriCittadino tipo="pendenze" onFilterSaved={filterSaved} />
                            <h5>Pendenze trovate: { pendenze ? pendenze?.numRisultati : "0" }</h5>
                        </Col>
                    </Row>
                    {
                        pendenze ? 
                        <>
                            <Pagination
                                activePage={pendenze.pagina}
                                items={pendenze.numPagine}
                                leftBtn={<Icon>chevron_left</Icon>}
                                maxButtons={10}
                                rightBtn={<Icon>chevron_right</Icon>}
                                onSelect={(page) => getPendenze(filter, page)}
                            />
                            {pendenze?.risultati?.map(
                                (item) => (<GestioneCittadinoPendenzeListItem key={item.idPendenza} item={ item } redirect={ redirect } />)
                            )}
                        </> :
                        <LoadingSmall />
                    }
                </>
            }
        </>
    );
}