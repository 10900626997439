export const useGetAllowedSections = () => {

    const getAllowedSections = (cittadino, idDominio) => {
    
        const roleSections = {
            Amministratore: [
                'anagrafica',
                'contiaccredito',
                'tipipendenza',
                'entrate',
                'pagamenti',
                'pendenze',
                'inserisci_pendenza',
                'flussi_pendenze',
                'riconciliazioni',
                'rendicontazioni',
                'scarica_flusso_rendicontazioni',
                'converti_flusso',
                'riscossioni',
                'nuovo_ticket',
                'storico_ticket',
            ],
            Tesoriere: [
                'anagrafica',
                'contiaccredito',
                'tipipendenza',
                'entrate',
                // 'pagamenti',
                // 'pendenze',
                // 'inserisci_pendenza',
                // 'flussi_pendenze',
                'riconciliazioni',
                'rendicontazioni',
                'scarica_flusso_rendicontazioni',
                'converti_flusso',
                'riscossioni',
                'nuovo_ticket',
                'storico_ticket',
            ],
            GestoreServizi: [
                'anagrafica',
                // 'contiaccredito',
                // 'tipipendenza',
                // 'entrate',
                // 'pagamenti',
                'pendenze',
                'inserisci_pendenza',
                // 'flussi_pendenze',
                // 'riconciliazioni',
                // 'rendicontazioni',
                // 'scarica_flusso_rendicontazioni',
                // 'converti_flusso',
                // 'riscossioni',
                'nuovo_ticket',
                'storico_ticket',
            ],
            DelegatoStampa: [
                // 'anagrafica',
                // 'contiaccredito',
                // 'tipipendenza',
                // 'entrate',
                // 'pagamenti',
                // 'pendenze',
                // 'inserisci_pendenza',
                // 'flussi_pendenze',
                // 'riconciliazioni',
                // 'rendicontazioni',
                // 'scarica_flusso_rendicontazioni',
                // 'converti_flusso',
                // 'riscossioni',
                // 'nuovo_ticket',
                // 'storico_ticket',
            ]
        }
    
        let allowedSections = []
    
        const tutti = cittadino?.permessi[0]?.ENTE === 'TUTTI'
        
        console.log({cittadino, tutti, idDominio})
    
        let role = ''
    
        if (tutti || cittadino?.permessi?.length === 1) {
            role = cittadino.permessi[0].RUOLI[0].tipo
        } else {
            let permessi = cittadino?.permessi?.filter(p => p.CF_PIVA_ENTE === idDominio)
            console.log({permessi})
            role = idDominio.length ? cittadino?.permessi?.filter(permesso => permesso.CF_PIVA_ENTE === idDominio)[0].RUOLI[0].tipo : ''
        }
        
        if (role?.length) {
            allowedSections = roleSections[role]
        }
    
        return allowedSections
    }

    return getAllowedSections
}