import React, { useContext, useState, useEffect, useCallback } from 'react'
import 'materialize-css'
import { Row, Col, Card, Button, Icon, Select, TextInput, DatePicker } from 'react-materialize'

import { GlobalContext } from '../../context/GlobalState';

import { useTokenCittadino } from "../hooks/useTokenCittadino"
import { useGeneraIdPendenza } from "../hooks/useGeneraIdPendenza"
import { useGetAnni } from "../hooks/useGetAnni"
import { useSigleNazioni } from "../hooks/useSigleNazioni"


export const GestioneEnteInserisciPendenza = ({ redirect }) => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const { token } = useTokenCittadino()
    const { datiEnte, cittadino } = useContext(GlobalContext);
    const [ servizi, setServizi ] = useState([]);
    const [ servizio, setServizio ] = useState('');
    const [ codiceEnte, setCodiceEnte ] = useState(null)
    const [ anno, anni ] = useGetAnni()
    const sigleNazioni = useSigleNazioni()

    const [idTipiPendenza, setIdTipiPendenza] = useState([])
    const [selectTipiPendenza, setSelectTipiPendenza] = useState([])

    const generaIdPendenza = useGeneraIdPendenza()


    useEffect(() => {
        if (!codiceEnte) {
            setServizi([])
            setCodiceEnte(datiEnte?.idDominio)
        } else if (codiceEnte !== datiEnte?.idDominio) {
            setCodiceEnte(null)
        }
    }, [codiceEnte, datiEnte, setCodiceEnte])


    // FILTRA I SERVIZI IN BASE AI PERMESSI
    const selectServizi = useCallback(() => {
        console.log('tipiPendenza START', {cittadino}, {datiEnte})

        let abilitati = datiEnte?.entrate?.filter(entrata => entrata.abilitato)

        let tipiPendenza = {
            id: [],
            obj: []
        }

        if (cittadino.permessi[0].ENTE === "TUTTI") {
            tipiPendenza.obj = abilitati
            console.log({tipiPendenza})
            return tipiPendenza
        }

        let entiAmministrati = cittadino.permessi.filter(permesso => permesso.RUOLI[0].tipo === 'Amministratore').map(permesso => permesso.CF_PIVA_ENTE)
        if (entiAmministrati.includes(datiEnte.idDominio)) {
            tipiPendenza.obj = abilitati
            console.log({tipiPendenza})
            return tipiPendenza
        }

        let entiGestiti = cittadino.permessi.filter(permesso => permesso.RUOLI[0].tipo === 'GestoreServizi').map(permesso => permesso.CF_PIVA_ENTE)
        if (entiGestiti.includes(datiEnte.idDominio)) {
            let permesso = cittadino.permessi.filter(p => p.CF_PIVA_ENTE == datiEnte.idDominio)[0]
            let tipi = permesso.RUOLI.filter(r => r.tipo === 'GestoreServizi').map(item => item.servizi)
            
            if (tipi[0].length) {
                tipiPendenza.id = tipi[0]
                let obj = abilitati.filter(tipo => tipi[0].includes(tipo.tipoEntrata.idEntrata))
                tipiPendenza.obj = obj
                console.log({tipiPendenza, obj, datiEnte})
                return tipiPendenza
            }

            tipiPendenza.obj = abilitati
            console.log({tipiPendenza})
            return tipiPendenza
        }

        console.log('tipiPendenza END')
    }, [datiEnte])


    useEffect(() => {
        let tipiPendenza = selectServizi()
        console.log({tipiPendenza, datiEnte})
        setIdTipiPendenza(tipiPendenza?.id ?? [])
        setSelectTipiPendenza(tipiPendenza?.obj ?? [])
    }, [selectServizi, cittadino, debug, datiEnte])
    // FILTRA I SERVIZI IN BASE AI PERMESSI


    if (token) {

    } else {
        redirect("/")
    }


    // if (!servizi.length && datiEnte) {
    //     if (debug) console.log('CHECK', servizi, datiEnte)
    //     let abilitati = datiEnte?.entrate?.filter(entrata => entrata.abilitato)
    //     if (abilitati) {
    //         setServizi(abilitati)
    //     }
    // }


    const formIniziale = {
        idDominio: '',
        codEntrata: '',
        tipo: "F",
        identificativo: "",
        cognome: "",
        nome: "",
        indirizzo: "",
        civico: "",
        cap: "",
        localita: "",
        provincia: "",
        nazione: "IT",
        causale: "",
        importo: "",
        dataValidita: "",
        dataScadenza: "",
        annoRiferimento: anno
    }


    const [datiForm, setDatiForm] = useState(formIniziale);


    const handleChange = (e) => {
        setDatiForm({
            ...datiForm,
            [e.target.id]: e.target.value
        });
    }


    const handleDatepicker = (e) => {
        let { id, value } = e.target
        console.log('form 1', datiForm[id])
        console.log('form 2', id, value)

        if (id === 'dataValidita') {
            if (value > datiForm.dataScadenza) {
                setDatiForm({
                    ...datiForm,
                    dataValidita: value,
                    dataScadenza: value,
                });
            } else {
                setDatiForm({
                    ...datiForm,
                    [id]: value
                });
            }
        }

        if (id === 'dataScadenza') {
            if (value < datiForm.dataScadenza) {
                setDatiForm({
                    ...datiForm,
                    dataValidita: value,
                    dataScadenza: value,
                });
            } else {
                setDatiForm({
                    ...datiForm,
                    [id]: value
                });
            }
        }
    } 


    const resetDate = () => {
        setDatiForm({
            ...datiForm,
            dataScadenza: "",
            dataValidita: "",
        });
    }


    const checkForm = () => {
        let valid = true

        if (debug) console.log(datiForm)

        if (!servizio?.length) valid = false
        if (datiForm.causale.length === 0) valid = false
        if (datiForm.annoRiferimento.length === 0 || datiForm.annoRiferimento < 2000 || datiForm.annoRiferimento > new Date().getFullYear()) valid = false
        if (datiForm.importo < 0.01) valid = false
        if (datiForm.identificativo.length !== 11 && datiForm.identificativo.length !== 16) valid = false
        if (datiForm.cognome.length < 1) valid = false
        if (datiForm.civico.length && !datiForm.indirizzo.length) valid = false
        if (datiForm.provincia !== "" && datiForm.provincia.length !== 2) valid = false
        if (datiForm.cap !== "" && datiForm.cap.length !== 5) valid = false
        if (datiForm.nazione !== "" && datiForm.nazione.length !== 2) valid = false

        return valid
    }


    const scaricaAvviso = async (idDominio, numeroAvviso) => {
        let url = process.env.REACT_APP_SERVER_URL + 'avvisi/' + idDominio + '/' + numeroAvviso + '?richiedente=' + cittadino.cf
        if (debug) console.log(url)
        let response = await fetch(url, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/text'
            }
        })

        let res = await response.text()

        if (debug) console.log(res)
        window.open(process.env.REACT_APP_SERVER_URL + 'pdf_avvisi/' + res)
        redirect("/gestione_ente/pendenze")
    }


    const getNomeServizio = () => {        
        if (datiEnte && servizio.length) {
            return selectTipiPendenza.filter(entrata => entrata.idEntrata === servizio)[0].tipoEntrata.descrizione
        } else {
            return ""
        }
    }


    const parseDate = (date) => {
        if (date) {
            let g = date.getDate().toString().padStart(2, '0')
            let m = (date.getMonth() + 1).toString().padStart(2, '0')
            let a = date.getFullYear()
    
            let parsed = `${a}-${m}-${g}`
    
            return parsed
        } else {
            return ""
        }
    }


    const submitForm = (e) => {
        e.preventDefault();

        let idA2A = "CPP"
        let idDominio = codiceEnte
        let idPendenza = generaIdPendenza('', true)
        let descrizione = getNomeServizio()
        let codEntrata = servizio
        let importo = Number(Number(datiForm.importo).toFixed(2))

        let soggettoPagatore = {
            tipo: datiForm.tipo,
            identificativo: datiForm.identificativo,
            anagrafica: (datiForm.nome + " " + datiForm.cognome).trim(),
        }

        if (datiForm.indirizzo.length) soggettoPagatore['indirizzo'] = datiForm.indirizzo
        if (datiForm.civico.length) soggettoPagatore['civico'] = datiForm.civico
        if (datiForm.cap.length) soggettoPagatore['cap'] = datiForm.cap
        if (datiForm.localita.length) soggettoPagatore['localita'] = datiForm.localita
        if (datiForm.provincia.length) soggettoPagatore['provincia'] = datiForm.provincia
        if (datiForm.nazione.length) soggettoPagatore['nazione'] = datiForm.nazione

        let datiPendenza = {
            idA2A: idA2A,
            idPendenza: idPendenza,
            idDominio: idDominio,
            causale: datiForm.causale,
            soggettoPagatore: soggettoPagatore,
            importo: importo,
            annoRiferimento: datiForm.annoRiferimento,
            dataScadenza: parseDate(datiForm.dataScadenza),
            dataValidita: parseDate(datiForm.dataValidita),
            voci: [
                {
                    idVocePendenza: idPendenza,
                    importo: importo,
                    descrizione: descrizione,
                    codEntrata: codEntrata
                }
            ]
        }

        let pendenza = { datiForm, datiPendenza }
        
        if (debug) console.log(pendenza)

        aggiungiPendenza(pendenza)
    }

    
    const aggiungiPendenza = async (pendenza) => {
        let response = await fetch(process.env.REACT_APP_SERVER_URL + 'cittadino/carica_pendenza/' + pendenza.datiPendenza.idPendenza, {
            method: 'POST',
            body: JSON.stringify({token, pendenza: pendenza.datiPendenza, richiedente: cittadino.cf})
        })

        let res = await response.json()

        if (debug) console.log("AGGIUNGI PENDENZA", res)
        let { idDominio, numeroAvviso } = res.result
        if ( idDominio && numeroAvviso) {
            scaricaAvviso(idDominio, numeroAvviso)
        } else {
            redirect("/esito_avviso?esito=KO")
        }
    }


    return (
        <>
            <h4>INSERIMENTO PENDENZA</h4>
            <Card>
                <Row>
                    <Col s={12}>
                        <Select
                            s={12} 
                            m={12}
                            id="SelezionaServizio"
                            multiple={false}
                            onChange={ e => setServizio(e.target.value) }
                            options={{
                                classes: '',
                                dropdownOptions: {
                                    alignment: 'left',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: false,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250
                                }
                            }}
                            value={ servizio }
                        >
                            <option
                                value=""
                            >
                                SELEZIONA UN SERVIZIO
                            </option>
                            { selectTipiPendenza?.map((servizio) => (<option key={servizio.idEntrata} value={servizio.idEntrata}>{servizio.tipoEntrata.descrizione}</option>)) }
                        </Select>
                    </Col>
                </Row>
            </Card>
            <Card title="Dati del pagamento">
                <Row>
                    <Col s={12}>
                        <TextInput s={12} label="Causale del Servizio (*)" id="causale" onChange={handleChange} value={datiForm.causale} validate required />                        
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Euro (*)" id="importo" onChange={handleChange} value={datiForm.importo} type="number" step="0.01" min="0.01" validate required />
                    </Col>
                    <Col s={12} l={6}>
                        <Select
                            s={12}
                            label="Anno di Riferimento (*)"
                            id="annoRiferimento"
                            multiple={false}
                            onChange={ handleChange }
                            options={{
                                classes: '',
                                dropdownOptions: {
                                    alignment: 'left',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: false,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250
                                }
                            }}
                            value={ datiForm.annoRiferimento }
                            validate
                            required
                        >
                            { anni.map((a) => (<option value={a} key={a}>{a}</option>)) }
                        </Select>
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={4}>
                        <DatePicker 
                            s={12} 
                            label="Data di Validità" 
                            id="dataValidita" 
                            options={{ 
                                defaultDate: datiForm.dataValidita,
                                setDefaultDate: true,
                                autoClose: true,
                                format: "dd/mm/yyyy",
                                firstDay: 1,
                                i18n: {
                                    weekdays: [
                                        'Domenica',
                                        'Lunedì',
                                        'Martedì',
                                        'Mercoledì',
                                        'Giovedì',
                                        'Venerdì',
                                        'Sabato'
                                    ],
                                    weekdaysAbbrev: [
                                        'D',
                                        'L',
                                        'M',
                                        'M',
                                        'G',
                                        'V',
                                        'S'
                                    ],
                                    weekdaysShort: [
                                        'Dom',
                                        'Lun',
                                        'Mar',
                                        'Mer',
                                        'Gio',
                                        'Ven',
                                        'Sab'
                                    ],
                                    months: [
                                        'Gennaio',
                                        'Febbraio',
                                        'Marzo',
                                        'Aprile',
                                        'Maggio',
                                        'Giugno',
                                        'Luglio',
                                        'Agosto',
                                        'Settembre',
                                        'Ottobre',
                                        'Novembre',
                                        'Dicembre'
                                      ],
                                      monthsShort: [
                                        'Gen',
                                        'Feb',
                                        'Mar',
                                        'Apr',
                                        'Mag',
                                        'Giu',
                                        'Lug',
                                        'Ago',
                                        'Set',
                                        'Ott',
                                        'Nov',
                                        'Dic'
                                      ],
                                }
                            }} 
                            onChange={(newDate) => handleDatepicker({target: {id: "dataValidita", value: newDate}})} 
                            value={datiForm.dataValidita}
                        />        
                    </Col>
                    <Col s={12} l={4}>
                        <DatePicker 
                            s={12} 
                            label="Data di Scadenza" 
                            id="dataScadenza" 
                            options={{ 
                                defaultDate: datiForm.dataScadenza,
                                setDefaultDate: true,
                                autoClose: true,
                                format: "dd/mm/yyyy",
                                firstDay: 1,
                                i18n: {
                                    weekdays: [
                                        'Domenica',
                                        'Lunedì',
                                        'Martedì',
                                        'Mercoledì',
                                        'Giovedì',
                                        'Venerdì',
                                        'Sabato'
                                    ],
                                    weekdaysAbbrev: [
                                        'D',
                                        'L',
                                        'M',
                                        'M',
                                        'G',
                                        'V',
                                        'S'
                                    ],
                                    weekdaysShort: [
                                        'Dom',
                                        'Lun',
                                        'Mar',
                                        'Mer',
                                        'Gio',
                                        'Ven',
                                        'Sab'
                                    ],
                                    months: [
                                        'Gennaio',
                                        'Febbraio',
                                        'Marzo',
                                        'Aprile',
                                        'Maggio',
                                        'Giugno',
                                        'Luglio',
                                        'Agosto',
                                        'Settembre',
                                        'Ottobre',
                                        'Novembre',
                                        'Dicembre'
                                    ],
                                      monthsShort: [
                                        'Gen',
                                        'Feb',
                                        'Mar',
                                        'Apr',
                                        'Mag',
                                        'Giu',
                                        'Lug',
                                        'Ago',
                                        'Set',
                                        'Ott',
                                        'Nov',
                                        'Dic'
                                    ],
                                }
                            }} 
                            onChange={(newDate) => handleDatepicker({target: {id: "dataScadenza", value: newDate}})} 
                            value={datiForm.dataScadenza}
                        />        
                    </Col>    
                    <Col s={12} l={4}>
                        <Button node="button" waves="light" style={{width:'100%'}} large onClick={resetDate}>
                            AZZERA DATE
                            <Icon right>cancel</Icon>
                        </Button>                        
                    </Col>
                    <Col s={12}>
                        <p><i>NB:<br /><b>Data di Validità</b> - data oltre la quale il pagamento potrebbe subire delle variazioni<br /><b>Data di Scadenza</b> - data oltre la quale non è più possibile effettuare il pagamento</i></p>
                    </Col>
                </Row>
                <p>(*) CAMPI OBBLIGATORI</p>
            </Card>
            <Card title="Dati soggetto pagatore">
                <Row>
                    <Col s={12} l={6}>
                        <Select s={12} id="tipo" onChange={handleChange} value={datiForm.tipo} >
                            <option value="F">PERSONA FISICA</option>
                            <option value="G">PERSONA GIURIDICA</option>
                        </Select>
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Codice Fiscale / Partita IVA (*)" id="identificativo" onChange={handleChange} value={datiForm.identificativo} minLength={datiForm.tipo === "G" ? 11 : 16} maxLength={datiForm.tipo === "G" ? 11 : 16} validate required  />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Cognome / Ragione Sociale (*)" id="cognome" onChange={handleChange} value={datiForm.cognome} validate required />
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Nome" id="nome" onChange={handleChange} value={datiForm.nome} />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Indirizzo" id="indirizzo" onChange={handleChange} value={datiForm.indirizzo} />
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Numero Civico" id="civico" onChange={handleChange} value={datiForm.civico} />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Località" id="localita" onChange={handleChange} value={datiForm.localita} />
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Sigla Provincia" id="provincia" onChange={handleChange} value={datiForm.provincia} maxLength="2" validate />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="CAP" id="cap" onChange={handleChange} value={datiForm.cap} maxLength="5" validate />
                    </Col>
                    <Col s={12} l={6}>
                        <Select
                            s={12}
                            label="Nazione (*)"
                            id="nazione"
                            multiple={false}
                            onChange={ handleChange }
                            options={{
                                classes: '',
                                dropdownOptions: {
                                    alignment: 'left',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: false,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250
                                }
                            }}
                            value={ datiForm.nazione }
                            validate
                            required
                        >
                            { sigleNazioni.map((a) => (<option value={a.sigla} key={a.sigla}>{a.nome}</option>)) }
                        </Select>
                    </Col>
                </Row>
                <p>(*) CAMPI OBBLIGATORI</p>
            </Card>
            <Row>
                <Col s={12}>
                    <Button type="submit" node="button" waves="light" style={{width:'100%'}} large onClick={submitForm} disabled={!checkForm()}>
                        INSERISCI PENDENZA
                        <Icon right>send</Icon>
                    </Button>
                </Col>
            </Row>
        </>
    );
}