import React, { useContext, useState } from 'react'
import readXlsxFile from 'read-excel-file'
import 'materialize-css'
import { Container, Card, Row, Col, TextInput, Button, Checkbox } from 'react-materialize'
import { GlobalContext } from '../context/GlobalState'
import { SelezionaProvincia } from '../components/SelezionaProvincia'
import { SelezionaEnte } from '../components/SelezionaEnte'
import { SelezionaServizio } from '../components/SelezionaServizio'
// custom hooks
import { useTokenCittadino } from "../components/hooks/useTokenCittadino"
import { useGeneraIdPendenza } from "../components/hooks/useGeneraIdPendenza"
import { useGetNomeServizio } from "../components/hooks/useGetNomeServizio"


export const ConvertiFlussoPage = () => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const {
        strutturaSelettori, 
        setStrutturaSelettori, 
        ente, 
        servizio,
        cittadino
    } = useContext(GlobalContext);

    const { token } = useTokenCittadino()
    const getNomeServizio = useGetNomeServizio()
    const generaIdPendenza = useGeneraIdPendenza()

    const [idrico, setIdrico] = useState(false)
    const [bonario, setBonario] = useState(false)

    if (strutturaSelettori == null) {
        let richiedente = cittadino ? cittadino.cf : 'anonimo'
        fetch(process.env.REACT_APP_SERVER_URL + 'getStrutturaSelettori?richiedente=' + richiedente)
            .then(response => response.json())
            .then(data => {
                if (debug) console.log("strutturaSelettori POST", data)
                setStrutturaSelettori(data);
            })
    }

    const flussoCompleto = []

    const chiaviFlussoIdrico = [
        "CODICE_PRATICA",	
        "PARTITA_IVA_CODICE_FISCALE",	
        "DESTINATARIO",	
        "INDIRIZZO",
        "CIVICO",	
        "CAP",	
        "COMUNE",	
        "LOCALITA",	
        "PROVINCIA",	
        "NAZIONE",	
        "EMAIL",	
        "PEC",	
        "IMPORTO",
    ]

    const chiaviIdricoBonario = [
        "TIPO",	
        "DENOMINAZIONE",	
        "CODICE_UNICO",	
        "INDIRIZZO",	
        "N CIVICO",	
        "LOCALITA_RESIDENZA",	
        "COMUNE_RESIDENZA",	
        "PROVINCIA_RESIDENZA",	
        "CAP_RESIDENZA",	
        "PARTITA_IVA",	
        "CODICE_FISCALE",	
        "PEC",	
        "COMUNE_UTENZA",	
        "PROVINCIA_UTENZA",	
        "ANNUALITA",	
        "NOTA_REGIONALE",	
        "INTERESSI",	
        "CANONE",	
        "CANONE_TOTALE",	
        "TIPO_DOCUMENTO"
    ]

    const chiaviFlussoRidotto = [
        "causale",	
        "annoRiferimento",	
        "importo",	
        "dataValidita",	
        "dataScadenza",	
        "tipoSoggettoPagatore",	
        "identificativoPagatore",	
        "anagraficaPagatore",	
        "indirizzoPagatore",	
        "civicoPagatore",	
        "capPagatore",	
        "localitaPagatore",	
        "provinciaPagatore",	
        "nazionePagatore",
    ]

    const chiaviFlussoCompleto = [
        "idA2A",
        "idPendenza",
        "idDominio",
        "numeroAvviso",
        "tipoPendenza",
        "idUnitaOperativa",
        "causale",
        "annoRiferimento",
        "cartellaPagamento",
        "datiAllegati",
        "direzione",
        "divisione",
        "importo",
        "dataValidita",
        "dataScadenza",
        "tassonomiaAvviso",
        "tipoSoggettoPagatore",
        "identificativoPagatore",
        "anagraficaPagatore",
        "indirizzoPagatore",
        "civicoPagatore",
        "capPagatore",
        "localitaPagatore",
        "provinciaPagatore",
        "nazionePagatore",
        "emailPagatore",
        "cellularePagatore",
        "idVoce1",
        "importoVoce1",
        "descrizioneVoce1",
        "ibanAccreditoVoce1",
        "ibanAppoggioVoce1",
        "tipoContabilitaVoce1",
        "codiceContabilitaVoce1",
        "tipoEntrataVoce1",
        "tipoBolloVoce1",
        "hashBolloVoce1",
        "provinciaBolloVoce1",
        "idVoce2",
        "importoVoce2",
        "descrizioneVoce2",
        "ibanAccreditoVoce2",
        "ibanAppoggioVoce2",
        "tipoContabilitaVoce2",
        "codiceContabilitaVoce2",
        "tipoEntrataVoce2",
        "tipoBolloVoce2",
        "hashBolloVoce2",
        "provinciaBolloVoce2",
        "idVoce3",
        "importoVoce3",
        "descrizioneVoce3",
        "ibanAccreditoVoce3",
        "ibanAppoggioVoce3",
        "tipoContabilitaVoce3",
        "codiceContabilitaVoce3",
        "tipoEntrataVoce3",
        "tipoBolloVoce3",
        "hashBolloVoce3",
        "provinciaBolloVoce3",
        "idVoce4",
        "importoVoce4",
        "descrizioneVoce4",
        "ibanAccreditoVoce4",
        "ibanAppoggioVoce4",
        "tipoContabilitaVoce4",
        "codiceContabilitaVoce4",
        "tipoEntrataVoce4",
        "tipoBolloVoce4",
        "hashBolloVoce4",
        "provinciaBolloVoce4",
        "idVoce5",
        "importoVoce5",
        "descrizioneVoce5",
        "ibanAccreditoVoce5",
        "ibanAppoggioVoce5",
        "tipoContabilitaVoce5",
        "codiceContabilitaVoce5",
        "tipoEntrataVoce5",
        "tipoBolloVoce5",
        "hashBolloVoce5",
        "provinciaBolloVoce5",
        "dataAvvisatura",
        "idDocumento",
        "descrizioneDocumento",
        "numeroRata",
        'linguaSecondaria'
    ]

    flussoCompleto.push(chiaviFlussoCompleto)


    const readFile = (e) => {
        let file = e.target.files[0]

        readXlsxFile(file).then(
            (rows) => {
                creaFlussoGovPay(rows)
            }
        )
    }


    const parseData = (dataString) => {
        return dataString
    }


    const creaFlussoGovPay = (rows) => {
        if (debug) console.log('ROWS', rows)

        let descrizione = getNomeServizio()

        if (idrico) {
            for (let i = 0; i < rows.length; i++) {
                if (i !== 0) {
                    const row = rows[i];
    
                    const sourceRecord = {}
                    const targetRecord = {}

                    for (let k = 0; k < row.length; k++) {
                        const value = row[k] ? row[k] : ""                
                        sourceRecord[chiaviFlussoIdrico[k]] = value
                    }

                    for (let y = 0; y < chiaviFlussoCompleto.length; y++) {
                        const key = chiaviFlussoCompleto[y];
                        targetRecord[key] = ""
                    }

                    let comune = sourceRecord["COMUNE"] ? sourceRecord["COMUNE"].trim() : ""
                    let localita = sourceRecord["LOCALITA"] ? sourceRecord["LOCALITA"].trim() : ""
                    let comuneLocalita = (comune + " " + localita).replaceAll(',',' ').trim().substr(0, 35)
                    let importo = Number(Number(sourceRecord["IMPORTO"]).toFixed(2))
                    let idPendenza = generaIdPendenza('idrico')
                    let identificativo = sourceRecord["PARTITA_IVA_CODICE_FISCALE"]
                    let indirizzo = sourceRecord["INDIRIZZO"].replaceAll(',',' ').trim().substr(0, 35)
                    let civico = sourceRecord["CIVICO"].toString().trim()
                    let destinatario = sourceRecord["DESTINATARIO"]
                                        .replaceAll('"', "'")
                                        .replaceAll(',', '')
                                        .replaceAll("à", "a'")
                                        .replaceAll("è", "e'")
                                        .replaceAll("é", "e'")
                                        .replaceAll("ì", "i'")
                                        .replaceAll("ò", "o'")
                                        .replaceAll("ù", "u'")
                                        .toUpperCase()
                                        .substr(0, 70)
    
                    if (debug) console.log("DESTINATARIO", destinatario, destinatario.length)

                    targetRecord["idA2A"] = "CPP"
                    targetRecord["idUnitaOperativa"] = "DEMANIO_IDRICO"
                    targetRecord["idPendenza"] = idPendenza
                    targetRecord["idDominio"] = ente.padStart(11, "0")
                    targetRecord["causale"] = "CANONE DEMANIO IDRICO - ANNO 2021"
                    targetRecord["annoRiferimento"] = "2021"
                    targetRecord["cartellaPagamento"] = sourceRecord["CODICE_PRATICA"].trim()
                    targetRecord["importo"] = importo
                    targetRecord["tipoSoggettoPagatore"] = identificativo.length === 16 ? "F" : "G"
                    targetRecord["identificativoPagatore"] = identificativo.padStart(11, "0")
                    targetRecord["anagraficaPagatore"] = destinatario
                    targetRecord["indirizzoPagatore"] = indirizzo ? indirizzo.trim().substr(0, 70) : ""
                    targetRecord["civicoPagatore"] = civico
                    targetRecord["capPagatore"] = sourceRecord["CAP"]
                    targetRecord["localitaPagatore"] = comuneLocalita
                    targetRecord["provinciaPagatore"] = sourceRecord["PROVINCIA"]
                    targetRecord["nazionePagatore"] = sourceRecord["NAZIONE"]
                    targetRecord["emailPagatore"] = sourceRecord["PEC"]
                    targetRecord["idVoce1"] = idPendenza
                    targetRecord["importoVoce1"] = importo
                    targetRecord["descrizioneVoce1"] = descrizione
                    targetRecord["tipoEntrataVoce1"] = servizio

                    flussoCompleto.push(targetRecord)
                }
            }
        } else if (bonario) {
            if (debug) console.log("FLUSSO BONARIO")
            for (let i = 0; i < rows.length; i++) {
                if (i !== 0) {
                    const row = rows[i];
    
                    const sourceRecord = {}
                    const targetRecord = {}

                    for (let k = 0; k < row.length; k++) {
                        const value = row[k] ? row[k] : ""                
                        sourceRecord[chiaviIdricoBonario[k]] = value
                    }

                    for (let y = 0; y < chiaviFlussoCompleto.length; y++) {
                        const key = chiaviFlussoCompleto[y];
                        targetRecord[key] = ""
                    }

                    let pec = sourceRecord["PEC"].split(" ")[0]
                    let comune = sourceRecord["COMUNE_RESIDENZA"] ? sourceRecord["COMUNE_RESIDENZA"].trim() : ""
                    let localita = sourceRecord["LOCALITA_RESIDENZA"] ? sourceRecord["LOCALITA_RESIDENZA"].trim() : ""
                    let comuneLocalita = (comune + " " + localita).replaceAll(',',' ').substr(0, 35)
                    let importo = Number(Number(sourceRecord["CANONE_TOTALE"]).toFixed(2))
                    let idPendenza = generaIdPendenza('idrico')
                    let codiceFiscale = sourceRecord["CODICE_FISCALE"]
                    let pIva = sourceRecord["PARTITA_IVA"]
                    if (debug) console.log("CF", codiceFiscale)
                    if (debug) console.log("PIVA", pIva)
                    let identificativo = codiceFiscale ? codiceFiscale : pIva
                    let indirizzo = sourceRecord["INDIRIZZO"].replaceAll(",", ".")
                    let civico = sourceRecord["N CIVICO"] ? sourceRecord["N CIVICO"] : ""
                    let destinatario = sourceRecord["DENOMINAZIONE"]
                                        .replaceAll('"', "'")
                                        .replaceAll(',', '')
                                        .replaceAll("à", "a'")
                                        .replaceAll("è", "e'")
                                        .replaceAll("é", "e'")
                                        .replaceAll("ì", "i'")
                                        .replaceAll("ò", "o'")
                                        .replaceAll("ù", "u'")
                                        .toUpperCase()
                                        .substr(0, 70)
    
                    if (debug) console.log("DENOMINAZIONE", destinatario, destinatario.length)

                    targetRecord["idA2A"] = "CPP"
                    targetRecord["idUnitaOperativa"] = "DEMANIO_IDRICO"
                    targetRecord["idPendenza"] = idPendenza
                    targetRecord["idDominio"] = ente.padStart(11, "0")
                    targetRecord["causale"] = "AVVISO BONARIO PER OMESSO PAGAMENTO CANONI"
                    targetRecord["annoRiferimento"] = "2021"
                    targetRecord["cartellaPagamento"] = sourceRecord["CODICE_UNICO"].trim()
                    targetRecord["importo"] = importo
                    targetRecord["tipoSoggettoPagatore"] = identificativo.length === 16 ? "F" : "G"
                    targetRecord["identificativoPagatore"] = identificativo.padStart(11, "0")
                    targetRecord["anagraficaPagatore"] = destinatario
                    targetRecord["indirizzoPagatore"] = indirizzo ? indirizzo.trim().substr(0, 70) : ""
                    targetRecord["civicoPagatore"] = civico.length ? civico.trim().substr(0, 16) : ""
                    targetRecord["capPagatore"] = sourceRecord["CAP_RESIDENZA"] ? sourceRecord["CAP_RESIDENZA"] : ""
                    targetRecord["localitaPagatore"] = comuneLocalita
                    targetRecord["provinciaPagatore"] = sourceRecord["PROVINCIA_RESIDENZA"]
                    targetRecord["nazionePagatore"] = "IT"
                    targetRecord["emailPagatore"] = pec
                    targetRecord["idVoce1"] = idPendenza
                    targetRecord["importoVoce1"] = importo
                    targetRecord["descrizioneVoce1"] = descrizione
                    targetRecord["tipoEntrataVoce1"] = servizio

                    flussoCompleto.push(targetRecord)
                }
            }
        } else {
            for (let i = 0; i < rows.length; i++) {
                if (i !== 0) {
                    const row = rows[i];
    
                    const sourceRecord = {}
                    const targetRecord = {}
    
                    for (let k = 0; k < row.length; k++) {
                        const value = row[k]                    
                        sourceRecord[chiaviFlussoRidotto[k]] = value
                    }
    
                    for (let y = 0; y < chiaviFlussoCompleto.length; y++) {
                        const key = chiaviFlussoCompleto[y];
                        const value = sourceRecord[key] ? sourceRecord[key] : ""
                        value.replaceAll(',', '')

                        if (key === 'dataValidita' || key === 'dataScadenza') {
                            value = parseData(value)
                        }

                        targetRecord[key] = value
                    }
    
                    let importo = Number(Number(sourceRecord["importo"]).toFixed(2))
                    let idPendenza = generaIdPendenza()
    
                    if (debug) console.log("ID PENDENZA", idPendenza)
    
                    targetRecord["idA2A"] = "CPP"
                    targetRecord["idDominio"] = ente
                    targetRecord["idPendenza"] = idPendenza
                    targetRecord["importo"] = importo
                    targetRecord["causale"] = targetRecord["causale"].replaceAll('"', "'")
                    targetRecord["idVoce1"] = idPendenza
                    targetRecord["tipoEntrataVoce1"] = servizio
                    targetRecord["descrizioneVoce1"] = descrizione
                    targetRecord["importoVoce1"] = importo
    
                    flussoCompleto.push(targetRecord)
                }            
            }
        }



        if (debug) console.log("FLUSSO COMPLETO", flussoCompleto)
    }


    const scaricaFlusso = () => {
        var csv = ""

        for (var i = 0; i < flussoCompleto.length; i++) {
    
            var line = ""
            var row = flussoCompleto[i]
    
            if (i === 0) {
                row.forEach(item => {
                    line += (item + ",")
                });
            } else {
                chiaviFlussoCompleto.forEach(key => {
                    line += (row[key] + ",")
                });            
            }
    
            line += "\r\n"
    
            csv += line
        }
    
        if (debug) console.log(csv)
        var downloadLink = document.createElement("a");
        var blob = new Blob(["\ufeff", csv]);
        var url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "report.csv";
    
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);        
    }


    return (
        <Container className="center">
            <Card>
                <Row>
                    <Col className="center" s={12}>
                        <h1>CONVERTI FLUSSO</h1>
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={4}>
                        <SelezionaProvincia />
                    </Col>
                    <Col s={12} l={4}>
                        <SelezionaEnte />
                    </Col>
                    <Col s={12} l={4}>
                        <SelezionaServizio />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Checkbox id="checkIdrico" label="Flusso ruolo idrico" value={idrico} onChange={setIdrico} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Checkbox id="checkBonario" label="Ruolo idrico bonario" value={bonario} onChange={setBonario} />
                    </Col>
                </Row>
                <Row>
                    <Col s={12}>
                        <TextInput s={12} id="fileUpload" label="CARICA FLUSSO DA CONVERTIRE" type="file" onChange={readFile} />
                    </Col>
                </Row>
                <Row>
                    <Col s={12}>
                        <Button onClick={scaricaFlusso}>SCARICA FLUSSO CONVERTITO</Button>
                    </Col>
                </Row>
            </Card>
        </Container>
    )
}