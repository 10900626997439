import React, { useContext, useState } from 'react'
import 'materialize-css'
import { Row, Col, TextInput, Select, Button, Icon, Card } from 'react-materialize'
// custom hooks
import { useGeneraIdPendenza } from "../components/hooks/useGeneraIdPendenza"
import { useGetNomeServizio } from "../components/hooks/useGetNomeServizio"
import { useGetAnni } from "../components/hooks/useGetAnni"
import { useSigleNazioni } from "../components/hooks/useSigleNazioni"
// import { useTokenCittadino } from "../components/hooks/useTokenCittadino"
import provinceComuniAbruzzo from '../json/provincie_comuni_abruzzo.json'

import { GlobalContext } from '../context/GlobalState';

export const FormPagamentoDPC024_ScarichiIdrici = (props) => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    // const {token} = useTokenCittadino()
    const getNomeServizio = useGetNomeServizio()
    const generaIdPendenza = useGeneraIdPendenza()
    const [ anno, anni ] = useGetAnni()
    const sigleNazioni = useSigleNazioni()

    const [ elencoComuni, setElencoComuni ] = useState([])

    if (debug) console.log('provinceComuniAbruzzo', provinceComuniAbruzzo)

    const { 
        provincia, 
        ente, 
        servizio, 
        setNuovaPendenza, 
        carrello, 
        aggiornaPendenzaCarrello 
    } = useContext(GlobalContext)


    const idUnitaOperativa = {
        "L'Aquila": "GENIO_CIVILE_AQUILA",
        "Chieti": "GENIO_CIVILE_CHIETI",
        "Pescara": "GENIO_CIVILE_PESCARA",
        "Teramo": "GENIO_CIVILE_TERAMO",
    }


    const formIniziale = {
        idDominio: ente,
        codEntrata: servizio,
        tipo: "G",
        identificativo: "",
        cognome: "",
        nome: "",
        indirizzo: "",
        civico: "",
        cap: "",
        localita: "",
        provincia: "",
        nazione: "IT",
        causale: "",
        importo: "",
        provinciaSelezionata: "",
        tipoPagamento: "",
        annoRiferimento: anno,
    }


    const getFormData = () => {
        if (props?.modifica && carrello[props?.modifica] !== undefined) {
            return carrello[props?.modifica].datiForm
        } else {
            return formIniziale
        }
    }

    const [datiForm, setDatiForm] = useState(getFormData());

    const handleChange = (e) => {
        let id = e.target.id
        let value = e.target.value
        setDatiForm({
            ...datiForm,
            [id]: value
        });

        if (id === 'provinciaSelezionata') {
            setElencoComuni(value.length ? provinceComuniAbruzzo[value] : [])
        }
    }


    const checkForm = () => {
        if (debug) console.log(provincia, ente, servizio, datiForm)

        let valid = true

        if (!provincia.length || !ente.length || !servizio.length) valid = false
        if (datiForm.annoRiferimento.length === 0 || !anni.includes(parseInt(datiForm.annoRiferimento))) valid = false
        if (datiForm.importo < 0.01) valid = false
        if (datiForm.identificativo.length !== 11 && datiForm.identificativo.length !== 16) valid = false
        if (datiForm.cognome.length < 1) valid = false
        if (datiForm.civico.length && !datiForm.indirizzo.length) valid = false
        if (datiForm.provincia !== "" && datiForm.provincia.length !== 2) valid = false
        if (datiForm.cap !== "" && datiForm.cap.length !== 5) valid = false
        if (datiForm.nazione !== "" && datiForm.nazione.length !== 2) valid = false
        if (datiForm.provinciaSelezionata === "") valid = false
        if (datiForm.tipoPagamento === "") valid = false

        return valid
    }


    const submitForm = (e) => {
        e.preventDefault();

        let idA2A = "CPP"
        let idDominio = ente
        let idPendenza = generaIdPendenza('arisgan', false)
        let descrizione = getNomeServizio()
        let codEntrata = servizio
        let importo = Number(Number(datiForm.importo).toFixed(2))

        let soggettoPagatore = {
            tipo: datiForm.tipo,
            identificativo: datiForm.identificativo,
            anagrafica: datiForm.cognome.trim(),
        }

        if (datiForm.indirizzo.length) soggettoPagatore['indirizzo'] = datiForm.indirizzo
        if (datiForm.civico.length) soggettoPagatore['civico'] = datiForm.civico
        if (datiForm.cap.length) soggettoPagatore['cap'] = datiForm.cap
        if (datiForm.localita.length) soggettoPagatore['localita'] = datiForm.localita
        if (datiForm.provincia.length) soggettoPagatore['provincia'] = datiForm.provincia
        if (datiForm.nazione.length) soggettoPagatore['nazione'] = datiForm.nazione

        let datiPendenza = {
            idA2A: idA2A,
            idPendenza: idPendenza,
            idDominio: idDominio,
            causale: componiCausale(),
            soggettoPagatore: soggettoPagatore,
            importo: importo,
            annoRiferimento: datiForm.annoRiferimento,
            idUnitaOperativa: idUnitaOperativa[datiForm.provinciaSelezionata],
            voci: [
                {
                    idVocePendenza: idPendenza,
                    importo: importo,
                    descrizione: descrizione,
                    codEntrata: codEntrata
                }
            ]
        }

        let pendenza = { datiForm, datiPendenza }

        
        if (props?.onSubmit) {
            setNuovaPendenza(pendenza)
            props.onSubmit()
        }
    }


    const componiCausale = () => {
        let causale = `${datiForm.tipoPagamento} - Provincia di ${datiForm.provinciaSelezionata}`

        return causale
    }


    const aggiornaPendenza = (e) => {
        e.preventDefault();

        let pendenzaDaModificare = carrello[props.modifica]
        let importo = Number(Number(datiForm.importo).toFixed(2))

        let soggettoPagatore = {
            tipo: datiForm.tipo,
            identificativo: datiForm.identificativo,
            anagrafica: (datiForm.nome + " " + datiForm.cognome).trim(),
        }

        if (datiForm.indirizzo.length) soggettoPagatore['indirizzo'] = datiForm.indirizzo
        if (datiForm.civico.length) soggettoPagatore['civico'] = datiForm.civico
        if (datiForm.cap.length) soggettoPagatore['cap'] = datiForm.cap
        if (datiForm.localita.length) soggettoPagatore['localita'] = datiForm.localita
        if (datiForm.provincia.length) soggettoPagatore['provincia'] = datiForm.provincia
        if (datiForm.nazione.length) soggettoPagatore['nazione'] = datiForm.nazione

        datiForm.causale = componiCausale()
        pendenzaDaModificare.datiForm = datiForm
        pendenzaDaModificare.datiPendenza.causale = datiForm.causale
        pendenzaDaModificare.datiPendenza.importo = importo
        pendenzaDaModificare.datiPendenza.annoRiferimento = datiForm.annoRiferimento
        pendenzaDaModificare.datiPendenza.soggettoPagatore = soggettoPagatore
        pendenzaDaModificare.datiPendenza.voci[0].importo = importo

        let payload = {
            index: props.modifica,
            pendenza: pendenzaDaModificare
        }

        if (props?.onUpdate) {
            aggiornaPendenzaCarrello(payload)
            props.onUpdate()
        }
    }


    return (
        <>
            <Card title="Dati del pagamento">
                <Row>
                    <Col s={12} l={4}>
                        <TextInput s={12} label="Euro (*)" id="importo" onChange={handleChange} value={datiForm.importo} type="number" step="0.01" min="0.01" validate required />
                    </Col> 
                    <Col s={12} l={4}>
                        <Select
                            s={12}
                            label="Anno di Riferimento (*)"
                            id="annoRiferimento"
                            multiple={false}
                            onChange={ handleChange }
                            options={{
                                classes: '',
                                dropdownOptions: {
                                    alignment: 'left',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: false,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250
                                }
                            }}
                            value={ datiForm.annoRiferimento }
                            validate
                            required
                        >
                            { anni.map((a) => (<option value={a} key={a}>{a}</option>)) }
                        </Select>
                    </Col>
                    <Col s={12} l={4}>
                        <Select
                            s={12}
                            label="Provincia (*)"
                            id="provinciaSelezionata"
                            multiple={false}
                            onChange={ handleChange }
                            options={{
                                classes: '',
                                dropdownOptions: {
                                    alignment: 'left',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: false,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250
                                }
                            }}
                            value={ datiForm.provinciaPagamento }
                            validate
                            required
                        >
                            <option value="" key="">Seleziona Provincia</option>
                            <option value="L'Aquila" key="L'Aquila">L'Aquila</option>
                            <option value="Chieti" key="Chieti">Chieti</option>
                            <option value="Pescara" key="Pescara">Pescara</option>
                            <option value="Teramo" key="Teramo">Teramo</option>
                        </Select>
                    </Col> 
                    <Col s={12} l={12}>
                        <Select
                            s={12}
                            label="Tipo di pagamento (*)"
                            id="tipoPagamento"
                            multiple={false}
                            onChange={ handleChange }
                            options={{
                                classes: '',
                                dropdownOptions: {
                                    alignment: 'left',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: false,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250
                                }
                            }}
                            value={ datiForm.tipoPagamento }
                            validate
                            required
                        >
                            <option value="" key="">Seleziona il pagamento</option>
                            <option value="DPC024 - Oneri Istruttori ACQUE REFLUE URBANE" key="0">DPC024 - Oneri Istruttori ACQUE REFLUE URBANE</option>
                            <option value="DPC024 - Oneri Istruttori ACQUE REFLUE INDUSTRIALI E/O DI PRIMA PIOGGIA" key="1">DPC024 - Oneri Istruttori ACQUE REFLUE INDUSTRIALI E/O DI PRIMA PIOGGIA</option>
                            <option value="DPC024 - Oneri Istruttori ACQUE REFLUE DOMESTICHE E ASSIMILABILI ALLE DOMESTICHE" key="2">DPC024 - Oneri Istruttori ACQUE REFLUE DOMESTICHE E ASSIMILABILI ALLE DOMESTICHE</option>
                            <option value="DPC024 - Oneri Istruttori ACQUE REFLUE - TUTTE LE TIPOLOGIE (VOLTURA E/O MODIFICHE NON SOSTANZIALI)" key="3">DPC024 - Oneri Istruttori ACQUE REFLUE - TUTTE LE TIPOLOGIE (VOLTURA E/O MODIFICHE NON SOSTANZIALI)</option>
                            <option value="DPC024 - Oneri Istruttori AUA (VOLTURA E/O MODIFICHE NON SOSTANZIALI)" key="4">DPC024 - Oneri Istruttori AUA (VOLTURA E/O MODIFICHE NON SOSTANZIALI)</option>
                        </Select>
                    </Col>                    
                </Row>
                <Row>
                    <Col s={12}>
                        <TextInput s={12} label="Causale del Servizio (*)" id="causale" onChange={handleChange} value={componiCausale()} readOnly disabled />                        
                    </Col>
                </Row>
                <p>(*) CAMPI OBBLIGATORI</p>
            </Card>
            <Card title="Dati soggetto pagatore">
                <Row>
                    <Col s={12} l={6}>
                        <Select s={12} id="tipo" onChange={handleChange} value={datiForm.tipo} >
                            <option value="F">PERSONA FISICA</option>
                            <option value="G">PERSONA GIURIDICA</option>
                        </Select>
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Codice Fiscale / Partita IVA (*)" id="identificativo" onChange={handleChange} value={datiForm.identificativo} minLength={datiForm.tipo === "G" ? 11 : 16} maxLength={datiForm.tipo === "G" ? 11 : 16} validate required  />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Cognome / Ragione Sociale (*)" id="cognome" onChange={handleChange} value={datiForm.cognome} validate required />
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Nome" id="nome" onChange={handleChange} value={datiForm.nome} />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Indirizzo" id="indirizzo" onChange={handleChange} value={datiForm.indirizzo} />
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Numero Civico" id="civico" onChange={handleChange} value={datiForm.civico} />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Località" id="localita" onChange={handleChange} value={datiForm.localita} />
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Sigla Provincia" id="provincia" onChange={handleChange} value={datiForm.provincia} maxLength="2" validate />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="CAP" id="cap" onChange={handleChange} value={datiForm.cap} maxLength="5" validate />
                    </Col>
                    <Col s={12} l={6}>
                        <Select
                            s={12}
                            label="Nazione (*)"
                            id="nazione"
                            multiple={false}
                            onChange={ handleChange }
                            options={{
                                classes: '',
                                dropdownOptions: {
                                    alignment: 'left',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: false,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250
                                }
                            }}
                            value={ datiForm.nazione }
                            validate
                            required
                        >
                            { sigleNazioni.map((a) => (<option value={a.sigla} key={a.sigla}>{a.nome}</option>)) }
                        </Select>
                    </Col>
                </Row>
                <p>(*) CAMPI OBBLIGATORI</p>
            </Card>
            <Row>
                <Col s={12}>
                    {
                        props?.modifica && carrello[props?.modifica] !== undefined ?
                        <Button type="submit" node="button" waves="light" style={{width:'100%'}} large onClick={aggiornaPendenza}>
                            AGGIORNA PENDENZA
                            <Icon right>send</Icon>
                        </Button> :
                        <Button type="submit" node="button" waves="light" style={{width:'100%'}} large onClick={submitForm} disabled={!checkForm()}>
                            PROCEDI
                            <Icon right>send</Icon>
                        </Button>
                    }
                </Col>
            </Row>
        </>
    )
}
