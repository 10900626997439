export const useGetAnni = () => {

    const anno = new Date().getFullYear()
    const anni = []

    anni.push(anno + 1)

    for (let a = anno; a > anno - 11; a--) {
        anni.push(a)
    }

    return [ anno, anni ]
    
}