import React, { useContext, useEffect, useState } from 'react'
import 'materialize-css'
import { Row, Col, Card, Button } from 'react-materialize'

import { LoadingFull } from '../loadingFull';

import { useTokenCittadino } from "../hooks/useTokenCittadino"

import { GlobalContext } from '../../context/GlobalState';


export const GestioneEnteStoricoTicket = ({dettaglio, redirect, match}) => {
    // const debug = process.env.REACT_APP_DEBUG === "true"

    const { token } = useTokenCittadino()
    const { datiEnte, cittadino } = useContext(GlobalContext);

    const [ elencoTicket, setElencoTicket ] = useState([])
    const [ loading, setLoading ] = useState(false)

    const idDominio = datiEnte?.idDominio;
    const codiceFiscale = cittadino?.cf;

    const tipologieProblemi = {
        DELEGHE: 'Deleghe per stampa avvisi di pagamento (inserimento/rimozione)',
        SERVIZI: 'Servizi attivi (inserimento/modifica/rimozione)',
        OPERATORI: 'Operatori Ente (inserimento/modifica/rimozione)',
        DISSERVIZIO: 'Segnala un disservizio o un malfunzionamento del portale PagoRA'
    }

    useEffect(() => {
        if (token) {
            const getTicket = async () => {
                setLoading(true)
                let response = await fetch(process.env.REACT_APP_SERVER_URL + 'ticket/elenco', {
                    method: 'POST',
                    body: JSON.stringify({token, codiceFiscale, idDominio})
                })
    
                let jsonResponse = await response.json()
                
                console.log({jsonResponse})
                
                if (jsonResponse.valid) {
                    setElencoTicket(jsonResponse.findResult)
                    setLoading(false)
                } else {
                    redirect("/")
                }
            }
    
            getTicket()
        } else {
            redirect("/")
        }
    }, [token, codiceFiscale, idDominio])

    return (
        <>
            { loading ? <LoadingFull /> : null }
            <Row>
                <Col>
                    <h4 style={{marginTop: 0}}>Storico Ticket</h4>
                </Col>
            </Row>
            <Row>
                <Col s={12}>
                    {
                        elencoTicket.length ?
                        elencoTicket.map(
                            (ticket, i) => (
                                <Card key={i}>
                                    <p><b>IDENTIFICATIVO RICHIESTA:</b> {ticket.ticketCode}</p>
                                    <p><b>TIPO:</b> {tipologieProblemi[ticket.tipologiaProblema]}</p>
                                    <p><b>DATA RICHIESTA:</b> {ticket.dataRichiesta}</p>
                                    <p><b>STATO:</b> {ticket.corpoTicket.Result.document.stato[ticket.corpoTicket.Result.document.stato.length -1].statoDesc}</p>
                                    <p><b>ULTIMO AGGIORNAMENTO:</b> {ticket.corpoTicket.Result.document.stato[ticket.corpoTicket.Result.document.stato.length -1].data}</p>
                                    <br />
                                    <p><b>RICHIESTA:</b></p>
                                    <p>{ticket.contenutoTicket}</p>
                                </Card>
                            )
                        ) :
                        <Card>
                            <p>NON CI SONO TICKET DA VISUALIZZARE</p>
                        </Card>
                    }
                </Col>
            </Row>
        </>
    );
}