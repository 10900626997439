import React, { useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import 'materialize-css'
import { Container, Card, Row, Col, Button, TextInput, Select } from 'react-materialize'
import { HeaderCittadino } from '../components/HeaderCittadino'
import { PageFooter } from "../components/PageFooter"
import { GlobalContext } from '../context/GlobalState'
import { useTokenCittadino } from "../components/hooks/useTokenCittadino"


export const CarrelloPage = () => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const history = useHistory()
    const location = useLocation()
    const [ identificativoVersante, setIdentificativoVersante ] = useState("")
    const [ anagraficaVersante, setAnagraficaVersante ] = useState("")
    const [ tipoVersante, setTipoVersante ] = useState("F")
    const { token } = useTokenCittadino()
    if (debug) console.log('LOCATION', location)

    const { 
        carrello, 
        removePendenzaCarrello, 
        svuotaCarrello,
        provincia,
        ente,
        servizio,
        strutturaSelettori,
        saveContext,
        cittadino 
    } = useContext(GlobalContext);

    saveContext()

    if (debug) console.log(carrello)

    const getNomeEnte = (idDominio) => {
        let nomeEnte = ""
        
        let province = Object.keys(strutturaSelettori)

        province.forEach(
            (provincia) => {
                let enti = strutturaSelettori[provincia].enti
                enti.forEach(
                    (ente) => {
                        if (ente.idDominio === idDominio) {
                            nomeEnte = ente.ragioneSociale
                        }
                    }
                )
            }
        )

        return nomeEnte
    }

    const pulisciCarrello = () => {
        svuotaCarrello()
        saveContext()
    }

    const aggiungiPagamento = () => {
        history.push('/pagamento')
    }

    const modificaPendenza = (index) => {
        if (debug) console.log('MODIFICA: '+index)
        if (debug) console.log("P: "+provincia, "E: "+ente, "S: "+servizio)
        if (debug) console.log('/pagamento?modifica='+index)
        history.push('/pagamento?modifica='+index)
    }

    const eliminaPendenza = (index) => {
        if (debug) console.log('RIMUOVI: '+index)
        removePendenzaCarrello(index)
        saveContext()
    }

    const eseguiPagamento = () => {
        let soggettoVersante = {
            tipo: "",
            identificativo: "",
            anagrafica: ""
        }

        if (token) {
            soggettoVersante.identificativo = cittadino.cf
            soggettoVersante.anagrafica = (cittadino.nome + " " + cittadino.cognome).trim()
            soggettoVersante.tipo = "F"
        } else {
            soggettoVersante.identificativo = identificativoVersante
            soggettoVersante.anagrafica = anagraficaVersante
            soggettoVersante.tipo = tipoVersante
        }

        let pagamenti = {
            urlRitorno: window.location.origin + "/esito_pagamento",
            pendenze: carrello.map((item) => {
                if(item.datiPendenza) {
                    return item.datiPendenza
                } else {
                    return item
                }
            }),
            soggettoVersante
        }

        pulisciCarrello()
        if (debug) console.log(carrello, pagamenti)

        fetch(process.env.REACT_APP_SERVER_URL + 'richiedi_pagamento?richiedente=' + soggettoVersante.identificativo, {
            method: 'POST',
            body: JSON.stringify(pagamenti)
        })
            .then(response => response.json())
            .then(data => {                
                if (debug) console.log(data)
                if (data.redirect) {                    
                    localStorage.setItem("richiestaPagamento", JSON.stringify(data))
                    window.open(data.redirect, '_self')
                } else {
                    localStorage.setItem("error", JSON.stringify(data))
                    history.push('/error')
                }
            });
    }


    const handleChange = (e) => {
        let id = e.target.id
        let value = e.target.value
        
        if (id === "identificativoVersante") setIdentificativoVersante(value)

        if (id === "anagraficaVersante") setAnagraficaVersante(value)

        if (id === "tipoVersante") setTipoVersante(value)
    }


    const checkVersante = () => {
        if (token && cittadino) return false

        if (anagraficaVersante.length > 0) {
            if (tipoVersante === "F" && identificativoVersante.length === 16) return false 
            
            if (tipoVersante === "G" && identificativoVersante.length === 11) return false
        }

        return true
    }


    return (
        <>
            <Container>
                <HeaderCittadino history={history} />
                <Row>
                    <Col s={12}>
                    {
                        carrello.length ?
                        carrello.map(
                            (item, index) => (<Card key={index}>
                                {
                                    item.numeroAvviso ?
                                    <>
                                        <Row>
                                            <Col s={12} m={4}>
                                                <b>Numero avviso:</b> {item.numeroAvviso}
                                            </Col>
                                            <Col s={12} m={4}>
                                                <b>Dominio:</b> {item.idDominio}
                                            </Col>
                                            <Col s={12} m={4}>
                                                <b>CF:</b> {item.idDebitore}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button onClick={() => eliminaPendenza(index)}>ELIMINA</Button>
                                            </Col>
                                        </Row>
                                    </> :
                                    <>
                                        <Row style={{marginBottom: 0}}>
                                            <Col s={12}>
                                                <b>Soggetto Debitore:</b> {item.datiPendenza.soggettoPagatore.anagrafica}
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: 0}}>
                                            <Col s={12}>
                                                <b>Ente Creditore:</b> {getNomeEnte(item?.datiPendenza?.idDominio)}
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: 0}}>
                                            <Col s={12}>
                                                <b>Servizio:</b> {item.datiPendenza.voci[0].descrizione}
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: 0}}>
                                            <Col s={12}>
                                                <b>Causale:</b> {item.datiPendenza.causale}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col s={12}>
                                                <b>Importo:</b> € {item.datiPendenza.importo}
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: 0}}>
                                            <Col>
                                                <Button onClick={() => modificaPendenza(index)}>MODIFICA</Button> <Button onClick={() => eliminaPendenza(index)}>ELIMINA</Button>
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </Card>)
                        ) :
                        <Card>
                            <Row>
                                <Col>
                                    <p>NON CI SONO ELEMENTI NEL CARRELLO</p>
                                </Col>
                            </Row>
                        </Card>
                    }
                    </Col>
                </Row>
                <Row>
                    <Col s={12}>
                        <Card title="Dati del Soggetto Versante">
                            {
                                token && cittadino ?
                                <Row>
                                    <Col s={12}><b>ANAGRAFICA:</b> {(cittadino.nome + " " + cittadino.cognome).trim()}</Col>
                                    <Col s={12}><b>C.F. / P.IVA:</b> {cittadino.cf}</Col>
                                </Row> :
                                <Row>
                                    <Col s={12} l={4}>
                                        <Select s={12} id="tipoVersante" onChange={handleChange} value={tipoVersante} >
                                            <option value="F">PERSONA FISICA</option>
                                            <option value="G">PERSONA GIURIDICA</option>
                                        </Select>
                                    </Col>
                                    <Col s={12} l={4}>
                                        <TextInput s={12} label="Codice Fiscale / Partita IVA (*)" id="identificativoVersante" onChange={handleChange} value={identificativoVersante} minLength={tipoVersante === "G" ? 11 : 16} maxLength={tipoVersante === "G" ? 11 : 16} validate required  />
                                    </Col>
                                    <Col s={12} l={4}>
                                        <TextInput s={12} label="Nome e Cognome / Ragione Sociale (*)" id="anagraficaVersante" onChange={handleChange} value={anagraficaVersante} validate required  />
                                    </Col>
                                </Row>
                            }
                        </Card>
                    </Col>
                </Row>
                {
                    carrello.length ?
                    <Row>
                        <Col s={12} m={4}>
                            <Button node="button" waves="light" style={{width:'100%'}} large onClick={pulisciCarrello}>SVUOTA CARRELLO</Button>
                        </Col>
                        <Col s={12} m={4}>
                            <Button node="button" waves="light" style={{width:'100%'}} large onClick={aggiungiPagamento} disabled={carrello.length > 4}>AGGIUNGI UN PAGAMENTO</Button>
                        </Col>
                        <Col s={12} m={4}>
                            <Button node="button" waves="light" style={{width:'100%'}} large onClick={eseguiPagamento} disabled={checkVersante()}>ESEGUI PAGAMENTO</Button>
                        </Col>
                    </Row> : 
                    null
                }
                <PageFooter />
            </Container>
        </>
    );
}