import { useState } from 'react';

export const useTokenCittadino = () => {

    const getToken = () => {
        const tokenString = localStorage.getItem('access_token');
        const userToken = JSON.parse(tokenString);
        return userToken
    };
      
    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.removeItem('token_ente')
        localStorage.setItem('access_token', JSON.stringify(userToken));
        setToken(userToken.token);
    };

    return {
        setToken: saveToken,
        token
    }
}