import React, {useContext} from 'react'
import 'materialize-css'
import { Navbar, NavItem, Icon, Row, Col } from 'react-materialize'
import logo from '../img/Regione-Abruzzo-Stemma-scritta.png'
import { GlobalContext } from '../context/GlobalState'
import { useTokenCittadino } from "./hooks/useTokenCittadino"

export const HeaderCittadino = (props) => {
    // const debug = process.env.REACT_APP_DEBUG === "true"
    const { token } = useTokenCittadino()

    const { carrello } = useContext(GlobalContext)

    const toHome = (e) => {
        e.preventDefault()
        if (props?.history) {
            props.history.push("/")
        }
    }

    const toCarrello = (e) => {
        e.preventDefault()
        if (props?.history) {
            props.history.push("/carrello")
        }
    }

    const toContatti = (e) => {
        e.preventDefault()
        if (props?.history) {
            props.history.push("/contatti")
        }
    }

    const toSpid = (e) => {
        e.preventDefault()
        window.location.href = 'https://adfs.regione.abruzzo.it/adfs/oauth2/authorize?resource=' + process.env.REACT_APP_ADFS_RESOURCE + '&client_id=' + process.env.REACT_APP_ADFS_CLIENT_ID + '&response_type=token&redirect_uri=' + process.env.REACT_APP_ADFS_REDIRECT_URI
    }

    const toProfile = (e) => {
        e.preventDefault()
        if (props?.history) {
            props.history.push("/gestione_cittadino/anagrafica")
        }
    }

    const logout = (e) => {
        e.preventDefault()
        localStorage.clear()
        if (props?.history) {
            props.history.push('/')
        }
    }

    return (
        <>
            <Row>
                <Col
                    l={6}
                    m={9}
                    s={12}
                >
                    <img
                        alt=""
                        src={logo}
                        width="100%"
                    />
                </Col>
            </Row>
            <Row>
                <Col s={12}>
                    <Navbar
                        className="blue darken-4"
                        alignLinks="right"
                        brand={<a className="brand-logo" href="/" onClick={toHome} style={{paddingLeft: '15px'}}><p style={{margin: 0}} className="flow-text">PagoPA Regione Abruzzo</p></a>}
                        id="mobile-nav"
                        menuIcon={<Icon>menu</Icon>}
                        options={{
                            draggable: true,
                            edge: 'left',
                            inDuration: 250,
                            onCloseEnd: null,
                            onCloseStart: null,
                            onOpenEnd: null,
                            onOpenStart: null,
                            outDuration: 200,
                            preventScrolling: true
                        }}
                    >
                        { token ?
                        <NavItem href="#" onClick={logout}>
                            ESCI
                        </NavItem> :
                        <NavItem href="#" onClick={toSpid}>
                            ACCEDI CON SPID/CIE
                        </NavItem>
                        }
                        { token ?
                        <NavItem href="#" onClick={toProfile}>
                            PROFILO CITTADINO
                        </NavItem> :
                        <></>
                        }
                        <NavItem href="/contatti" onClick={toContatti}>
                            CONTATTI
                        </NavItem>
                        <NavItem href="/carrello" onClick={toCarrello}>
                            CARRELLO ({carrello.length}/5)
                        </NavItem>
                    </Navbar>
                </Col>
            </Row>
        </>
    )
}
