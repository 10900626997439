import serviziNascosti from '../../json/servizi_nascosti.json'

export const useGetServiziNascosti = () => {
    const debug = process.env.REACT_APP_DEBUG === "true"

    const getServiziNascosti = (ente, servizio) => {
        let enti = Object.keys(serviziNascosti)

        if (enti.includes(ente)) {
            let servizi = serviziNascosti[ente]
            return servizi.includes(servizio)
        } 

        return false
    }

    return getServiziNascosti
}