import React, { useContext, useState } from 'react'
import 'materialize-css'
import { Card, Button, Row, Col, DatePicker, Select } from 'react-materialize'
import { SelezionaProvincia } from './SelezionaProvincia'
import { SelezionaEnte } from './SelezionaEnte'

import { GlobalContext } from '../context/GlobalState';


export const CardFiltriCittadino = ({tipo, onFilterSaved}) => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const {strutturaSelettori, setStrutturaSelettori, ente, cittadino } = useContext(GlobalContext);

    if (strutturaSelettori == null) {

        let richiedente = cittadino ? cittadino.cf : 'anonimo'

        fetch(process.env.REACT_APP_SERVER_URL + 'getStrutturaSelettori?richiedente=' + richiedente)
            .then(response => response.json())
            .then(data => {
                if (debug) console.log("strutturaSelettori POST", data)
                setStrutturaSelettori(data);
            })
    }

    const filterFields = {
        idDominio: ente ? ente : "",
        stato: "",
        dataDa: "",
        dataA: "",
        visualizza: "intestatario"
    }

    const statiPagamento = [
        'IN_CORSO',
        'ESEGUITO',
        'NON_ESEGUITO',
        'ESEGUITO_PARZIALE',
        'ANNULLATO',
        'FALLITO'
    ]

    const statiPendenza = [
        'ESEGUITA',
        'NON_ESEGUITA',
        'ESEGUITA_PARZIALE',
        'ANNULLATA',
        'SCADUTA',
        'INCASSATA',
        'ANOMALA'
    ]

    const [filter, setFilter] = useState(filterFields)

    const updateField = (e) => {
        let field = e.target.id.split('_')[1]
        let value = e.target.value

        setFilter({
            ...filter,
            [field]: value
        })
    }

    const updateDate = (e) => {
        let field = e.target.id.split('_')[1]
        let value = e.target.value

        if (debug) console.log(field, value)

        setFilter({
            ...filter,
            [field]: value
        })
    }

    const showDate = (date) => {
        let result = ""

        if (date) {
            let dd = date.getDate().toString().padStart(2, '0')
            let mm = date.getMonth()
            mm += 1
            mm = mm.toString().padStart(2, '0')
            let yyyy = date.getFullYear().toString()

            result = dd + '/' + mm + '/' + yyyy
        }

        return result
    }

    const updateEnte = (e) => {
        if (debug) console.log('ente', e)
        setFilter({
            ...filter,
            idDominio: e
        })
    }

    const updateProvincia = (e) => {
        if (debug) console.log('provincia', e)
        setFilter({
            ...filter,
            idDominio: ''
        })
    }

    const saveFilter = (e) => {
        if(onFilterSaved) {
            onFilterSaved(filter)
        }
        
    }

    return (
        <Card title="filtra i risultati">
            <Row style={{margin: 0}}>
                {
                    tipo === 'pagamenti' ?
                    <>
                        <Col s={12}>                                
                            <Select id="field_visualizza" s={12} label='VISUALIZZA COME:' value={filter.visualizza} onChange={updateField}>
                                <option value="intestatario">INTESTATARIO DEI PAGAMENTI</option>
                                <option value="versante">SOGGETTO VERSANTE</option>
                            </Select>
                        </Col>
                    </> :
                    null
                }
                {
                    tipo === 'pendenze' ?
                    <>
                        <Col s={12} l={6}>
                            <SelezionaProvincia onChange={updateProvincia} />
                        </Col>
                        <Col s={12} l={6}>                                
                            <SelezionaEnte onChange={updateEnte} />
                        </Col>
                    </> :
                    null
                }
                <Col s={12} m={6} l={3}>
                    <DatePicker s={12} m={12} label='Data da' id="field_dataDa" onChange={(newDate) => {updateDate({target:{id: "field_dataDa", value: newDate}})}} value={showDate(filter.dataDa)} />
                </Col>
                <Col s={12} m={6} l={3}>
                    <DatePicker s={12} m={12} label='Data a' id="field_dataA" onChange={(newDate) => {updateDate({target:{id: "field_dataA", value: newDate}})}} value={showDate(filter.dataA)} />
                </Col>
                <Col s={12} m={6} l={3}>
                    {
                        tipo === 'pagamenti' ?
                        <Select id="field_stato" onChange={updateField}  value={filter.stato} s={12}>
                            <option value="">Stato</option>
                            {statiPagamento.map(
                                (item) => (
                                    <option value={item} key={item}>{item}</option>
                                )
                            )}
                        </Select> :
                        <Select id="field_stato" onChange={updateField}  value={filter.stato} s={12}>
                            <option value="">Stato</option>
                            {statiPendenza.map(
                                (item) => (
                                    <option value={item} key={item}>{item}</option>
                                )
                            )}
                        </Select>
                    }
                </Col>
                <Col s={12} m={6} l={3}>
                    <br />
                    <Button style={{width: '100%'}} onClick={ saveFilter }>CERCA</Button>
                </Col>
            </Row>
        </Card>
    )
}
