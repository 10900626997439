import { useState } from 'react';

export const useTokenEnte = () => {

    const getToken = () => {
        const tokenString = localStorage.getItem('token_ente');
        const userToken = JSON.parse(tokenString);
        return userToken
    };
      
    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.removeItem('access_token');
        localStorage.setItem('token_ente', JSON.stringify(userToken));
        setToken(userToken.token);
    };

    return {
        setToken: saveToken,
        token
    }
}