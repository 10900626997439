import React, { useContext, useState } from 'react'
import 'materialize-css'
import { Row, Col, TextInput, Select, Button, Icon, Card } from 'react-materialize'
// custom hooks
import { useTokenCittadino } from "../components/hooks/useTokenCittadino"
import { useGeneraIdPendenza } from "../components/hooks/useGeneraIdPendenza"
import { useGetNomeServizio } from "../components/hooks/useGetNomeServizio"
import { useGetAnni } from "../components/hooks/useGetAnni"
import { useSigleNazioni } from "../components/hooks/useSigleNazioni"
import { useGetUnitaOperativaPredefinita } from '../components/hooks/useGetUnitaOperativaPredefinita'

import { GlobalContext } from '../context/GlobalState';

export const FormPagamentoDPD023fitosanitario = (props) => {
    // const debug = process.env.REACT_APP_DEBUG === "true"
    const { token } = useTokenCittadino()
    const getNomeServizio = useGetNomeServizio()
    const generaIdPendenza = useGeneraIdPendenza()
    const getUnitaOperativaPredefinita = useGetUnitaOperativaPredefinita()
    const [ anno, anni ] = useGetAnni()
    const sigleNazioni = useSigleNazioni()

    const { 
        provincia, 
        ente, 
        servizio,
        setNuovaPendenza, 
        carrello, 
        aggiornaPendenzaCarrello,
        cittadino
    } = useContext(GlobalContext)


    const dettaglioCausale = [
        "Tariffa Fitosanitaria Annuale: per le aziende iscritte al RUOP",
        "Tariffa Fitosanitaria Annuale: Aziende iscritte al RUOP titolari di autorizzazione all'uso del passaporto delle piante",
        "Tariffa Fitosanitaria Una Tantum: per il rilascio dell'autorizzazione all'uso del passaporto delle piante",
        "Tariffa Fitosanitaria: Diritti obbligatori per controlli all'esportazione",
        "Tariffa Fitosanitaria: Diritti obbligatori per controlli all'importazione",
        "Sanzioni amministrative",
    ]


    const formIniziale = {
        idDominio: ente,
        codEntrata: servizio,
        tipo: "F",
        identificativo: "",
        cognome: "",
        nome: "",
        indirizzo: "",
        civico: "",
        cap: "",
        localita: "",
        provincia: "",
        nazione: "IT",
        causale: "",
        importo: "",
        dettaglioCausale: dettaglioCausale[0],
        territorio: '13',
        codice: '',
        causaleUtente: '',
        annoRiferimento: anno
    }


    const territorio = {
        "01": "Piemonte [01]",
        "02": "Valle d'Aosta [02]",
        "03": "Lombardia [03]",
        "05": "Veneto [05]",
        "06": "Friuli-Venezia Giulia [06]",
        "07": "Liguria [07]",
        "08": "Emilia-Romagna [08]",
        "09": "Toscana [09]",
        "10": "Umbria [10]",
        "11": "Marche [11]",
        "12": "Lazio [12]",
        "13": "Abruzzo [13]",
        "14": "Molise [14]",
        "15": "Campania [15]",
        "16": "Puglia [16]",
        "17": "Basilicata [17]",
        "18": "Calabria [18]",
        "19": "Sicilia [19]",
        "20": "Sardegna [20]",
        "21": "Provincia Autonoma di Bolzano [21]",
        "22": "Provincia Autonoma di Trento [22]"
    }

    const componiCausale = () => {
        let codiceRuop = `IT-${datiForm.territorio}-${datiForm.codice}`
        let causale = `${datiForm.dettaglioCausale}: ${codiceRuop}`

        return causale
    }

    const getFormData = () => {
        if (props?.modifica && carrello[props?.modifica] !== undefined) {
            return carrello[props?.modifica].datiForm
        } else {
            return formIniziale
        }
    }

    const [datiForm, setDatiForm] = useState(getFormData());

    if (token && cittadino) {
        if (datiForm.identificativo === "" && datiForm.nome === "" && datiForm.cognome === "") {
            setDatiForm({
                ...datiForm,
                "identificativo": cittadino.cf,
                "nome": cittadino.nome,
                "cognome": cittadino.cognome,
            });
        }
    }

    const handleChange = (e) => {
        setDatiForm({
            ...datiForm,
            [e.target.id]: e.target.value
        });
    }

    const checkForm = () => {
        let valid = true

        if (!provincia.length || !ente.length || !servizio.length) valid = false
        if (datiForm.causaleUtente.length === 0) valid = false
        if (datiForm.annoRiferimento.length === 0 || !anni.includes(parseInt(datiForm.annoRiferimento))) valid = false
        if (datiForm.importo < 0.01) valid = false
        if (datiForm.identificativo.length !== 11 && datiForm.identificativo.length !== 16) valid = false
        if (datiForm.cognome.length < 1) valid = false
        if (datiForm.civico.length && !datiForm.indirizzo.length) valid = false
        if (datiForm.provincia !== "" && datiForm.provincia.length !== 2) valid = false
        if (datiForm.cap !== "" && datiForm.cap.length !== 5) valid = false
        if (datiForm.nazione !== "" && datiForm.nazione.length !== 2) valid = false

        return valid
    }

    const submitForm = (e) => {
        e.preventDefault();

        let idA2A = "CPP"
        let idDominio = ente
        let idPendenza = generaIdPendenza('', false)
        let descrizione = getNomeServizio()
        let codEntrata = servizio
        let importo = Number(Number(datiForm.importo).toFixed(2))

        let unitaOperativa = getUnitaOperativaPredefinita(ente, servizio)

        let soggettoPagatore = {
            tipo: datiForm.tipo,
            identificativo: datiForm.identificativo,
            anagrafica: (datiForm.nome + " " + datiForm.cognome).trim(),
        }

        if (datiForm.indirizzo.length) soggettoPagatore['indirizzo'] = datiForm.indirizzo
        if (datiForm.civico.length) soggettoPagatore['civico'] = datiForm.civico
        if (datiForm.cap.length) soggettoPagatore['cap'] = datiForm.cap
        if (datiForm.localita.length) soggettoPagatore['localita'] = datiForm.localita
        if (datiForm.provincia.length) soggettoPagatore['provincia'] = datiForm.provincia
        if (datiForm.nazione.length) soggettoPagatore['nazione'] = datiForm.nazione

        let datiPendenza = {
            idA2A: idA2A,
            idPendenza: idPendenza,
            idDominio: idDominio,
            causale: componiCausale(),
            soggettoPagatore: soggettoPagatore,
            importo: importo,
            annoRiferimento: datiForm.annoRiferimento,
            voci: [
                {
                    idVocePendenza: idPendenza,
                    importo: importo,
                    descrizione: descrizione,
                    codEntrata: codEntrata
                }
            ]
        }

        if (unitaOperativa.length) {
            datiPendenza['idUnitaOperativa'] = unitaOperativa
        }

        let pendenza = { datiForm, datiPendenza }
        
        if (props?.onSubmit) {
            setNuovaPendenza(pendenza)
            props.onSubmit()
        }
    }

    const aggiornaPendenza = (e) => {
        e.preventDefault();

        let pendenzaDaModificare = carrello[props.modifica]
        let importo = Number(Number(datiForm.importo).toFixed(2))

        let soggettoPagatore = {
            tipo: datiForm.tipo,
            identificativo: datiForm.identificativo,
            anagrafica: (datiForm.nome + " " + datiForm.cognome).trim(),
        }

        if (datiForm.indirizzo.length) soggettoPagatore['indirizzo'] = datiForm.indirizzo
        if (datiForm.civico.length) soggettoPagatore['civico'] = datiForm.civico
        if (datiForm.cap.length) soggettoPagatore['cap'] = datiForm.cap
        if (datiForm.localita.length) soggettoPagatore['localita'] = datiForm.localita
        if (datiForm.provincia.length) soggettoPagatore['provincia'] = datiForm.provincia
        if (datiForm.nazione.length) soggettoPagatore['nazione'] = datiForm.nazione

        datiForm.causale = componiCausale()
        pendenzaDaModificare.datiForm = datiForm
        pendenzaDaModificare.datiPendenza.causale = datiForm.causale
        pendenzaDaModificare.datiPendenza.importo = importo
        pendenzaDaModificare.datiPendenza.annoRiferimento = datiForm.annoRiferimento
        pendenzaDaModificare.datiPendenza.soggettoPagatore = soggettoPagatore
        pendenzaDaModificare.datiPendenza.voci[0].importo = importo

        let payload = {
            index: props.modifica,
            pendenza: pendenzaDaModificare
        }

        
        if (props?.onUpdate) {
            aggiornaPendenzaCarrello(payload)
            props.onUpdate()
        }
    }

    return (
        <>
            <Card title="Dati del pagamento">
                <Row>
                    <Col s={12}>
                        <Select
                            s={12}
                            label="Specifica del Servizio (*)"
                            id="dettaglioCausale"
                            multiple={false}
                            onChange={ handleChange }
                            options={{
                                classes: '',
                                dropdownOptions: {
                                    alignment: 'left',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: false,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250
                                }
                            }}
                            value={ datiForm.dettaglioCausale }
                            validate
                            required
                        >
                            { dettaglioCausale.map((a) => (<option value={a} key={a}>{a}</option>)) }
                        </Select>
                    </Col>
                    <Col s={12} l={2}>
                        <p><br />CODICE RUOP</p> 
                    </Col>
                    <Col s={12} l={1} style={{textAlign: 'right'}}>
                        <p><br />IT</p> 
                    </Col>
                    <Col s={12} l={5}>
                        <Select
                            s={12}
                            label="Regione/Provincia (*)"
                            id="territorio"
                            multiple={false}
                            onChange={ handleChange }
                            options={{
                                classes: '',
                                dropdownOptions: {
                                    alignment: 'left',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: false,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250
                                }
                            }}
                            value={ datiForm.territorio }
                            validate
                            required
                        >
                            { Object.keys(territorio).map((k) => (<option value={k} key={k}>{territorio[k]}</option>)) }
                        </Select>
                    </Col>
                    <Col s={12} l={4}>
                        <TextInput s={12} minLength='4' maxLength='4' label="Codice (*)" id="codice" onChange={handleChange} value={datiForm.codice} validate />  
                    </Col>
                </Row>
                <Row>
                    <Col s={12}>
                        <TextInput s={12} label="Causale del Servizio (*)" id="causale" onChange={handleChange} value={componiCausale()} validate required disabled />                        
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Euro (*)" id="importo" onChange={handleChange} value={datiForm.importo} type="number" step="0.01" min="0.01" validate required />
                    </Col>
                    <Col s={12} l={6}>
                        <Select
                            s={12}
                            label="Anno di Riferimento (*)"
                            id="annoRiferimento"
                            multiple={false}
                            onChange={ handleChange }
                            options={{
                                classes: '',
                                dropdownOptions: {
                                    alignment: 'left',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: false,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250
                                }
                            }}
                            value={ datiForm.annoRiferimento }
                            validate
                            required
                        >
                            { anni.map((a) => (<option value={a} key={a}>{a}</option>)) }
                        </Select>
                    </Col>
                </Row>
                <p>(*) CAMPI OBBLIGATORI</p>
            </Card>
            <Card title="Dati soggetto pagatore">
                <Row>
                    <Col s={12} l={6}>
                        <Select s={12} id="tipo" onChange={handleChange} value={datiForm.tipo} >
                            <option value="F">PERSONA FISICA</option>
                            <option value="G">PERSONA GIURIDICA</option>
                        </Select>
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Codice Fiscale / Partita IVA (*)" id="identificativo" onChange={handleChange} value={datiForm.identificativo} minLength={datiForm.tipo === "G" ? 11 : 16} maxLength={datiForm.tipo === "G" ? 11 : 16} validate required  />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Cognome / Ragione Sociale (*)" id="cognome" onChange={handleChange} value={datiForm.cognome} validate required />
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Nome" id="nome" onChange={handleChange} value={datiForm.nome} />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Indirizzo" id="indirizzo" onChange={handleChange} value={datiForm.indirizzo} />
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Numero Civico" id="civico" onChange={handleChange} value={datiForm.civico} />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Località" id="localita" onChange={handleChange} value={datiForm.localita} />
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Sigla Provincia" id="provincia" onChange={handleChange} value={datiForm.provincia} maxLength="2" validate />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="CAP" id="cap" onChange={handleChange} value={datiForm.cap} maxLength="5" validate />
                    </Col>
                    <Col s={12} l={6}>
                        <Select
                            s={12}
                            label="Nazione (*)"
                            id="nazione"
                            multiple={false}
                            onChange={ handleChange }
                            options={{
                                classes: '',
                                dropdownOptions: {
                                    alignment: 'left',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: false,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250
                                }
                            }}
                            value={ datiForm.nazione }
                            validate
                            required
                        >
                            { sigleNazioni.map((a) => (<option value={a.sigla} key={a.sigla}>{a.nome}</option>)) }
                        </Select>
                    </Col>
                </Row>
                <p>(*) CAMPI OBBLIGATORI</p>
            </Card>
            <Row>
                <Col s={12}>
                    {
                        props?.modifica && carrello[props?.modifica] !== undefined ?
                        <Button type="submit" node="button" waves="light" style={{width:'100%'}} large onClick={aggiornaPendenza}>
                            AGGIORNA PENDENZA
                            <Icon right>send</Icon>
                        </Button> :
                        <Button type="submit" node="button" waves="light" style={{width:'100%'}} large onClick={submitForm} disabled={!checkForm()}>
                            PROCEDI
                            <Icon right>send</Icon>
                        </Button>
                    }
                </Col>
            </Row>
        </>
    )
}
