import React, { useContext, useState, useEffect } from 'react'
import 'materialize-css'
import { Row, Col, Card, Button, Pagination, Icon } from 'react-materialize'

import { LoadingSmall } from '../loadingSmall';
import { LoadingFull } from '../loadingFull';

import { GlobalContext } from '../../context/GlobalState';

import { useTokenCittadino } from "../hooks/useTokenCittadino"

import { CardFiltriEnte } from '../CardFiltriEnte'


export const GestioneEnteRiconciliazioni = ({dettaglio, redirect, match}) => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const { token } = useTokenCittadino()
    const { datiEnte, cittadino } = useContext(GlobalContext);
    const [riconciliazioni, setRiconciliazioni] = useState(null);
    const [riconciliazione, setRiconciliazione] = useState(null);
    const [ filter, setFilter ] = useState(null);
    const [ codiceEnte, setCodiceEnte ] = useState(null)
    const [ loading, setLoading ] = useState(false)


    if (!codiceEnte) {
        setRiconciliazioni(null)
        setCodiceEnte(datiEnte?.idDominio)
    } else if (codiceEnte !== datiEnte?.idDominio) {
        setCodiceEnte(null)
    }


    const getRiconciliazioni = async (filterData, page) => {
        setLoading(true)
        let response = await fetch(process.env.REACT_APP_SERVER_URL + 'ente/riconciliazioni/' + codiceEnte, {
            method: 'POST',
            body: JSON.stringify({token, filter: filterData, page, richiedente: cittadino.cf})
        })

        let res = await response.json()

        if (debug) console.log(res)
        if (res.valid) {
            if (riconciliazioni !== res.result) setRiconciliazioni(res.result)
            setLoading(false)
        } else {
            redirect("/")
        }
    }


    const getDettaglioRiconciliazioni = async () => {
        let response = await fetch(process.env.REACT_APP_SERVER_URL + 'ente/riconciliazioni/' + codiceEnte + '/' + dettaglio, {
            method: 'POST',
            body: JSON.stringify({token, richiedente: cittadino.cf})
        })

        let res = await response.json()

        if (debug) console.log(res)
        if (res.valid) {
            setRiconciliazione(res.result); 
        } else {
            redirect("/")
        }
    }


    useEffect(() => {
        if (token) {
            if (dettaglio && riconciliazione?.idIncasso !== dettaglio) {
                getDettaglioRiconciliazioni()
            } else if (!dettaglio && !riconciliazioni) {
                getRiconciliazioni(filter, 1)
            }
        } else {
            redirect("/")
        }
    }, [token, redirect, dettaglio, getRiconciliazioni, getDettaglioRiconciliazioni])




    const visualizzaDettaglio = (idIncasso) => {
        let url = '/gestione_ente/riconciliazioni/' + idIncasso
        redirect(url)
    }


    const filterSaved = (filterData) => {
        if (debug) console.log('filter saved', filterData)
        setFilter(filterData)
        getRiconciliazioni(filterData, 1)
    } 


    return (
        <>
            { loading ? <LoadingFull /> : null }
            {
                dettaglio ?
                <>                                
                    <Row>
                        <Col>
                            <Button onClick={() => redirect('/gestione_ente/riconciliazioni')}>indietro</Button>
                        </Col>
                        <Col>
                            <h4 style={{marginTop: 0}}>Dettaglio riconciliazione</h4>
                        </Col>
                    </Row>
                    {
                        riconciliazione ?
                        <>
                            <Card>
                                <Row style={{marginBottom: '5px'}}>
                                    <Col>
                                        <b>Causale:</b> {riconciliazione.causale}
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: '5px'}}>
                                    <Col>
                                        <b>Importo:</b> {riconciliazione.importo}
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: '5px'}}>
                                    <Col>
                                        <b>ID incasso:</b> {riconciliazione.idIncasso}
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: '5px'}}>
                                    <Col>
                                        <b>SCT:</b> {riconciliazione.sct}
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: '5px'}}>
                                    <Col>
                                        <b>Data valuta:</b> {riconciliazione.dataValuta}
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: '5px'}}>
                                    <Col>
                                        <b>Data contabile:</b> {riconciliazione.dataContabile}
                                    </Col>
                                </Row>
                            </Card>
                            <h4>Riscossioni</h4>
                            {
                                riconciliazione?.riscossioni?.map(
                                    (item) => (
                                        <Card key={item.iuv}>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Ente Creditore:</b> {item.vocePendenza.pendenza.dominio.ragioneSociale}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Soggetto Pagatore:</b> {item.vocePendenza.pendenza.soggettoPagatore.anagrafica}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Servizio:</b> {item.vocePendenza.pendenza.tipoPendenza.descrizione}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Importo:</b> € {item.vocePendenza.pendenza.importo}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>idVocePendenza:</b> {item.vocePendenza.idVocePendenza}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>IUR:</b> {item.iur}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>IUV:</b> {item.iuv}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Data:</b> {item.data}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Stato:</b> {item.stato}
                                                </Col>
                                            </Row>
                                        </Card>
                                    )
                                )
                            }
                        </> :
                        <LoadingSmall />
                    }
                </> :
                <>
                    <Row>
                        <Col s={12}>
                            <h4 style={{marginTop: 0}}>Riconciliazioni</h4>
                            <p>Elenco delle riconciliazioni effettuate</p>
                            <CardFiltriEnte tipo="riconciliazioni" onFilterSaved={filterSaved} />
                            <h5>Riconciliazioni trovate: { riconciliazioni ? riconciliazioni?.numRisultati : "0" }</h5>
                        </Col>
                    </Row>
                    { 
                        riconciliazioni ?
                        <>
                            <Pagination
                                activePage={riconciliazioni.pagina}
                                items={riconciliazioni.numPagine}
                                leftBtn={<Icon>chevron_left</Icon>}
                                maxButtons={10}
                                rightBtn={<Icon>chevron_right</Icon>}
                                onSelect={(page) => getRiconciliazioni(filter, page)}
                            />
                            {riconciliazioni?.risultati?.map(
                                (item) => (
                                    <Card key={item.idIncasso}>
                                        <Row style={{marginBottom: '5px'}}>
                                            <Col>
                                                <b>Causale:</b> {item.causale}
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: '5px'}}>
                                            <Col>
                                                <b>Importo:</b> {item.importo}
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: '5px'}}>
                                            <Col>
                                                <b>ID incasso:</b> {item.idIncasso}
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: '5px'}}>
                                            <Col>
                                                <b>SCT:</b> {item.sct}
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: '5px'}}>
                                            <Col>
                                                <b>Data valuta:</b> {item.dataValuta}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <b>Data contabile:</b> {item.dataContabile}
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: '5px'}}>
                                            <Col>
                                                <Button onClick={() => {visualizzaDettaglio(item.idIncasso)}}>DETTAGLI</Button>
                                            </Col>
                                        </Row>
                                    </Card>
                                )
                            )}
                        </> :
                        <LoadingSmall /> 
                    }
                </>
            }
        </>
    );
}