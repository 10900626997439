import { useContext } from 'react'
import { GlobalContext } from '../../context/GlobalState';

export const useGetNomeServizio = () => {

    const { 
        strutturaSelettori, 
        provincia, 
        ente, 
        servizio
    } = useContext(GlobalContext)

    const getNomeServizio = () => {
        console.log('SELETTORI', strutturaSelettori)
        if (ente !== null && servizio !== null) {
            let enteObj = strutturaSelettori[provincia].enti.filter(item => item.idDominio === ente)[0]
            return enteObj.listaEntrate.filter(entrata => entrata.idEntrata === servizio)[0].tipoEntrata.descrizione
        } else {
            return ""
        }
    }
    

    return getNomeServizio
}