import React, { useContext } from 'react'
import 'materialize-css'
import { Select } from 'react-materialize'

import { GlobalContext } from '../context/GlobalState'

export const SelezionaProvincia = ({onChange}) => {
    // const debug = process.env.REACT_APP_DEBUG === "true"
    const {strutturaSelettori, provincia, setProvincia } = useContext(GlobalContext);

    return (
        <Select
            s={12} 
            m={12}
            id="SelezionaProvincia"
            multiple={false}
            onChange={ e => { setProvincia(e.target.value); if(onChange) onChange(e.target.value) } }
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: false,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }}
            value={ provincia }
        >
            <option
                value=""
            >
                SELEZIONA UN TERRITORIO
            </option>
            {strutturaSelettori != null ? Object.keys(strutturaSelettori).map((key) => (<option key={key} value={key}>{strutturaSelettori[key].nome}</option>)) : []}
        </Select>
    )
}
