import React, { useContext, useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import 'materialize-css'
import { Container, Row, Col, Card, Select, Button, TextInput, Icon } from 'react-materialize'
import { HeaderCittadino } from '../components/HeaderCittadino'
import { PageFooter } from '../components/PageFooter'
import { GlobalContext } from '../context/GlobalState';
import { useTokenCittadino } from "../components/hooks/useTokenCittadino"
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const ContactPage = () => {
    // const debug = process.env.REACT_APP_DEBUG === "true"
    const history = useHistory()

    const { token } = useTokenCittadino()
    const { datiEnte, cittadino } = useContext(GlobalContext);
    const [ editorState, setEditorState ] = useState(EditorState.createEmpty())
    const [ sending, setSending ] = useState(false)
    const [ tipologiaProblema, setTipologiaProblema ] = useState("")
    const [ pendingFile, setPendingFile ] = useState(null)
    const [ uploads, setUploads ] = useState([])
    const [ contenutoTicket, setContenutoTicket ] = useState("")
    const [ dbRecord, setDbRecord ] = useState({})

    const uploaderRef = useRef(null)

    useEffect(() => {
        uploaderRef.current = document.getElementById("uploader");
        console.log({uploaderRef});
    }, []);


    const tipologieProblemi = {
        DISSERVIZIO: "Segnala un disservizio o un malfunzionamento del portale PagoRA",
        PAGAMENTI: "Segnala un problema relativo all'esecuzione di un pagamento",
        RICEVUTE: "Segnala un problema relativo alle ricevute di pagamento"
    }


    const saveTicketBody = () => {
        const richiestaTicket = {
            tag: "Software",
            idTag: "600",
            tagLayer2: "PAGORA",
            idTagLayer2: 617,
            indirizzoSede: "Esterno",
            telefono: cittadino?.cellulare,
            indirizzoIp: process.env.REACT_APP_IP,
            message: getTicketMessage(),
            dataTicket: getTicketDate(),
            arrayAllegati: uploads
        }

        const dbRecord = {
            codiceFiscale: cittadino.cf,
            idDominio: 'CITTADINO',
            dataRichiesta: getTicketDateTime(),
            tipologiaProblema: tipologiaProblema,
            contenutoTicket: contenutoTicket,
            richiestaTicket: richiestaTicket,
            archiviato: false,
            corpoTicket: null,
            ticketCode: null,
        }

        setDbRecord(dbRecord)

        console.log({dbRecord})

        return dbRecord
    }


    const getTicketDate = () => {
        let now = new Date()
        let anno = now.getFullYear()
        let mese = (now.getMonth() +1).toString().padStart(2, '0')
        let giorno = now.getDate().toString().padStart(2, '0')

        return `${anno}-${mese}-${giorno}`
    }


    const getTicketDateTime = () => {
        let now = new Date()
        let anno = now.getFullYear()
        let mese = (now.getMonth() +1).toString().padStart(2, '0')
        let giorno = now.getDate().toString().padStart(2, '0')
        let ora = now.getHours().toString().padStart(2, '0')
        let minuti = now.getMinutes().toString().padStart(2, '0')
        let secondi = now.getSeconds().toString().padStart(2, '0')

        return `${giorno}/${mese}/${anno} - ${ora}:${minuti}:${secondi}`
    }


    const getTicketMessage = () => {
        return `${cittadino.cf}#${tipologiaProblema}#[CITTADINO][${cittadino.email}][${cittadino.cellulare}] <br /><br /><div>${contenutoTicket}</div>`
    }


    const handleTipologiaChange = (e) => {
        setTipologiaProblema(e.target.value)
    }


    const handleUpload = (e) => {
        const file = e.target.files[0]
        console.log({file})
        setPendingFile(file)
    }


    const onEditorStateChange = (state) =>{
        console.log('editorState', state)
        setEditorState(state)
        setContenutoTicket(draftToHtml(convertToRaw(state.getCurrentContent())))
    } 


    const aggiungiAllegato = async () => {
        const allegato = {
            isImage: pendingFile.type.includes('image'),
            fileName: pendingFile.name,
            fileBase64: await covertFileToBase64(pendingFile),
            fileType: pendingFile.name.split('.').slice(-1)[0]
        }

        setPendingFile(null)
        uploaderRef.current.reset()
        setUploads(uploads.concat(allegato))

        console.log('ALLEGATO AGGIUNTO')
        console.log({allegato})
    }


    const rimuoviAllegato = (index) => {
        setUploads(uploads.filter((item,i) => i !== index))
    }


    const covertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)

            fileReader.onload = () => {
                resolve(fileReader.result.split(',')[1])
            }

            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }


    const checkTicket = () => {
        if (sending) return true

        let valid = true

        if (!tipologiaProblema.length) valid = false
        if (!contenutoTicket.length) valid = false

        return valid
    }


    const sendTicket = async () => {
        setSending(true)

        let dbRecord = saveTicketBody()

        let response = await fetch(process.env.REACT_APP_SERVER_URL + 'ticket/inviaNuovo', {
            method: 'POST',
            body: JSON.stringify({token, dbRecord})
        })

        let res = await response.json()

        console.log({res});

        if (res.valid) {
            setEditorState(EditorState.createEmpty())
            setTipologiaProblema("")
            setUploads([])
        } else {
            history.push('/')
        }
        
        setSending(false)
    }


    return (
        <Container>
            <HeaderCittadino history={history} />
            <Row>
                <Col s={12}>
                    <Card title="Contatti">
                        <h4>Regione Abruzzo</h4>
                        <p>Per trovare le Strutture e contattare il personale regionale puoi utilizzare uno dei seguenti modi:</p>
                        <h5>Indirizzo sedi</h5>
                        <p><strong>L'Aquila: </strong>Via Leonardo da Vinci, 6 - 67100 L'Aquila</p>
                        <p><strong>Pescara: </strong>Viale Bovio, 425 - Pescara</p>
                    </Card>
                </Col>
            </Row>
            {
                cittadino ?
                <Row s={12}>
                    <Col s={12}>
                        <Card title="Richieste e segnalazioni">
                            <Row>
                                <Col s={12}>
                                    <Select
                                        s={12}
                                        label="Tipologia Problema (*)"
                                        id="tipologiaProblema"
                                        multiple={false}
                                        onChange={ handleTipologiaChange }
                                        options={{
                                            classes: '',
                                            dropdownOptions: {
                                                alignment: 'left',
                                                autoTrigger: true,
                                                closeOnClick: true,
                                                constrainWidth: false,
                                                coverTrigger: true,
                                                hover: false,
                                                inDuration: 150,
                                                onCloseEnd: null,
                                                onCloseStart: null,
                                                onOpenEnd: null,
                                                onOpenStart: null,
                                                outDuration: 250
                                            }
                                        }}
                                        value={ tipologiaProblema }
                                        validate
                                        required
                                        disabled={sending}
                                    >
                                        <option value="" key="">SELEZIONARE UNA TIPOLOGIA DI RICHIESTA</option>
                                        { Object.entries(tipologieProblemi).map((entry) => (<option value={entry[0]} key={entry[0]}>{entry[1]}</option>)) }
                                    </Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col s={12}>
                                    <Editor
                                        doExpand
                                        editorState={editorState}
                                        wrapperStyle={{height: '400px', border: 'solid 1px #ccc'}}
                                        editorStyle={{height: '300px'}}
                                        onEditorStateChange={onEditorStateChange}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col s={9}>
                                    <form id="uploader">
                                        <TextInput
                                            s={12}
                                            label="Seleziona un allegato"
                                            type='file' 
                                            onChange={handleUpload}                                
                                        />
                                    </form>
                                </Col>
                                <Col s={3}>
                                    <br />
                                    <Button
                                        style={{width: '100%'}} 
                                        onClick={aggiungiAllegato}
                                        disabled={pendingFile === null}
                                    >
                                        AGGIUNGI
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col s={12}>
                                    <p>ALLEGATI:</p>
                                    <br />
                                </Col>
                                {
                                    uploads.map((upload, i) => (
                                        <Col key={i} s={12}>
                                            <Row>
                                                <Col s={9}>
                                                    <b>{upload.fileName}</b>
                                                </Col>
                                                <Col s={3}>
                                                    <Button style={{width: '100%'}} small onClick={() => rimuoviAllegato(i)}>RIMUOVI</Button> 
                                                </Col>
                                            </Row>    
                                            <hr />                            
                                        </Col>
                                    ))
                                }
                            </Row>
                            <Row>
                                <Col s={12}>
                                    <Button type="submit" node="button" waves="light" style={{width:'100%'}} large onClick={sendTicket} disabled={!checkTicket()}>
                                        { sending ? 'RICHIESTA INVIATA, ELABORAZIONE IN CORSO...' : 'INVIA RICHIESTA'}
                                        <Icon right>send</Icon>
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row> :
                <Row>
                    <Col s={12}>
                        <Card title="Autenticati per inviare richieste o segnalazioni">
                            <p>Per inviare direttamente una richiesta o una segnalazione puoi autenticarti con SPID/CIE e utilizzare la form che troverai in questa pagina.</p>
                        </Card>
                    </Col>
                </Row>
            }
            <PageFooter />
        </Container>
    )
}
