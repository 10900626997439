import React, { useContext } from 'react'
import 'materialize-css'
import { Select } from 'react-materialize'

import { useGetServiziNascosti } from './hooks/useGetServiziNascosti'

import { GlobalContext } from '../context/GlobalState'

export const SelezionaServizio = () => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const { strutturaSelettori, provincia, ente, servizio, setServizio } = useContext(GlobalContext);

    if (debug) console.log(strutturaSelettori, provincia, ente)

    const servizi = provincia !== "" && ente !== "" ? strutturaSelettori[provincia].enti.filter(item => item.idDominio === ente)[0].listaEntrate : []

    const getServiziNascosti = useGetServiziNascosti()

    return (
        <Select
            s={12} 
            m={12}
            id="SelezionaServizio"
            multiple={false}
            onChange={ e => setServizio(e.target.value) }
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: false,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }}
            value={ servizio }
        >
            <option
                value=""
            >
                SELEZIONA UN SERVIZIO
            </option>
            {servizi.filter((servizio) => !getServiziNascosti(ente, servizio.idEntrata)).map((servizio) => (<option key={servizio.idEntrata} value={servizio.idEntrata}>{servizio.tipoEntrata.descrizione}</option>))}
        </Select>
    )
}
