import React, { useContext, useState, useEffect } from 'react'
import 'materialize-css'
import { Row, Col, Card, Button, Pagination, Icon } from 'react-materialize'

import { LoadingSmall } from '../loadingSmall';
import { LoadingFull } from '../loadingFull';

import { ModalConfermaIncasso } from '../ModalConfermaIncasso';

import { GlobalContext } from '../../context/GlobalState';

import { useTokenCittadino } from "../hooks/useTokenCittadino"

import { CardFiltriEnte } from '../CardFiltriEnte'


export const GestioneEnteRendicontazioni = ({dettaglio, redirect, match}) => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const { token } = useTokenCittadino()
    const { datiEnte, cittadino } = useContext(GlobalContext);
    const [rendicontazioni, setRendicontazioni] = useState(null);
    const [rendicontazione, setRendicontazione] = useState(null);
    const [ filter, setFilter ] = useState(null);
    const [ codiceEnte, setCodiceEnte ] = useState(null)
    const [ loading, setLoading ] = useState(false)


    if (!codiceEnte) {
        setRendicontazioni(null)
        setCodiceEnte(datiEnte?.idDominio)
    } else if (codiceEnte !== datiEnte?.idDominio) {
        setCodiceEnte(null)
    }


    const getRendicontazioni = async (filterData, page) => {
        setLoading(true)
        let response = await fetch(process.env.REACT_APP_SERVER_URL + 'ente/rendicontazioni/' + codiceEnte, {
            method: 'POST',
            body: JSON.stringify({token, filter: filterData, page, richiedente: cittadino.cf})            
        })

        let res = await response.json()

        if (debug) console.log(res)
        if (res.valid) {
            if (rendicontazioni !== res.result) setRendicontazioni(res.result)
            setLoading(false)
        } else {
            redirect("/")
        }
    }


    const getDettaglioRendicontazioni = async () => {
        let response = await fetch(process.env.REACT_APP_SERVER_URL + 'ente/rendicontazioni/' + codiceEnte + '/' + dettaglio, {
            method: 'POST',
            body: JSON.stringify({token, richiedente: cittadino.cf})
        })

        let res = await response.json()

        if (debug) console.log(res)
        if (res.valid) {
            setRendicontazione(res.result)
        } else {
            redirect("/") 
        }
    }


    useEffect(() => {
        if (token) {
            if (dettaglio && rendicontazione?.idFlusso !== dettaglio) {
                getDettaglioRendicontazioni()
            } else if (!dettaglio && !rendicontazioni) {
                getRendicontazioni(filter, 1)
            }
        } else {
            redirect("/")
        }
    }, [token, redirect, dettaglio, getRendicontazioni, getDettaglioRendicontazioni])




    const visualizzaDettaglio = (dettaglio) => {
        let url = '/gestione_ente/rendicontazioni/' + dettaglio
        redirect(url)
    }


    const filterSaved = (filterData) => {
        if (debug) console.log('filter saved', filterData)
        setFilter(filterData)
        getRendicontazioni(filterData, 1)
    } 


    const checkIncassato = () => {
        if (rendicontazione) {
            if (debug) console.log('STATI', rendicontazione)
            let stati = rendicontazione.rendicontazioni.map(item => {
                if (!item.riscossione) {
                    return false
                } else {
                    return item.riscossione.stato === 'RISCOSSA'
                }
            });
            if (debug) console.log('STATI', stati, stati.includes(true))
            return stati.includes(true);
        } else {
            return false
        }
    }


    return (
        <>
            {   loading ? <LoadingFull /> : null }
            {
                dettaglio ?
                <>
                    <Row>
                        <Col>
                            <Button onClick={() => redirect('/gestione_ente/rendicontazioni')}>indietro</Button>
                        </Col>
                        <Col>
                            <h4 style={{marginTop: 0}}>Dettaglio rendicontazioni</h4>
                        </Col>
                    </Row>
                    {
                        rendicontazione ?
                        <>
                            <Card>
                                <Row style={{marginBottom: '5px'}}>
                                    <Col>
                                        <b>idFlusso:</b> {rendicontazione.idFlusso}
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: '5px'}}>
                                    <Col>
                                        <b>TRN:</b> {rendicontazione.trn}
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: '5px'}}>
                                    <Col>
                                        <b>importo totale:</b> {rendicontazione.importoTotale} €
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: '5px'}}>
                                    <Col>
                                        <b>ID PSP:</b> {rendicontazione.idPsp}
                                    </Col>
                                    <Col>
                                        <b>Ragione sociale PSP:</b> {rendicontazione.ragioneSocialePsp}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <b>data flusso:</b> {rendicontazione.dataFlusso}
                                    </Col>
                                    <Col>
                                        <b>data regolamento:</b> {rendicontazione.dataRegolamento}
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: '5px'}}>
                                    <Col>
                                        <ModalConfermaIncasso redirect={redirect} rendicontazione={rendicontazione} incassato={checkIncassato()} />
                                    </Col>
                                </Row>
                            </Card> 
                            <h5>Numero pagamenti: {rendicontazione?.numeroPagamenti}</h5>
                            {
                                rendicontazione?.rendicontazioni?.map(
                                    (item) => (
                                        <Card key={item.iuv}>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>IUV:</b> {item.iuv}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>IUR:</b> {item.iur}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Importo:</b> {item.importo} €
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Data:</b> {item.data}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Tipo:</b> {item.riscossione?.tipo}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Stato:</b> {item.riscossione?.stato}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Descrizione:</b> {item.riscossione?.vocePendenza.descrizione}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Servizio:</b> {item.riscossione?.vocePendenza.pendenza.tipoPendenza.descrizione}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Identificativo debitore:</b> {item.riscossione?.vocePendenza.pendenza.soggettoPagatore.identificativo}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Anagrafica debitore:</b> {item.riscossione?.vocePendenza.pendenza.soggettoPagatore.anagrafica}
                                                </Col>
                                            </Row>
                                        </Card>
                                    )
                                )
                            }
                        </> :
                        <LoadingSmall />
                    }
                </> :
                <>
                    <Row>
                        <Col s={12}>
                            <h4 style={{marginTop: 0}}>Rendicontazioni</h4>
                            <p>Elenco dei flussi di rendicontazione ricevuti</p>
                            <CardFiltriEnte tipo="rendicontazioni" onFilterSaved={filterSaved} />
                            <h5>Rendicontazioni trovate: { rendicontazioni ? rendicontazioni?.numRisultati : "0" }</h5>
                        </Col>
                    </Row>
                    {
                        rendicontazioni ? 
                        <>
                            <Pagination
                                activePage={rendicontazioni.pagina}
                                items={rendicontazioni.numPagine}
                                leftBtn={<Icon>chevron_left</Icon>}
                                maxButtons={10}
                                rightBtn={<Icon>chevron_right</Icon>}
                                onSelect={(page) => getRendicontazioni(filter, page)}
                            />
                            {rendicontazioni?.risultati?.map(
                                (item, i) => (<Card key={i}>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <b>idFlusso:</b> {item.idFlusso}
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <b>TRN:</b> {item.trn}
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <b>importo totale:</b> {item.importoTotale} €
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <b>ID PSP:</b> {item.idPsp}
                                        </Col>
                                        <Col>
                                            <b>Ragione sociale PSP:</b> {item.ragioneSocialePsp}
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <b>data flusso:</b> {item.dataFlusso}
                                        </Col>
                                        <Col>
                                            <b>data regolamento:</b> {item.dataRegolamento}
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <Button onClick={() => visualizzaDettaglio(item.idFlusso)}>DETTAGLI</Button>
                                        </Col>
                                    </Row>                        
                                </Card>) 
                            )}
                        </> :
                        <LoadingSmall />
                    }
                </>
            }
        </>
        
    );
}