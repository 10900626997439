import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import 'materialize-css'
import { Container, Row, Col, Card, Button } from 'react-materialize'
import { HeaderCittadino } from '../components/HeaderCittadino'
import { PageFooter } from "../components/PageFooter"
import { GlobalContext } from '../context/GlobalState'
import { LoadingSmall } from '../components/loadingSmall'

export const VerificaAvvisoPage = () => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const history = useHistory()

    const [pendenza, setPendenza] = useState(null)
    const [errore, setErrore] = useState(null)

    const {
        nuovoAvviso, 
        carrello,
        addPendenzaCarrello,
        cittadino
    } = useContext(GlobalContext)

    if (nuovoAvviso) {
        if (debug) console.log(nuovoAvviso)

        let richiedente = cittadino ? cittadino.cf : 'anonimo'

        fetch(process.env.REACT_APP_SERVER_URL + 'verifica_avviso?richiedente=' + richiedente, {
            method: 'POST',
            body: JSON.stringify(nuovoAvviso)
        })
            .then(res => res.json())
            .then(res => {
                if (debug) console.log(res)
                if (res.statusCode === 500 || res.descrizione) {
                    if (!errore) setErrore(res)
                } else if (!pendenza || pendenza?.numeroAvviso !== nuovoAvviso.numeroAvviso) {
                    setPendenza(res)
                }
            })
    } else {
        history.push('/')
    }


    const goForward = () => {
        addPendenzaCarrello(nuovoAvviso)
        history.push('/carrello')
    }

    const goBack = () => {
        history.push('/')
    }


    return (
        <Container>
            <HeaderCittadino history={history} />
            {
                errore ?
                <>
                    <Row>
                        <Col s={12}>
                            <Card title="ERRORE ELABORAZIONE AVVISO">
                                <h5>Si è verificato un errore nell'elaborazione dell'avviso, si prega di verificare i dati e riprovare</h5>
                                <b>{errore.descrizione}</b>
                                <p>{errore.dettaglio}</p>
                                <br />
                                <Button onClick={goBack}>TORNA ALLA HOMEPAGE</Button>
                            </Card>
                        </Col>
                    </Row>      
                </> :
                <>
                    <Row>
                        <Col s={12}>
                            <Card title="Dati della pendenza corrispondente all'avviso">
                                {
                                    pendenza ?
                                    <>
                                        {
                                            pendenza.soggettoPagatore.identificativo === nuovoAvviso.idDebitore ?
                                            <>
                                                <Row>
                                                    <Col s={12}>
                                                        <h5>Numero: {pendenza.numeroAvviso} - Dominio: {pendenza.dominio.idDominio}</h5>
                                                        <p style={{marginTop: 0}}>{pendenza.causale} - €{pendenza.importo}</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <b>Stato:</b> {pendenza.stato}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <b>Beneficiario:</b> {pendenza.dominio.ragioneSociale}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <b>Data di caricamento:</b> {pendenza.dataCaricamento}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <b>Data di scadenza:</b> {pendenza.dataScadenza}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <b>Data di pagamento:</b> {pendenza.dataPagamento}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <b>Nome instestatario:</b> {pendenza.soggettoPagatore.anagrafica}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <b>CF instestatario:</b> {pendenza.soggettoPagatore.identificativo}
                                                    </Col>
                                                </Row>
                                            </> :
                                            <>
                                                <Row>
                                                    <Col>
                                                        <p>Il codice fiscale inserito non corrisponde</p>
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        <Row>
                                            {
                                                pendenza.stato === "NON_ESEGUITA" || pendenza.soggettoPagatore.identificativo === nuovoAvviso.idDebitore ?
                                                <Col>
                                                    {
                                                        carrello.length === 5 ?
                                                        <Button disabled>CARRELLO PIENO</Button> :
                                                        <Button onClick={goForward}>AGGIUNGI AL CARRELLO</Button>
                                                    }
                                                </Col> :
                                                <Col>
                                                    <p>L'avviso non è pagabile</p>
                                                    <br />
                                                    <Button onClick={goBack}>TORNA ALLA HOME</Button>
                                                </Col>    
                                            }
                                        </Row>
                                    </> :
                                    <>
                                        <LoadingSmall />
                                    </>
                                }
                            </Card>
                        </Col>
                    </Row>
                </>
            }
            <PageFooter />
        </Container>
    );
}