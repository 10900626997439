import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';
import abruzzo from '../json/abruzzo.json'


const debug = process.env.REACT_APP_DEBUG === "true"


const statiPagamento = {
    IN_CORSO: "Pagamento in corso",
    FALLITO: "Pagamento rifiutato",
    ESEGUITO: "Pagamento eseguito",
    ANNULLATO: "Pagamento annullato"
}

const statiVerifica = {
    true: "Verificato",
    false: "Non verificato"
}

const statiPendenza = [
    "Tutti",
    "Pagata",
    "Da pagare",
    "Pagata parzialmente",
    "Annullata",
    "Scaduta",
    "Riconciliata"
]

// Initial state
const initialState = {
    ultimoAggiornamento: 0,
    carrello: [],
    strutturaSelettori: null,
    abruzzo,
    provincia: "",
    ente: "",
    datiEnte: "",
    servizio: "",
    statiPagamento,
    statiVerifica,
    statiPendenza,
    nuovaPendenza: null,
    nuovoAvviso: null,
    cittadino: null
}


// Create context
export const GlobalContext = createContext(initialState);

// Provider component
export const GlobalProvider = ({ children }) => {
    let localStateItem = localStorage.getItem("CONTEXT");
    let localStateObj = JSON.parse(localStateItem)
    let now = Date.now()
    let age = now - (localStateObj?.ultimoAggiornamento ? localStateObj?.ultimoAggiornamento : 0)
    if (debug) console.log("AGE", age)

    let localState

    if (age > 3600000) { // 1 ora
        initialState.ultimoAggiornamento = now
        localState = initialState
    } else {
        localState = localStateObj
    }

    const [state, dispatch] = useReducer(AppReducer, localState);

    function saveContext() {
        localStorage.setItem("CONTEXT", JSON.stringify(state));
    }

    function setStrutturaSelettori(data) {
        dispatch({
            type: 'SET_STRUTTURA_SELETTORI',
            payload: data
        });

        saveContext()
    }

    // Actions
    function deleteTransaction(id) {
        dispatch({
            type: 'DELETE_TRANSACTION',
            payload: id
        });

        saveContext()
    }

    function addTransaction(transaction) {
        dispatch({
            type: 'ADD_TRANSACTION',
            payload: transaction
        });

        saveContext()
    }

    function setProvincia(provincia) {
        dispatch({
            type: 'SET_PROVINCIA',
            payload: provincia
        });

        saveContext()
    }

    function setEnte(ente) {
        dispatch({
            type: 'SET_ENTE',
            payload: ente
        });

        saveContext()
    }

    function setDatiEnte(datiEnte) {
        dispatch({
            type: 'SET_DATI_ENTE',
            payload: datiEnte
        });

        saveContext()
    }

    function setServizio(servizio) {
        dispatch({
            type: 'SET_SERVIZIO',
            payload: servizio
        });

        saveContext()
    }

    function setProvinciaEnte({provincia, ente}) {
        dispatch({
            type: 'SET_PROVINCIA_ENTE',
            payload: {provincia, ente}
        });

        saveContext()
    }

    function setProvinciaEnteServizio({provincia, ente, servizio}) {
        dispatch({
            type: 'SET_PROVINCIA_ENTE_SERVIZIO',
            payload: {provincia, ente, servizio}
        });

        saveContext()
    }

    function addPendenzaCarrello(payload) {
        dispatch({
            type: 'ADD_PENDENZA_CARRELLO',
            payload
        });

        saveContext()
    }

    function removePendenzaCarrello(payload) {
        dispatch({
            type: 'REMOVE_PENDENZA_CARRELLO',
            payload
        });

        saveContext()
    }

    function aggiornaPendenzaCarrello(payload) {
        dispatch({
            type: 'AGGIORNA_PENDENZA_CARRELLO',
            payload
        });

        saveContext()
    }

    function svuotaCarrello() {
        dispatch({
            type: 'SVUOTA_CARRELLO',
            payload: []
        });

        saveContext()
    }

    function setNuovaPendenza(payload) {
        dispatch({
            type: 'SET_NUOVA_PENDENZA',
            payload
        });

        saveContext()
    }

    function setNuovoAvviso(payload) {
        dispatch({
            type: 'SET_NUOVO_AVVISO',
            payload
        });

        saveContext()
    }

    function setCittadino(payload) {
        dispatch({
            type: 'SET_CITTADINO',
            payload
        });

        saveContext()
    }



    return (<GlobalContext.Provider value={{
        strutturaSelettori: state.strutturaSelettori,
        transactions: state.transactions,
        carrello: state.carrello,
        abruzzo: state.abruzzo,
        provincia: state.provincia,
        ente: state.ente,
        datiEnte: state.datiEnte,
        servizio: state.servizio,
        statiPagamento: state.statiPagamento,
        statiVerifica: state.statiVerifica,
        statiPendenza: state.statiPendenza,
        nuovaPendenza: state.nuovaPendenza,
        nuovoAvviso: state.nuovoAvviso,
        cittadino: state.cittadino,
        saveContext,
        setStrutturaSelettori,
        deleteTransaction,
        addTransaction,
        setProvincia,
        setEnte,
        setDatiEnte,
        setServizio,
        setProvinciaEnte,
        setProvinciaEnteServizio,
        addPendenzaCarrello,
        removePendenzaCarrello,
        aggiornaPendenzaCarrello,
        svuotaCarrello,
        setNuovaPendenza,
        setNuovoAvviso,
        setCittadino
    }}>
        { children }
    </GlobalContext.Provider>)
}