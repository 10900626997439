import React, { useContext, useState, useEffect, useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import 'materialize-css'
import { Container, Row, Col, Card, Button } from 'react-materialize'
import { HeaderEnte } from '../components/HeaderEnte'
import { GestioneEnteAnagrafica } from '../components/ente/GestioneEnteAnagrafica'
import { GestioneEnteEntrate } from '../components/ente/GestioneEnteEntrate'
import { GestioneEnteTipiPendenza } from '../components/ente/GestioneEnteTipiPendenza'
import { GestioneEnteContiAccredito } from '../components/ente/GestioneEnteContiAccredito'
import { GestioneEntePendenze } from '../components/ente/GestioneEntePendenze'
import { GestioneEnteInserisciPendenza } from '../components/ente/GestioneEnteInserisciPendenza'
import { GestioneEntePagamenti } from '../components/ente/GestioneEntePagamenti'
import { GestioneEnteRiconciliazioni } from '../components/ente/GestioneEnteRiconciliazioni'
import { GestioneEnteRendicontazioni } from '../components/ente/GestioneEnteRendicontazioni'
import { GestioneEnteScaricaRendicontazioni } from '../components/ente/GestioneEnteScaricaRendicontazioni'
import { GestioneEnteRiscossioni } from '../components/ente/GestioneEnteRiscossioni'
import { GestioneEnteFlussiMassiviPendenze } from '../components/ente/GestioneEnteFlussiMassiviPendenze'
import { GestioneEnteConvertiFlusso } from '../components/ente/GestioneEnteConvertiFlusso'
import { GestioneEnteNuovoTicket } from '../components/ente/GestioneEnteNuovoTicket'
import { GestioneEnteStoricoTicket } from '../components/ente/GestioneEnteStoricoTicket'
import { SelezionaPaginaEnte } from "../components/SelezionaPaginaEnte"
import { SelezionaGestioneEnte } from "../components/SelezionaGestioneEnte"

import { GlobalContext } from '../context/GlobalState';

import { useTokenCittadino } from "../components/hooks/useTokenCittadino"
import { useCheckToken } from "../components/hooks/useCheckToken"
import { useGetAllowedSections } from "../components/hooks/useGetAllowedSections"
import { PageFooter } from '../components/PageFooter'


export const GestioneEntePage = () => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const checkToken = useCheckToken()
    const { token } = useTokenCittadino()
    const [ elencoEnti, setElencoEnti ] = useState([])
    const [ enteSelezionato, setEnteSelezionato ] = useState("")
    const [ allowedSections, setAllowedSections ] = useState([])
    const [ checkComplete, setCheckComplete ] = useState(false)
    const [ loading, setLoading ] = useState(false)
 
    const history = useHistory()
    const match = useRouteMatch()
    const getAllowedSections = useGetAllowedSections()

    const { 
        cittadino,
        datiEnte, 
        setDatiEnte, 
    } = useContext(GlobalContext);

    console.log({cittadino, datiEnte})

    const { 
        sezione, 
        dettaglio, 
        operazioni 
    } = match.params


    const caricaTuttiEnti = useCallback(
        async () => { 
            let response = await fetch(process.env.REACT_APP_SERVER_URL + 'domini?richiedente=' + cittadino.cf)
            let res = await response.json()
            if (debug) console.log('DOMINI', res)
            if (res.risultati) {
                setElencoEnti(res.risultati.filter(item => item.abilitato))
                // setEnteSelezionato(res.risultati[0].idDominio)      
                // setCheckComplete(true)
            }
        },
        []
    ) 


    const caricaEnteSelezionato = useCallback(
        async () => {
            setLoading(true)
            let response = await fetch(process.env.REACT_APP_SERVER_URL + 'domini/' + enteSelezionato + '?richiedente=' + cittadino.cf)
            let res = await response.json()
            if (debug) console.log('dati ente', res)

            if (!res.abilitato) {
                history.push('/')
            } else {
                if (!datiEnte || datiEnte.idDominio !== enteSelezionato) {
                    setDatiEnte(res)
                }
            }

            setLoading(false)
        },
        [enteSelezionato, datiEnte, setDatiEnte]
    ) 


    useEffect(
        () => {   
            if (token) {
                checkToken()
            } else {
                localStorage.clear()
                history.push('/')
            }
        }, 
        [token]
    )


    const caricaEntiAmministrati = async (elenco) => {
        let filtrati = []

        for(let i = 0; i < elenco.length; i++) {
            let idDominio = elenco[i].idDominio
            let response = await fetch(`${process.env.REACT_APP_SERVER_URL}domini/${idDominio}?richiedente=${cittadino.cf}`)
            let res = await response.json()

            if (res.abilitato) filtrati.push(res)
        }

        console.log({filtrati})

        if (filtrati.length) {
            setElencoEnti(filtrati)
        } else {
            history.push('/')
        }

    }


    useEffect(
        () => {
            if (cittadino) {
                if (!elencoEnti.length) {
                    if (cittadino.permessi[0].ENTE === "TUTTI") {
                        caricaTuttiEnti()
                    } else {
                        let elenco = cittadino.permessi.filter(item => item.RUOLI[0].tipo !== 'DelegatoStampa').map((item) => { return { idDominio: item.CF_PIVA_ENTE, ragioneSociale: item.ENTE}})
                        caricaEntiAmministrati(elenco)
                        // setEnteSelezionato(elenco[0].idDominio)   
                        // setCheckComplete(true)
                    }

                }
            }
        }, 
        [cittadino, elencoEnti, caricaEntiAmministrati, caricaTuttiEnti]
    )


    useEffect(
        () => {
            if (elencoEnti.length) {
                if (enteSelezionato.length) {
                    caricaEnteSelezionato()
                } else {
                    setEnteSelezionato(elencoEnti[0].idDominio)
                }
            }
        }, 
        [elencoEnti, enteSelezionato, setEnteSelezionato, caricaEnteSelezionato]
    )
    
    
    useEffect(
        () => {
            if (enteSelezionato.length) {
                console.log('call setAllowedSections')
                let allowed = getAllowedSections(cittadino, enteSelezionato)
                console.log({allowed})
                setAllowedSections(allowed)
                setCheckComplete(true)
            }
        }, 
        [enteSelezionato, cittadino]
    )
        

    const checkAllowed = useCallback(
        (sezione) => {
            if (!allowedSections.length) {
                return ''
            }
    
            if (allowedSections.includes(sezione)) {
                return sezione
            }
            
            return 'not_allowed'
        }
    ) 


    const checkContent = () => {
        if (sezione) {
            let allowed = checkAllowed(sezione)
            
            console.log({allowedSections, allowed})

            switch (allowed) {
                case 'anagrafica':
                    return (<GestioneEnteAnagrafica dettaglio={dettaglio} redirect={vaiAllaSezione} />)
                case 'contiaccredito':
                    return (<GestioneEnteContiAccredito dettaglio={dettaglio} redirect={vaiAllaSezione} />)
                case 'entrate':
                    return (<GestioneEnteEntrate dettaglio={dettaglio} redirect={vaiAllaSezione} />)               
                case 'tipipendenza':
                    return (<GestioneEnteTipiPendenza dettaglio={dettaglio} redirect={vaiAllaSezione} />)
                case 'pendenze':
                    return (<GestioneEntePendenze dettaglio={dettaglio} redirect={vaiAllaSezione} />)
                case 'inserisci_pendenza':
                    return (<GestioneEnteInserisciPendenza redirect={vaiAllaSezione} />)
                case 'pagamenti':
                    return (<GestioneEntePagamenti dettaglio={dettaglio} redirect={vaiAllaSezione} />)
                case 'flussi_pendenze':
                    return (<GestioneEnteFlussiMassiviPendenze dettaglio={dettaglio} operazioni={operazioni} redirect={vaiAllaSezione} />)
                case 'riconciliazioni':
                    return (<GestioneEnteRiconciliazioni dettaglio={dettaglio} redirect={vaiAllaSezione} />)
                case 'rendicontazioni':
                    return (<GestioneEnteRendicontazioni dettaglio={dettaglio} redirect={vaiAllaSezione} />)
                case 'scarica_flusso_rendicontazioni':
                    return (<GestioneEnteScaricaRendicontazioni dettaglio={dettaglio} redirect={vaiAllaSezione} />)
                case 'riscossioni':
                    return (<GestioneEnteRiscossioni dettaglio={dettaglio} redirect={vaiAllaSezione} />)
                case 'converti_flusso':
                    return (<GestioneEnteConvertiFlusso dettaglio={dettaglio} redirect={vaiAllaSezione} />)
                case 'nuovo_ticket':
                    return (<GestioneEnteNuovoTicket dettaglio={dettaglio} redirect={vaiAllaSezione} />)
                case 'storico_ticket':
                    return (<GestioneEnteStoricoTicket dettaglio={dettaglio} redirect={vaiAllaSezione} />)
                case 'not_allowed':
                    history.push('/gestione_ente/anagrafica')
                    break
                default:
                    history.push('/')
            }               
            
        } else {
            history.push('/gestione_ente/anagrafica')
        }
    }


    const vaiAllaSezione = (url) => {
        history.push(url);
    }


    const onChangeEnte = (ente) => {
        if (debug) console.log('CHANGE ENTE', ente)
        setEnteSelezionato(ente)
    }


    return (
        <Container>
            <HeaderEnte history={history} />
            {
                elencoEnti.length > 1 || cittadino?.permessi?.[0].ENTE === "TUTTI" ? 
                <Row>
                    <Col s={12} l={9}>
                        <SelezionaGestioneEnte elencoEnti={elencoEnti ? elencoEnti : []} onChange={onChangeEnte} enteSelezionato={enteSelezionato} />
                    </Col>
                    <Col s={12} l={3}>
                        <br />
                        <Button style={{width: '100%'}} onClick={caricaEnteSelezionato} disabled={!enteSelezionato || enteSelezionato === datiEnte.idDominio}>CONFERMA</Button>
                    </Col>
                </Row> :
                <></>
            }
            <Row>
                <Col s={2}>
                    <br />
                    {datiEnte?.logo ? <img src={datiEnte?.logo} alt="logo ente" style={{width: '100%'}} /> : ""}
                </Col>
                <Col s={10}>
                    <h3>{datiEnte?.ragioneSociale}</h3>
                </Col>
            </Row>
            <Row>
                <Col s={12}>
                    <Card className="teal lighten-2" title="SELEZIONA UNA SEZIONE">
                        <Row style={{margin: 0}}>
                            <Col s={12}>
                                <SelezionaPaginaEnte sezione={sezione} navigaVerso={vaiAllaSezione} allowedSections={allowedSections} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col s={12}>
                    { checkComplete ? checkContent() : null }
                </Col>
            </Row>
            <PageFooter />
        </Container>
    );
}