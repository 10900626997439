import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import 'materialize-css'
import { Container, Card, Row, Col, Button, TextInput } from 'react-materialize'
import { useTokenEnte } from "../components/hooks/useTokenEnte"


export const BackofficePage = ({token}) => {
    const debug = process.env.REACT_APP_DEBUG === "true"

    const {setToken} = useTokenEnte()

    const history = useHistory()

    if (token) {
        fetch(process.env.REACT_APP_SERVER_URL + 'ente/check_token', {
            method: 'POST',
            body: JSON.stringify({token})
        })
            .then(res => res.json())
            .then(res => {
                if (debug) console.log(res)
                if (res.isValid) {
                    history.push('/gestione_ente/anagrafica')
                }
            })
    }

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [messaggio, setMessaggio] = useState('')

    const handleChange = (e) => {
        let id = e.target.id
        let value = e.target.value

        switch(id) {
            case 'username':
                setUsername(value)
                break
            case 'password':
                setPassword(value)
                break
            default:
                break
        }
    }

    const checkLogin = () => {
        if (debug) console.log(username, password)
        fetch(process.env.REACT_APP_SERVER_URL + 'ente/login', {
            method: 'POST',
            body: JSON.stringify({username, password})
        })
            .then(res => res.json())
            .then(res => {
                if (debug) console.log(res)
                if (res.autorizzato) {
                    setMessaggio('')
                    setToken(res.token)
                    history.push('/gestione_ente/anagrafica')
                } else {
                    setMessaggio('DATI DI ACCESSO NON VALIDI')
                }
            })
    }

    return (
        <>
            <Container style={{marginTop: '100px'}}>
                <Row>
                    <Col l={6} m={8} s={12} offset="l3 m2">
                        <Card title="AREA RISERVATA">
                            <Row>
                                <TextInput id="username" label="USERNAME" s={12} onChange={handleChange} />
                                <TextInput id="password" label="PASSWORD" password s={12} onChange={handleChange} />
                                <Button waves='light' node='button' large style={{width: '100%', marginTop: '20px'}} onClick={checkLogin}>ACCEDI</Button>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <p className='center'>{messaggio}</p>
        </>
    )
}