import React, { useContext, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import 'materialize-css'
import { Container, Card, Row, Col, Button } from 'react-materialize'
import { HeaderCittadino } from '../components/HeaderCittadino'
import { PageFooter } from '../components/PageFooter'
import { GlobalContext } from '../context/GlobalState'


export const EsitoPagamentoPage = () => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const history = useHistory()
    const location = useLocation()

    const queryString = require('query-string')
    const parsed = queryString.parse(location.search)
    const keys = Object.keys(parsed)
    if (debug) console.log(parsed)

    const richiestaPagamento = JSON.parse(localStorage.getItem("richiestaPagamento"));
    if (debug) console.log('richiestaPagamento', richiestaPagamento)
    
    const { carrello, svuotaCarrello, saveContext, cittadino } = useContext(GlobalContext);
    const richiedente = cittadino ? cittadino.cf : 'anonimo'
    const [pagamenti, setPagamenti] = useState(null)

    const getPagamenti = () => {
        let idPagamento = null

        if (Array.isArray(parsed.idPagamento)) {
            idPagamento = parsed.idPagamento[0]
        } else {
            idPagamento = parsed.idPagamento
        }

        fetch(process.env.REACT_APP_SERVER_URL + 'pagamenti/' + idPagamento + '?richiedente=' + richiedente, {
            method: 'GET'
        })
            .then(response => response.json())
            .then(data => { 
                console.log({data}); 
                setPagamenti(data); 
            });
    }


    useEffect(() => {
        if (carrello.length) {
            svuotaCarrello()
        }
    }, [carrello, svuotaCarrello])


    useEffect(() => {
        saveContext()
    }, [saveContext])


    if (richiestaPagamento) {
        if (!keys.includes('idSession') || !keys.includes('esito')) {
            history.push("/")
        } else {
            if (richiestaPagamento.idSession !== parsed.idSession) {
                history.push("/")
            }
        }
    } else {
        history.push("/")
    }

    
    if (parsed.esito === "OK" && pagamenti === null) {
        if (debug) console.log("GET PAGAMENTI")
        getPagamenti()
    }


    const scaricaRicevuta = (idDominio, iuv, ccp) => {
        let url = process.env.REACT_APP_SERVER_URL + 'rpp/' + idDominio + '/' + iuv + '/' + ccp + '/rt?richiedente=' + richiedente
        if (debug) console.log(url)
        fetch(url, {
            method: 'GET',
            headers: { 
                'Accept': 'application/pdf'
            }
        })
            .then(res => res.text())
            .then(res => {
                if (debug) console.log("RICEVUTA", res)
                window.open(process.env.REACT_APP_SERVER_URL + 'pdf_ricevute/' + res)
            })
    }


    return (
        <Container>
            <HeaderCittadino history={history} />
            <Row>
                <Col s={12}>
                    <h1>ESITO PAGAMENTO: {parsed.esito}</h1>
                    <Card>
                        <Row>
                            <Col>
                                <h4>PROCEDURA DI PAGAMENTO {parsed.esito === "OK" ? "CONCLUSA" : "INTERROTTA"}</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <b>ID SESSIONE:</b>
                            </Col>
                            <Col>
                                {parsed.idSession}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <b>ID PAGAMENTO:</b>
                            </Col>
                            <Col>
                                {parsed.idPagamento}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <b>ESITO:</b>
                            </Col>
                            <Col>
                                {parsed.esito}
                            </Col>
                        </Row>
                        {
                            parsed.esito === "OK" ?
                            <></> :
                            <Row>
                                <Col>
                                    <p>Riprovare tra qualche minuto</p>
                                </Col>
                            </Row>
                        }
                    </Card>
                </Col>
            </Row>
            {
                parsed.esito === "OK" ?
                <>
                    <Row>
                        <Col s={12}>
                            <h3>Pagamenti eseguiti</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col s={12}>
                            {
                                pagamenti ?
                                pagamenti?.rpp?.map(
                                    (item, i) => <Card key={i}>
                                        <Row style={{marginBottom: 0}}>
                                            <Col s={12}>
                                                <b>causale:</b> {item?.pendenza?.causale}
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: 0}}>
                                            <Col s={12}>
                                                <b>importo:</b> € {item?.pendenza?.importo}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col s={12}>
                                                <b>beneficiario:</b> {item?.pendenza?.dominio?.ragioneSociale}
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: 0}}>
                                            <Col s={12}>
                                                <Button onClick={() => {scaricaRicevuta(item?.pendenza?.dominio?.idDominio, item?.rpt?.datiVersamento?.identificativoUnivocoVersamento, item?.rpt?.datiVersamento?.codiceContestoPagamento)}}>SCARICA LA RICEVUTA DEL PAGAMENTO</Button>
                                            </Col>
                                        </Row>
                                    </Card>
                                ) :
                                <p>GENERAZIONE DELLE RICEVUTE TELEMATICHE IN CORSO, ATTENDERE QUALCHE SECONDO...</p>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col s={12}>
                            <p>Potrai scaricare le ricevute dei pagamenti anche accedendo al tuo profilo tramite SPID</p>
                        </Col>
                    </Row>
                </> :
                <></>
            }
            <PageFooter />
        </Container>
    )
}