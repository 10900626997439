import React, { useContext, useState } from 'react'
import 'materialize-css'
import { Row, Col, TextInput, Select, Button, Icon, Card } from 'react-materialize'
// custom hooks
import { useTokenCittadino } from "../components/hooks/useTokenCittadino"
import { useGeneraIdPendenza } from "../components/hooks/useGeneraIdPendenza"
import { useGetNomeServizio } from "../components/hooks/useGetNomeServizio"
import { useGetAnni } from "../components/hooks/useGetAnni"
import { useSigleNazioni } from "../components/hooks/useSigleNazioni"
import { useGetUnitaOperativaPredefinita } from '../components/hooks/useGetUnitaOperativaPredefinita'

import { GlobalContext } from '../context/GlobalState';

export const FormPagamentoDPC032 = (props) => {
    // const debug = process.env.REACT_APP_DEBUG === "true"
    const { token } = useTokenCittadino()
    const getNomeServizio = useGetNomeServizio()
    const generaIdPendenza = useGeneraIdPendenza()
    const getUnitaOperativaPredefinita = useGetUnitaOperativaPredefinita()
    const [ anno, anni ] = useGetAnni()
    const sigleNazioni = useSigleNazioni()


    const { 
        provincia, 
        ente, 
        servizio,
        setNuovaPendenza, 
        carrello, 
        aggiornaPendenzaCarrello,
        cittadino
    } = useContext(GlobalContext)


    const formIniziale = {
        idDominio: ente,
        codEntrata: servizio,
        tipo: "F",
        identificativo: "",
        cognome: "",
        nome: "",
        indirizzo: "",
        civico: "",
        cap: "",
        localita: "",
        provincia: "",
        nazione: "IT",
        causale: "",
        importo: "",
        meseRiferimento: "GENNAIO",
        annoRiferimento: anno
    }


    const tipoPagamento = {
        'RA_DM_DPC032_001_IMP_REG_CONC': 'DEMANIO MARITTIMO - IMPOSTA REGIONALE CONCESSIONI',
        'RA_DM_DPC032_002_IMP_REG_CONC_RA_OP': 'DEMANIO MARITTIMO - IMPOSTA REGIONALE CONCESSIONI - RAVVEDIMENTO OPEROSO',
        'RA_DM_DPC032_003_IMP_REG_CONC_ACC': 'DEMANIO MARITTIMO - IMPOSTA REGIONALE CONCESSIONI - ACCERTAMENTO',
        'RA_DM_DPC032_004_SANZ_VER_ING_PF': 'DEMANIO MARITTIMO - VERBALI O INGIUNZIONI - PERSONE FISICHE',
        'RA_DM_DPC032_005_SANZ_VER_ING_PG': 'DEMANIO MARITTIMO - VERBALI O INGIUNZIONI - PERSONE GIURIDICHE',
        'RA_PAES_DPC032_006_DIR_ISTR_RIL_AUT': 'PAESAGGIO - DIRITTI ISTRUTTORIA RILASCIO AUTORIZZAZIONI (ART.3BIS -L.R.2/2003)',
        'RA_URB_DPC032_007_ONERI_10_2011': 'URBANISTICA - ONERI DI URBANIZZAZIONE RECUPERO SOTTOTETTI (ART.1 L.R.10/2011)',
        'RA_URB_DPC032_008_ONERI_49_2012': 'URBANISTICA - ONERI DI URBANIZZAZIONE (L.R.49/2012)',
        'RA_URB_DPC032_009_ONERI_16_2009': 'URBANISTICA - ONERI DI URBANIZZAZIONE INTERVENTI SU PATRIMONIO EDILIZIO ESISTENTE - (ART. 7 L.R. 16/2009)',
        'RA_URB_DPC032_010_ONERI_40_2017': 'URBANISTICA - ONERI DI URBANIZZAZIONE SUPERFICI ACCESSORIE (L.R.40/2017)'
    }


    const getFormData = () => {
        if (props?.modifica && carrello[props?.modifica] !== undefined) {
            return carrello[props?.modifica].datiForm
        } else {
            return formIniziale
        }
    }


    const [datiForm, setDatiForm] = useState(getFormData());


    if (token && cittadino) {
        if (datiForm.identificativo === "" && datiForm.nome === "" && datiForm.cognome === "") {
            setDatiForm({
                ...datiForm,
                "identificativo": cittadino.cf,
                "nome": cittadino.nome,
                "cognome": cittadino.cognome,
            });
        }
    }


    const handleChange = (e) => {
        setDatiForm({
            ...datiForm,
            [e.target.id]: e.target.value
        });
    }


    const checkForm = () => {
        let valid = true

        if (!provincia.length || !ente.length || !servizio.length) valid = false
        // if (datiForm.causale.length === 0) valid = false
        if (datiForm.annoRiferimento.length === 0 || !anni.includes(parseInt(datiForm.annoRiferimento))) valid = false
        if (datiForm.importo < 0.01) valid = false
        if (datiForm.identificativo.length !== 11 && datiForm.identificativo.length !== 16) valid = false
        if (datiForm.cognome.length < 1) valid = false
        if (datiForm.civico.length && !datiForm.indirizzo.length) valid = false
        if (datiForm.provincia !== "" && datiForm.provincia.length !== 2) valid = false
        if (datiForm.cap !== "" && datiForm.cap.length !== 5) valid = false
        if (datiForm.nazione !== "" && datiForm.nazione.length !== 2) valid = false

        return valid
    }


    const submitForm = (e) => {
        e.preventDefault();

        let idA2A = "CPP"
        let idDominio = ente
        let idPendenza = generaIdPendenza('', false)
        let descrizione = getNomeServizio()
        let codEntrata = servizio
        let importo = Number(Number(datiForm.importo).toFixed(2))

        let unitaOperativa = getUnitaOperativaPredefinita(ente, servizio)

        let soggettoPagatore = {
            tipo: datiForm.tipo,
            identificativo: datiForm.identificativo,
            anagrafica: (datiForm.nome + " " + datiForm.cognome).trim(),
        }

        if (datiForm.indirizzo.length) soggettoPagatore['indirizzo'] = datiForm.indirizzo
        if (datiForm.civico.length) soggettoPagatore['civico'] = datiForm.civico
        if (datiForm.cap.length) soggettoPagatore['cap'] = datiForm.cap
        if (datiForm.localita.length) soggettoPagatore['localita'] = datiForm.localita
        if (datiForm.provincia.length) soggettoPagatore['provincia'] = datiForm.provincia
        if (datiForm.nazione.length) soggettoPagatore['nazione'] = datiForm.nazione

        let datiPendenza = {
            idA2A: idA2A,
            idPendenza: idPendenza,
            idDominio: idDominio,
            causale: componiCausale(),
            soggettoPagatore: soggettoPagatore,
            importo: importo,
            annoRiferimento: datiForm.annoRiferimento,
            voci: [
                {
                    idVocePendenza: idPendenza,
                    importo: importo,
                    descrizione: descrizione,
                    codEntrata: codEntrata
                }
            ]
        }

        if (unitaOperativa.length) {
            datiPendenza['idUnitaOperativa'] = unitaOperativa
        }

        let pendenza = { datiForm, datiPendenza }
        
        if (props?.onSubmit) {
            setNuovaPendenza(pendenza)
            props.onSubmit()
        }
    }


    const componiCausale = () => {
        let causale = ''

        switch (servizio) {
            case 'RA_DM_DPC032_001_IMP_REG_CONC':
            case 'RA_DM_DPC032_002_IMP_REG_CONC_RA_OP':
            case 'RA_DM_DPC032_003_IMP_REG_CONC_ACC':
                causale = tipoPagamento[servizio] + " - " + datiForm.annoRiferimento
                break
            case 'RA_DM_DPC032_004_SANZ_VER_ING_PF':
            case 'RA_DM_DPC032_004_SANZ_VER_ING_PG':
                causale = tipoPagamento[servizio] + " - " + datiForm.meseRiferimento + " " + datiForm.annoRiferimento
                break
            default:
                causale = tipoPagamento[servizio]
        }

        return causale
    }


    const aggiornaPendenza = (e) => {
        e.preventDefault();

        let pendenzaDaModificare = carrello[props.modifica]
        let importo = Number(Number(datiForm.importo).toFixed(2))

        let soggettoPagatore = {
            tipo: datiForm.tipo,
            identificativo: datiForm.identificativo,
            anagrafica: (datiForm.nome + " " + datiForm.cognome).trim(),
        }

        if (datiForm.indirizzo.length) soggettoPagatore['indirizzo'] = datiForm.indirizzo
        if (datiForm.civico.length) soggettoPagatore['civico'] = datiForm.civico
        if (datiForm.cap.length) soggettoPagatore['cap'] = datiForm.cap
        if (datiForm.localita.length) soggettoPagatore['localita'] = datiForm.localita
        if (datiForm.provincia.length) soggettoPagatore['provincia'] = datiForm.provincia
        if (datiForm.nazione.length) soggettoPagatore['nazione'] = datiForm.nazione

        datiForm.causale = componiCausale()
        pendenzaDaModificare.datiForm = datiForm
        pendenzaDaModificare.datiPendenza.causale = datiForm.causale
        pendenzaDaModificare.datiPendenza.importo = importo
        pendenzaDaModificare.datiPendenza.annoRiferimento = datiForm.annoRiferimento
        pendenzaDaModificare.datiPendenza.soggettoPagatore = soggettoPagatore
        pendenzaDaModificare.datiPendenza.voci[0].importo = importo

        let payload = {
            index: props.modifica,
            pendenza: pendenzaDaModificare
        }

        
        if (props?.onUpdate) {
            aggiornaPendenzaCarrello(payload)
            props.onUpdate()
        }
    }


    return (
        <>
            <Card title="Dati del pagamento">
                {
                    servizio === 'RA_DM_DPC032_004_SANZ_VER_ING_PF' || servizio === 'RA_DM_DPC032_004_SANZ_VER_ING_PG' ?
                    <Row>
                        <Col s={12} l={4}>
                            <TextInput s={12} label="Euro (*)" id="importo" onChange={handleChange} value={datiForm.importo} type="number" step="0.01" min="0.01" validate required />
                        </Col>
                        <Col s={12} l={4}>
                            <Select
                                s={12}
                                label="Mese di Riferimento (*)"
                                id="meseRiferimento"
                                multiple={false}
                                onChange={ handleChange }
                                options={{
                                    classes: '',
                                    dropdownOptions: {
                                        alignment: 'left',
                                        autoTrigger: true,
                                        closeOnClick: true,
                                        constrainWidth: false,
                                        coverTrigger: true,
                                        hover: false,
                                        inDuration: 150,
                                        onCloseEnd: null,
                                        onCloseStart: null,
                                        onOpenEnd: null,
                                        onOpenStart: null,
                                        outDuration: 250
                                    }
                                }}
                                value={ datiForm.meseRiferimento }
                                validate
                                required
                            >
                                <option value="GENNAIO" key="GENNAIO">GENNAIO</option>
                                <option value="FEBBRAIO" key="FEBBRAIO">FEBBRAIO</option>
                                <option value="MARZO" key="MARZO">MARZO</option>
                                <option value="APRILE" key="APRILE">APRILE</option>
                                <option value="MAGGIO" key="MAGGIO">MAGGIO</option>
                                <option value="GIUGNO" key="GIUGNO">GIUGNO</option>
                                <option value="LUGLIO" key="LUGLIO">LUGLIO</option>
                                <option value="AGOSTO" key="AGOSTO">AGOSTO</option>
                                <option value="SETTEMBRE" key="SETTEMBRE">SETTEMBRE</option>
                                <option value="OTTOBRE" key="OTTOBRE">OTTOBRE</option>
                                <option value="NOVEMBRE" key="NOVEMBRE">NOVEMBRE</option>
                                <option value="DICEMBRE" key="DICEMBRE">DICEMBRE</option>
                            </Select>
                        </Col>  
                        <Col s={12} l={4}>
                            <Select
                                s={12}
                                label="Anno di Riferimento (*)"
                                id="annoRiferimento"
                                multiple={false}
                                onChange={ handleChange }
                                options={{
                                    classes: '',
                                    dropdownOptions: {
                                        alignment: 'left',
                                        autoTrigger: true,
                                        closeOnClick: true,
                                        constrainWidth: false,
                                        coverTrigger: true,
                                        hover: false,
                                        inDuration: 150,
                                        onCloseEnd: null,
                                        onCloseStart: null,
                                        onOpenEnd: null,
                                        onOpenStart: null,
                                        outDuration: 250
                                    }
                                }}
                                value={ datiForm.annoRiferimento }
                                validate
                                required
                            >
                                { anni.map((a) => (<option value={a} key={a}>{a}</option>)) }
                            </Select>
                        </Col>
                    </Row> :
                    <Row>
                        <Col s={12} l={6}>
                            <TextInput s={12} label="Euro (*)" id="importo" onChange={handleChange} value={datiForm.importo} type="number" step="0.01" min="0.01" validate required />
                        </Col>
                        <Col s={12} l={6}>
                            <Select
                                s={12}
                                label="Anno di Riferimento (*)"
                                id="annoRiferimento"
                                multiple={false}
                                onChange={ handleChange }
                                options={{
                                    classes: '',
                                    dropdownOptions: {
                                        alignment: 'left',
                                        autoTrigger: true,
                                        closeOnClick: true,
                                        constrainWidth: false,
                                        coverTrigger: true,
                                        hover: false,
                                        inDuration: 150,
                                        onCloseEnd: null,
                                        onCloseStart: null,
                                        onOpenEnd: null,
                                        onOpenStart: null,
                                        outDuration: 250
                                    }
                                }}
                                value={ datiForm.annoRiferimento }
                                validate
                                required
                            >
                                { anni.map((a) => (<option value={a} key={a}>{a}</option>)) }
                            </Select>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col s={12}>
                        <TextInput s={12} label="Causale del Servizio (*)" id="causale" onChange={handleChange} value={componiCausale()} readOnly disabled />                        
                    </Col>
                </Row>
                <p>(*) CAMPI OBBLIGATORI</p>
            </Card>
            <Card title="Dati soggetto pagatore">
                <Row>
                    <Col s={12} l={6}>
                        <Select s={12} id="tipo" onChange={handleChange} value={datiForm.tipo} >
                            <option value="F">PERSONA FISICA</option>
                            <option value="G">PERSONA GIURIDICA</option>
                        </Select>
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Codice Fiscale / Partita IVA (*)" id="identificativo" onChange={handleChange} value={datiForm.identificativo} minLength={datiForm.tipo === "G" ? 11 : 16} maxLength={datiForm.tipo === "G" ? 11 : 16} validate required  />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Cognome / Ragione Sociale (*)" id="cognome" onChange={handleChange} value={datiForm.cognome} validate required />
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Nome" id="nome" onChange={handleChange} value={datiForm.nome} />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Indirizzo" id="indirizzo" onChange={handleChange} value={datiForm.indirizzo} />
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Numero Civico" id="civico" onChange={handleChange} value={datiForm.civico} />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Località" id="localita" onChange={handleChange} value={datiForm.localita} />
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Sigla Provincia" id="provincia" onChange={handleChange} value={datiForm.provincia} maxLength="2" validate />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="CAP" id="cap" onChange={handleChange} value={datiForm.cap} maxLength="5" validate />
                    </Col>
                    <Col s={12} l={6}>
                        <Select
                            s={12}
                            label="Nazione (*)"
                            id="nazione"
                            multiple={false}
                            onChange={ handleChange }
                            options={{
                                classes: '',
                                dropdownOptions: {
                                    alignment: 'left',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: false,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250
                                }
                            }}
                            value={ datiForm.nazione }
                            validate
                            required
                        >
                            { sigleNazioni.map((a) => (<option value={a.sigla} key={a.sigla}>{a.nome}</option>)) }
                        </Select>
                    </Col>
                </Row>
                <p>(*) CAMPI OBBLIGATORI</p>
            </Card>
            <Row>
                <Col s={12}>
                    {
                        props?.modifica && carrello[props?.modifica] !== undefined ?
                        <Button type="submit" node="button" waves="light" style={{width:'100%'}} large onClick={aggiornaPendenza}>
                            AGGIORNA PENDENZA
                            <Icon right>send</Icon>
                        </Button> :
                        <Button type="submit" node="button" waves="light" style={{width:'100%'}} large onClick={submitForm} disabled={!checkForm()}>
                            PROCEDI
                            <Icon right>send</Icon>
                        </Button>
                    }
                </Col>
            </Row>
        </>
    )
}
