import React, { useContext } from 'react'
import 'materialize-css'
import { Select } from 'react-materialize'

import { GlobalContext } from '../context/GlobalState';

export const SelezionaDelegato = ({onChange, delegaSelezionata, deleghe}) => {

    const handleChange = (value) => {
        if (onChange) onChange(value)
    }

    return (
        <Select
            s={12} 
            m={12}
            id="SelezionaEnte"
            multiple={false}
            onChange={ e => {handleChange(e.target.value)} }
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: false,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }}
            value={ delegaSelezionata }
        >
            <option
                value=""
            >
                SELEZIONA UNA DELEGA DA VISUALIZZARE
            </option>
            { deleghe.map((item) => (<option key={item.CF_PIVA_ENTE} value={item.CF_PIVA_ENTE}>{item.ENTE}</option>)) }
        </Select>
    )
}