import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import 'materialize-css'
import { Container, Card, Row, Col, Preloader } from 'react-materialize'

import { useTokenCittadino } from "../components/hooks/useTokenCittadino"

export const AutenticazionePage = () => {
    const debug = process.env.REACT_APP_DEBUG === "true"

    const { setToken } = useTokenCittadino()

    const history = useHistory()
    const location = useLocation()
    const hash = location.hash.replace('#','').split('&')
    const hashParams = {}

    hash.forEach(element => {
        let splitted = element.split('=')
        hashParams[splitted[0]] = splitted[1]
    });

    if (debug) console.log(hashParams)


    const checkToken = () => {
        fetch(process.env.REACT_APP_SERVER_URL + 'cittadino/check_token', {
            method: 'POST',
            body: JSON.stringify({token: hashParams.access_token})
        })
            .then(res => res.json())
            .then(res => {
                if (debug) console.log(res)
                if (res.valid) {
                    setToken(hashParams.access_token)
                    history.push("/gestione_cittadino/anagrafica")
                } else {
                    history.push("/")
                }
            })
    }


    useEffect(() => {
        checkToken()
    }, [checkToken])



    return (
        <Container className="center">
            <Card title="VERIFICA AUTENTICAZIONE">
                <Row>
                    <Col className="center" s={12}>
                        <Preloader active color="green" flashing={false} size="big" />
                    </Col>
                </Row>
            </Card>
        </Container>
    )
}