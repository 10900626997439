import React from 'react'
import 'materialize-css'
import { Row, Col, Footer, Card } from 'react-materialize'
import logoUE from '../img/ue.png'
import logoRepubblica from '../img/repubblica.png'
import logoAbruzzo from '../img/Regione-Abruzzo-Stemma-logo.png'
import logoPorFesrFse from '../img/por-fesr-fse.png'

export const PageFooter = () => {
    // const debug = process.env.REACT_APP_DEBUG === "true"
    return (
        <>
            <Row>
                <Col s={12}>
                    <Card title="Finanziato attraverso">
                        <Row>
                            <Col s={12} m={12} l={6}>
                                <p><i>Programma Operativo Regionale del Fondo Europeo di Sviluppo Regionale</i></p>
                                <p><b>POR FESR Abruzzo 2014-2020 – Asse 2</b></p>
                                <p>Azione 2.2.2 “Soluzioni tecnologiche per la realizzazione dei servizi di e-government interoperabili, integrati (joined-up services) e progettati con cittadini e imprese e soluzioni integrate per le smart cities and communities”</p>
                            </Col>
                            <Col s={12} m={12} l={6} style={{textAlign: 'end'}}>
                                <img
                                        alt=""
                                        src={logoUE}
                                    />
                                <img
                                        alt=""
                                        src={logoRepubblica}
                                    />
                                <img
                                        alt=""
                                        src={logoAbruzzo}
                                        width="90px"
                                    />
                                <img
                                        alt=""
                                        src={logoPorFesrFse}
                                    />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col s={12}>
                    <Footer
                        style={{backgroundColor: '#30373d'}}
                        // className="teal lighten-1"
                        copyrights="Regione Abruzzo 2021"
                        links={
                            <ul>
                                <li><a className="grey-text text-lighten-3" href="https://www.regione.abruzzo.it/content/accessibilit%C3%A0" target="_blank" rel="noreferrer">Accessibilità</a></li>
                                <li><a className="grey-text text-lighten-3" href="https://www.regione.abruzzo.it/patrocinio" target="_blank" rel="noreferrer">Patrocinio</a></li>
                                <li><a className="grey-text text-lighten-3" href="https://www.regione.abruzzo.it/content/pubblicit%C3%A0-legale" target="_blank" rel="noreferrer">Pubblicità Legale</a></li>
                                {/* <li><a className="grey-text text-lighten-3" href="https://www.regione.abruzzo.it/content/informativa-sulla-privacy" target="_blank">Privacy policy</a></li> */}
                                <li><a className="grey-text text-lighten-3" href="https://www.regione.abruzzo.it/content/note-legali" target="_blank" rel="noreferrer">Note legali</a></li>
                            </ul>
                        }
                        moreLinks={<a className="grey-text text-lighten-4 right" href="https://www.aric.it/privacy" target="_blank" rel="noopener noreferrer">PRIVACY POLICY</a>}
                    >
                        <h5 className="white-text">
                            Regione Abruzzo
                        </h5>
                        <Row>
                            <Col>
                                <b>Sede L'Aquila</b><br />
                                Via Leonardo da Vinci, 6<br />
                                67100 L'Aquila<br />
                                <br />
                            </Col>
                            <Col>
                                <b>Sede Pescara</b><br />
                                Piazza Unione, 13<br />
                                65127 Pescara<br />
                                <br />
                            </Col>
                        </Row>
                        {/* <p className="grey-text text-lighten-4">
                            You can use rows and columns here to organize your footer content.
                        </p> */}
                    </Footer>
                </Col>
            </Row>
        </>
    )
}
