import React from 'react'
import 'materialize-css'
import { Row, Col, Card, Button } from 'react-materialize'



export const GestioneCittadinoPendenzeListItem = ({redirect, item}) => {
    // const debug = process.env.REACT_APP_DEBUG === "true"


    const visualizzaDettaglio = (idA2a, idDettaglio) => {
        let url = '/gestione_cittadino/pendenze/' + idA2a + '~' + idDettaglio
        redirect(url)
    }


    return (
        <Card>
            <h4 style={{marginTop: 0}}>{item.causale} - €{item.importo}</h4>
            <h5>Beneficiario: {item.dominio.ragioneSociale}</h5>
            <h5>Servizio: {item.tipoPendenza.descrizione}</h5>
            <h5>Stato: {item.stato}</h5>
            <Row>
                <Col>
                    <Button onClick={() => visualizzaDettaglio(item.idA2A, item.idPendenza)}>DETTAGLI</Button>
                </Col>
            </Row>
        </Card>                            
    );
}