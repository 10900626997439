import React, { useContext, useState, useEffect } from 'react'
import 'materialize-css'
import { Row, Col, Card, Button, Pagination, Icon } from 'react-materialize'

import { LoadingSmall } from '../loadingSmall';
import { LoadingFull } from '../loadingFull';

import { GlobalContext } from '../../context/GlobalState';

import { useTokenCittadino } from "../hooks/useTokenCittadino"

import { CardFiltriEnte } from '../CardFiltriEnte'


export const GestioneEnteRiscossioni = ({dettaglio, redirect}) => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const { token } = useTokenCittadino()
    const { datiEnte, cittadino } = useContext(GlobalContext);
    const [ riscossioni, setRiscossioni ] = useState(null);
    const [ riscossione, setRiscossione ] = useState(null);
    const [ filter, setFilter ] = useState(null);
    const [ codiceEnte, setCodiceEnte ] = useState(null)
    const [ loading, setLoading ] = useState(false)


    if (!codiceEnte) {
        setRiscossioni(null)
        setCodiceEnte(datiEnte?.idDominio)
    } else if (codiceEnte !== datiEnte?.idDominio) {
        setCodiceEnte(null)
    }


    const getRiscossioni = async (filterData, page) => {
        setLoading(true)
        let response = await fetch(process.env.REACT_APP_SERVER_URL + 'ente/riscossioni/' + codiceEnte, {
            method: 'POST',
            body: JSON.stringify({token, filter: filterData, page, richiedente: cittadino.cf})
        })

        let res = await response.json()

        if (debug) console.log(res);
        if (res.valid) {
            if (riscossioni !== res.result) setRiscossioni(res.result)
            setLoading(false)
        } else {
            redirect("/")
        }
    }


    const getDettaglioRiscossioni = async () => {
        let [iuv, iur, indice] = dettaglio.split('-')
        if (!riscossione || (riscossione?.iuv !== iuv && riscossione?.iur !== iur && riscossione?.indice !== indice)) {
            let response = await fetch(process.env.REACT_APP_SERVER_URL + 'ente/riscossioni/' + codiceEnte + '/' + iuv + '/' + iur + '/' + indice, {
                method: 'POST',
                body: JSON.stringify({token, richiedente: cittadino.cf})
            })

            let res = await response.json()

            if (debug) console.log(res); 
            if (res.valid) {
                setRiscossione(res.result)
            } else {
                redirect("/")
            }
        }
    }


    useEffect(() => {
        if (token) {
            if (dettaglio) {
                let [iuv, iur, indice] = dettaglio.split('-')
                if (riscossione?.iuv !== iuv && riscossione?.iur !== iur && riscossione?.indice !== indice) {
                    getDettaglioRiscossioni()
                }
            } else if (!dettaglio && !riscossioni) {
                getRiscossioni(filter, 1)
            }
        } else {
            redirect("/")
        }
    }, [token, redirect, dettaglio, getRiscossioni, getDettaglioRiscossioni])


    const visualizzaDettaglio = (iuv, iur, indice) => {
        let url = '/gestione_ente/riscossioni/' + iuv + '-' + iur + '-' + indice
        redirect(url)
    }


    const filterSaved = (filterData) => {
        if (debug) console.log('filter saved', filterData)
        setFilter(filterData)
        getRiscossioni(filterData, 1)
    }


    return (
        <>
            { loading ? <LoadingFull /> : null }
            {
                dettaglio ?
                <>
                    <Row>
                        <Col>
                            <Button onClick={() => redirect('/gestione_ente/riscossioni')}>indietro</Button>
                        </Col>
                        <Col>
                            <h4 style={{marginTop: 0}}>Dettaglio riscossione</h4>
                        </Col>
                    </Row>   
                    {
                        riscossione ?
                        <Card>
                            <Row style={{marginBottom: '5px'}}>
                                <Col>
                                    <b>ID voce pendenza:</b> {riscossione.idVocePendenza}
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '5px'}}>
                                <Col>
                                    <b>Importo:</b> {riscossione.importo}
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '5px'}}>
                                <Col>
                                    <b>IUR:</b> {riscossione.iur}
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '5px'}}>
                                <Col>
                                    <b>IUV:</b> {riscossione.iuv}
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '5px'}}>
                                <Col>
                                    <b>Data:</b> {riscossione.data}
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '5px'}}>
                                <Col>
                                    <b>Stato:</b> {riscossione.stato}
                                </Col>
                            </Row>
                        </Card> :
                        <LoadingSmall />
                    }          
                </> :
                <>
                    <Row>
                        <Col s={12}>
                            <h4 style={{marginTop: 0}}>Riscossioni</h4>
                            <p>Elenco delle riscossioni ricevute</p>
                            <CardFiltriEnte tipo="riscossioni" onFilterSaved={filterSaved} />
                            <h5>Riscossioni trovate: { riscossioni ? riscossioni?.numRisultati : "0" }</h5>
                        </Col>
                    </Row>
                    {
                        riscossioni ?
                        <>
                            <Pagination
                                activePage={riscossioni.pagina}
                                items={riscossioni.numPagine}
                                leftBtn={<Icon>chevron_left</Icon>}
                                maxButtons={10}
                                rightBtn={<Icon>chevron_right</Icon>}
                                onSelect={(page) => getRiscossioni(filter, page)}
                            />
                            {riscossioni?.risultati?.map(
                                (item, i) => (<Card key={i}>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <b>riscossione (IUR):</b> {item.iur}
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <b>importo:</b> {item.importo}
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <b>pendenza:</b> {item.idVocePendenza}
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <b>IUV:</b> {item.iuv}
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <Button onClick={() => visualizzaDettaglio(item.iuv, item.iur, item.indice)}>DETTAGLI</Button>
                                        </Col>
                                    </Row>  
                                </Card>)
                            )} 
                        </> : 
                        <LoadingSmall />
                    }
                </>
            }
        </>
        
    );
}