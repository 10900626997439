import React from 'react';
import 'materialize-css/dist/css/materialize.min.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { HomePage } from "./pages/HomePage"
import { ContactPage } from "./pages/ContactPage"
import { PagamentoPage } from "./pages/PagamentoPage"
import { ConfermaDatiPagamentoPage } from "./pages/ConfermaDatiPagamentoPage"
import { VerificaAvvisoPage } from "./pages/VerificaAvvisoPage"
import { GestioneEntePage } from "./pages/GestioneEntePage"
import { CarrelloPage } from "./pages/CarrelloPage"
import { EsitoPendenzaPage } from "./pages/EsitoPendenzaPage"
import { EsitoPagamentoPage } from "./pages/EsitoPagamentoPage"
import { BackofficePage } from "./pages/BackofficePage"
import { AutenticazionePage } from "./pages/AutenticazionePage"
import { GestioneCittadinoPage } from "./pages/GestioneCittadinoPage"
import { ConvertiFlussoPage } from "./pages/ConvertiFlussoPage"
import { NotFound } from "./pages/NotFound"
import { ErrorPage } from "./pages/ErrorPage"

import { GlobalProvider } from "./context/GlobalState"

import './App.css';

function App() {
  return (
    <GlobalProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <HomePage />  
          </Route>
          <Route path="/contatti" exact>
            <ContactPage />
          </Route>
          <Route path="/pagamento" exact>
            <PagamentoPage />
          </Route>
          <Route path="/pagamento/conferma_dati" exact>
            <ConfermaDatiPagamentoPage />
          </Route>
          <Route path="/pagamento/verifica_avviso" exact>
            <VerificaAvvisoPage />
          </Route>
          <Route path="/pagamento/:provincia" exact>
            <PagamentoPage />
          </Route>
          <Route path="/pagamento/:provincia/:ente" exact>
            <PagamentoPage />
          </Route>
          <Route path="/pagamento/:provincia/:ente/:servizio" exact>
            <PagamentoPage />
          </Route>
          <Route path="/carrello" exact>
            <CarrelloPage />
          </Route>
          <Route path="/esito_avviso" exact>
            <EsitoPendenzaPage />
          </Route>
          <Route path="/esito_pagamento" exact>
            <EsitoPagamentoPage />
          </Route>
          <Route path="/backoffice" exact>
            <BackofficePage />
          </Route>
          <Route path="/converti_flusso" exact>
            <ConvertiFlussoPage />
          </Route>
          <Route path="/gestione_ente" exact>
            <GestioneEntePage />
          </Route>
          <Route path="/gestione_ente/:sezione" exact>
            <GestioneEntePage />
          </Route>
          <Route path="/gestione_ente/:sezione/:dettaglio" exact>
            <GestioneEntePage />
          </Route>
          <Route path="/gestione_ente/:sezione/:dettaglio/:operazioni" exact>
            <GestioneEntePage />
          </Route>
          <Route path="/autenticazione" exact>
            <AutenticazionePage />
          </Route>
          <Route path="/gestione_cittadino/:sezione" exact>
            <GestioneCittadinoPage />
          </Route>
          <Route path="/gestione_cittadino/:sezione/:dettaglio" exact>
            <GestioneCittadinoPage />
          </Route>
          <Route path="/error">
            <ErrorPage />
          </Route>
          <Route path="/">
            <NotFound />
          </Route>
        </Switch>
      </BrowserRouter>
    </GlobalProvider>
  );
}

export default App;
