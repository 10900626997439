import { useContext } from 'react'
import { GlobalContext } from '../../context/GlobalState';

export const useGeneraIdPendenza = () => {

    const debug = process.env.REACT_APP_DEBUG === "true"

    const { datiEnte, ente, provincia, servizio } = useContext(GlobalContext)

    if (debug) console.log('useGeneraIdPendenza', datiEnte, ente, provincia, servizio)

    const codiceProvincia = {
        'ABRUZZO': '000',
        'AQ': '066',
        'TE': '067',
        'PE': '068',
        'CH': '069'
    }

    const getDataFormattata = () => {
        let dataFormattata = ""

        let data = new Date()
        let anno = data.getFullYear()
        let mese = (data.getMonth() + 1).toString().padStart(2, '0')
        let giorno = data.getDate().toString().padStart(2, '0')
        let ora = data.getHours().toString().padStart(2, '0')
        let minuti = data.getMinutes().toString().padStart(2, '0')
        let secondi = data.getSeconds().toString().padStart(2, '0')
        let millisecondi = data.getMilliseconds().toString().padStart(3, '0')

        dataFormattata += anno
        dataFormattata += mese
        dataFormattata += giorno
        dataFormattata += ora
        dataFormattata += minuti
        dataFormattata += secondi
        dataFormattata += millisecondi

        return dataFormattata
    }

    const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min)
    }

    const generaStringa = (lunghezza) => {
        const caratteri = "1234567890qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM"

        let stringa = ""

        for(let i = 0; i < lunghezza; i++) {
            let index = getRandomInt(0, caratteri.length)
            let carattere = caratteri.charAt(index)
            stringa += carattere
        }

        return stringa
    }

    const generaIdPendenza = (tipo, backend) => {

        let idPendenza = "13"
        if (backend) {
            if (datiEnte.idDominio == '80003170661') {
                idPendenza += codiceProvincia['ABRUZZO']
            } else {
                idPendenza += codiceProvincia[datiEnte.provincia]
            }
        } else {
            if (ente == '80003170661') {
                idPendenza += codiceProvincia['ABRUZZO']
            } else {
                idPendenza += codiceProvincia[provincia]
            }
        }

        if (tipo) {
            if (tipo === 'arisgan') {
                idPendenza += "DPB006"
                idPendenza += getDataFormattata()
                idPendenza += generaStringa(6)

                return idPendenza
            } else if (tipo === 'idrico') {
                let servizioSplit = servizio.split("_")
                let capitolo = servizioSplit[2]
                let progServizio = servizioSplit[3]
                idPendenza += capitolo
                idPendenza += progServizio
                idPendenza += getDataFormattata()
                idPendenza += generaStringa(4)
            }
        } else {
            idPendenza += getDataFormattata()
            idPendenza += generaStringa(6)
        }

        return idPendenza
    }


    return generaIdPendenza
}