import React from 'react'
import 'materialize-css'
import { Navbar, NavItem, Icon, Row, Col } from 'react-materialize'
import logo from '../img/Regione-Abruzzo-Stemma-scritta.png'


export const HeaderEnte = (props) => {
    // const debug = process.env.REACT_APP_DEBUG === "true"

    const logout = (e) => {
        e.preventDefault()
        localStorage.clear()
        if (props?.history) {
            props.history.push('/')
        }
    }

    const goToProfile = (e) => {
        e.preventDefault()
        if (props?.history) {
            props.history.push('/gestione_cittadino/anagrafica')
        }
    }

    return (
        <>
            <Row>
                <Col
                    l={6}
                    m={9}
                    s={12}
                >
                    <img
                        alt=""
                        src={logo}
                        width="100%"
                    />
                </Col>
            </Row>
            <Row>
                <Col s={12}>
                    <Navbar
                        className="blue darken-4"
                        alignLinks="right"
                        brand={<a className="brand-logo" href={"/gestione_ente/anagrafica"} style={{paddingLeft: '15px'}}>Gestione Ente</a>}
                        id="mobile-nav"
                        menuIcon={<Icon>menu</Icon>}
                        options={{
                            draggable: true,
                            edge: 'left',
                            inDuration: 250,
                            onCloseEnd: null,
                            onCloseStart: null,
                            onOpenEnd: null,
                            onOpenStart: null,
                            outDuration: 200,
                            preventScrolling: true
                        }}
                    >
                        <NavItem href="#" onClick={goToProfile}>
                            PROFILO CITTADINO
                        </NavItem>
                        <NavItem href="#" onClick={logout}>
                            ESCI
                        </NavItem>
                    </Navbar>
                </Col>
            </Row>
        </>
    )
}
