import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import 'materialize-css'
import { Container, Card, Row, Col } from 'react-materialize'
import { HeaderCittadino } from '../components/HeaderCittadino'
import { PageFooter } from '../components/PageFooter'
import { GlobalContext } from '../context/GlobalState'


export const ErrorPage = () => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const history = useHistory()

    const [error, setError] = useState(null)

    const { saveContext } = useContext(GlobalContext)

    saveContext()

    const errorStored = localStorage.getItem("error")
    
    if (errorStored && !error) {
        setError(JSON.parse(errorStored))
    }

    if (debug) console.log('ERROR', error)

    return (
        <Container>
            <HeaderCittadino history={history} />
            <Row>
                <Col s={12}>
                    <Card title="ERRORE NELLA PROCEDURA DI PAGAMENTO">
                        <p>Si è verificato un errore di sistema nell'elaborazione del pagamento.</p>
                        <br />
                        <p><b>DESCRIZIONE:</b> {error ? error?.descrizione : "descrizione non disponibile"}</p>
                        <p><b>DETTAGLIO:</b> {error ? error?.dettaglio : "dettaglio non disponibile"}</p>
                        <br />
                        <p>Vi preghiamo di riprovare tra qualche minuto.</p>
                        <p>Se il problema dovesse persistere contattate l'Ente per segnalare l'errore.</p>
                    </Card>
                </Col>
            </Row>
            <PageFooter />
        </Container>
    )
}