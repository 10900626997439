import React, { useContext, useState, useEffect, useCallback } from 'react'
import 'materialize-css'
import { Row, Col, Card, Button, Pagination, Icon } from 'react-materialize'

import { LoadingSmall } from '../loadingSmall';
import { LoadingFull } from '../loadingFull';

import { GlobalContext } from '../../context/GlobalState';

import { useTokenCittadino } from "../hooks/useTokenCittadino"
import { useScaricaRicevuta } from '../hooks/useScaricaRicevuta';
import { useScaricaAvviso } from '../hooks/useScaricaAvviso';


import { CardFiltriEnte } from '../CardFiltriEnte'


export const GestioneEntePendenze = ({ dettaglio, redirect }) => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const { token } = useTokenCittadino()
    const { datiEnte, cittadino } = useContext(GlobalContext);
    const [ pendenze, setPendenze ] = useState(null);
    const [ pendenza, setPendenza ] = useState(null);
    const [ filter, setFilter ] = useState(null);
    const [ codiceEnte, setCodiceEnte ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    
    const scaricaAvviso = useScaricaAvviso(cittadino.cf)
    const scaricaRicevuta = useScaricaRicevuta(cittadino.cf)


    if (!codiceEnte) {
        setPendenze(null)
        setCodiceEnte(datiEnte?.idDominio)
    } else if (codiceEnte !== datiEnte?.idDominio) {
        setCodiceEnte(null)
    }


    // FILTRA I SERVIZI IN BASE AI PERMESSI
    const selectServizi = useCallback(() => {
        let abilitati = datiEnte?.entrate?.filter(entrata => entrata.abilitato)

        let tipiPendenza = {
            id: [],
            obj: []
        }

        if (cittadino.permessi[0].ENTE === "TUTTI") {
            tipiPendenza.obj = abilitati
            console.log({tipiPendenza})
            return tipiPendenza
        }

        let entiAmministrati = cittadino.permessi.filter(permesso => permesso.RUOLI[0].tipo === 'Amministratore').map(permesso => permesso.CF_PIVA_ENTE)
        if (entiAmministrati.includes(datiEnte.idDominio)) {
            tipiPendenza.obj = abilitati
            console.log({tipiPendenza})
            return tipiPendenza
        }

        let entiGestiti = cittadino.permessi.filter(permesso => permesso.RUOLI[0].tipo === 'GestoreServizi').map(permesso => permesso.CF_PIVA_ENTE)
        if (entiGestiti.includes(datiEnte.idDominio)) {
            let permesso = cittadino.permessi.filter(p => p.CF_PIVA_ENTE == datiEnte.idDominio)[0]
            let tipi = permesso.RUOLI.filter(r => r.tipo === 'GestoreServizi').map(item => item.servizi)
            
            if (tipi[0].length) {
                tipiPendenza.id = tipi[0]
                let obj = abilitati.filter(tipo => tipi[0].includes(tipo.tipoEntrata.idEntrata))
                tipiPendenza.obj = obj
                console.log({tipiPendenza, obj, datiEnte})
                return tipiPendenza
            }

            console.log('FILTER E')
            tipiPendenza.obj = abilitati
            console.log({tipiPendenza})
            return tipiPendenza
        }

        return tipiPendenza

    }, [datiEnte])


    const [selectPendenze, setSelectPendenze] = useState(selectServizi())


    useEffect(() => {
        let tipiPendenza = selectServizi()
        setSelectPendenze(tipiPendenza)
    }, [setSelectPendenze, datiEnte])
    // FILTRA I SERVIZI IN BASE AI PERMESSI


    const getPendenze = useCallback(
        async (filterData, page) => {
            setLoading(true)
            console.log({filterData})
            if (!filterData) {
                filterData = {
                    idTipoPendenza: selectPendenze.id
                }
            }
            console.log({filterData})
            let response = await fetch(process.env.REACT_APP_SERVER_URL + 'ente/pendenze/' + datiEnte.idDominio, {
                method: 'POST',
                body: JSON.stringify({token, filter: filterData, page, richiedente: cittadino.cf})
            })
    
            let res = await response.json()
    
            if (debug) console.log(res)
    
            if (res.valid) {
                if (pendenze !== res.result) setPendenze(res.result)
                setLoading(false)
            } else {
                redirect("/")
            }
        },
        [datiEnte, selectPendenze]
    )
    
    
    const getDettaglioPendenze = useCallback(
        async () => {
            setLoading(true)
            let [idA2A, id] = dettaglio.split('~')
            if (!pendenza || pendenza?.idPendenza !== id) {
                let response = await fetch(process.env.REACT_APP_SERVER_URL + 'ente/pendenze/' + datiEnte.idDominio + '/' + idA2A + '/' + id, {
                    method: 'POST',
                    body: JSON.stringify({token, richiedente: cittadino.cf})
                })
    
                let res = await response.json()
    
                if (debug) console.log(res); 
                if (res.valid) {
                    setPendenza(res.result); 
                    setLoading(false)
                } else {
                    redirect("/")
                }
            }
        },
        [dettaglio, datiEnte]
    )
    

    useEffect(() => {
        if (token) {
            if (dettaglio) {
                let [idA2A, id] = dettaglio.split('~')
                if (dettaglio && pendenza?.idPendenza !== id) {
                    getDettaglioPendenze()
                }
            } else if (!dettaglio && !pendenze) {
                getPendenze(filter, 1)
            }
        } else {
            redirect("/")
        }
    }, [token, dettaglio, getPendenze, getDettaglioPendenze, selectPendenze, datiEnte])


    const visualizzaDettaglio = (idA2A, idDettaglio) => {
        let url = '/gestione_ente/pendenze/' + idA2A + '~' + idDettaglio
        redirect(url)
    }


    const filterSaved = (filter) => {
        if (debug) console.log('filter saved', filter)
        getPendenze(filter, 1)
    } 


    return (
        <>
            { loading ? <LoadingFull /> : null }
            {
                dettaglio ?
                <>
                    <Row>
                        <Col>
                            <Button onClick={() => redirect('/gestione_ente/pendenze')}>indietro</Button>
                        </Col>
                        <Col>
                            <h4 style={{marginTop: 0}}>Dettaglio pendenza</h4>
                        </Col>
                    </Row>
                    {
                        pendenza ?
                        <Card>
                            <Row style={{marginBottom: '5px'}}>
                                <Col>
                                    <b>Descrizione:</b> {pendenza?.tipoPendenza?.descrizione}
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '5px'}}>
                                <Col>
                                    <b>Causale:</b> {pendenza?.causale}
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '5px'}}>
                                <Col>
                                    <b>Importo:</b> {pendenza?.importo}
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '5px'}}>
                                <Col>
                                    <b>Tipo di pagamento:</b> {pendenza?.iuvPagamento ? 'SPONTANEO' : 'DOVUTO'}
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '5px'}}>
                                <Col>
                                    <b>Stato:</b> {pendenza?.stato}
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '5px'}}>
                                <Col>
                                    <b>CF debitore:</b> {pendenza?.soggettoPagatore?.identificativo}
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '5px'}}>
                                <Col>
                                    <b>Nome debitore:</b> {pendenza?.soggettoPagatore?.anagrafica}
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '5px'}}>
                                <Col>
                                    <b>ID pendenza:</b> {pendenza?.idPendenza}
                                </Col>
                            </Row>
                            {
                                pendenza.iuvPagamento ?
                                <Row style={{marginBottom: '5px'}}>
                                    <Col>
                                        <b>IUV pagamento:</b> {pendenza?.iuvPagamento}
                                    </Col>
                                </Row> :
                                <Row style={{marginBottom: '5px'}}>
                                    <Col>
                                        <b>IUV avviso:</b> {pendenza?.iuvAvviso}
                                    </Col>
                                    <Col>
                                        <b>Numero avviso:</b> {pendenza?.numeroAvviso}
                                    </Col>
                                </Row>   
                            }
                            <Row style={{marginBottom: '5px'}}>
                                <Col>
                                    <b>Data di caricamento:</b> {pendenza?.dataCaricamento}
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '5px'}}>
                                <Col>
                                    <b>Data di aggiornamento:</b> {pendenza?.dataUltimoAggiornamento}
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '5px'}}>
                                <Col>
                                    <b>Data scadenza:</b> {pendenza?.dataScadenza}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <b>Data pagamento:</b> {pendenza?.dataPagamento}
                                </Col>
                            </Row>
                            <Row style={{marginBottom: 0}}>
                                <Col>
                                    <Button disabled={pendenza.stato !== 'NON_ESEGUITA'} onClick={() => {scaricaAvviso(pendenza?.dominio?.idDominio, pendenza?.numeroAvviso)}}>SCARICA AVVISO PAGAMENTO</Button>
                                </Col>
                                <Col>
                                    <Button disabled={pendenza.stato !== 'ESEGUITA' || pendenza?.rpp[0]?.rt?.datiPagamento?.CodiceContestoPagamento === "n/a"} onClick={() => {scaricaRicevuta(pendenza?.dominio?.idDominio, pendenza?.iuvPagamento, pendenza?.rpp[0]?.rt?.datiPagamento?.CodiceContestoPagamento)}}>SCARICA RICEVUTA PAGAMENTO</Button>
                                </Col>
                            </Row> 
                        </Card> :
                        <LoadingSmall />
                    }
                </> :
                <>
                    <Row>
                        <Col s={12}>
                            <h4 style={{marginTop: 0}}>Pendenze</h4>
                            <p>Elenco delle pendenze associate all'Ente</p>
                            <CardFiltriEnte tipo="pendenze" onFilterSaved={filterSaved} idTipiPendenza={selectPendenze.id} selectTipiPendenza={selectPendenze.obj} />
                            <h5>Pendenze trovate: { pendenze ? pendenze?.numRisultati : "0" }</h5>
                        </Col>
                    </Row>
                    {
                        pendenze ? 
                        <>
                            <Pagination
                                activePage={pendenze.pagina}
                                items={pendenze.numPagine}
                                leftBtn={<Icon>chevron_left</Icon>}
                                maxButtons={10}
                                rightBtn={<Icon>chevron_right</Icon>}
                                onSelect={(page) => getPendenze(filter, page)}
                            />
                            {pendenze?.risultati?.map(
                                (pendenza) => (<Card key={pendenza.idPendenza}>
                                    <h5 style={{marginTop: 0}}>{pendenza.causale} - €{pendenza.importo}</h5>
                                    <h6 style={{marginTop: 0, marginBottom: '20px'}}>{pendenza.tipoPendenza.descrizione}</h6>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <b>Tipo di pagamento:</b> {pendenza.iuvPagamento ? "SPONTANEO" : "DOVUTO"}
                                        </Col>
                                        <Col>
                                            <b>Stato:</b> {pendenza.stato}
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <b>ID pendenza:</b> {pendenza.idPendenza}
                                        </Col>
                                    </Row>
                                    {
                                        pendenza.iuvPagamento ?
                                        <Row style={{marginBottom: '5px'}}>
                                            <Col>
                                                <b>IUV pagamento:</b> {pendenza.iuvPagamento}
                                            </Col>
                                        </Row> :
                                        <Row style={{marginBottom: '5px'}}>
                                            <Col>
                                                <b>IUV avviso:</b> {pendenza.iuvAvviso}
                                            </Col>
                                            <Col>
                                                <b>Numero avviso:</b> {pendenza.numeroAvviso}
                                            </Col>
                                        </Row>
                                    }
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <b>CF intestatario:</b> {pendenza.soggettoPagatore?.identificativo}
                                        </Col>
                                        <Col>
                                            <b>Nome intestatario:</b> {pendenza.soggettoPagatore?.anagrafica}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <b>Data di caricamento:</b> {pendenza.dataCaricamento}
                                        </Col>
                                        <Col>
                                            <b>Data di aggiornamento:</b> {pendenza.dataUltimoAggiornamento}
                                        </Col>
                                        <Col>
                                            <b>Data di validità:</b> {pendenza.dataValidita}
                                        </Col>
                                        <Col>
                                            <b>Data di pagamento:</b> {pendenza.dataPagamento}
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <Button onClick={() => visualizzaDettaglio(pendenza.idA2A, pendenza.idPendenza)}>DETTAGLI</Button>
                                        </Col>
                                    </Row>
                                </Card>)
                            )}
                        </> :
                        <LoadingSmall />
                    }
                </>
            }
        </>
    );
}