import React, { useContext, useState } from 'react'
import 'materialize-css'
import { Row, Col, Card } from 'react-materialize'

import { GlobalContext } from '../../context/GlobalState';

export const GestioneEnteAnagrafica = ({dettaglio, redirect, match}) => {
    // const debug = process.env.REACT_APP_DEBUG === "true"

    
    const { datiEnte } = useContext(GlobalContext);

    const codiceEnte = datiEnte?.idDominio

    if (codiceEnte && dettaglio) {   
        let url = '/gestione_ente/anagrafica'
        redirect(url);
    }


    return (
        <>
            <h4 style={{marginTop: 0}}>Anagrafica Ente</h4>
            <Card>
                <Row style={{marginBottom: '5px'}}>
                    <Col>
                        <b>idDominio:</b> {codiceEnte}
                    </Col>
                </Row>
                <Row style={{marginBottom: '5px'}}>
                    <Col>
                        <b>indirizzo:</b> {datiEnte.indirizzo}
                    </Col>
                </Row>
                <Row style={{marginBottom: '5px'}}>
                    <Col>
                        <b>civico:</b> {datiEnte.civico}
                    </Col>
                </Row>
                <Row style={{marginBottom: '5px'}}>
                    <Col>
                        <b>CAP:</b> {datiEnte.cap}
                    </Col>
                </Row>
                <Row style={{marginBottom: '5px'}}>
                    <Col>
                        <b>località:</b> {datiEnte.localita}
                    </Col>
                </Row>
                <Row style={{marginBottom: '5px'}}>
                    <Col>
                        <b>provincia:</b> {datiEnte.provincia}
                    </Col>
                </Row>
                <Row style={{marginBottom: '5px'}}>
                    <Col>
                        <b>nazione:</b> {datiEnte.nazione}
                    </Col>
                </Row>
                <Row style={{marginBottom: '5px'}}>
                    <Col>
                        <b>email:</b> {datiEnte.email}
                    </Col>
                </Row>
                <Row style={{marginBottom: '5px'}}>
                    <Col>
                        <b>pec:</b> {datiEnte.pec}
                    </Col>
                </Row>
                <Row style={{marginBottom: '5px'}}>
                    <Col>
                        <b>web:</b> {datiEnte.web}
                    </Col>
                </Row>
                <Row style={{marginBottom: '5px'}}>
                    <Col>
                        <b>GLN:</b> {datiEnte.gln}
                    </Col>
                </Row>
                <Row style={{marginBottom: '5px'}}>
                    <Col>
                        <b>CBILL:</b> {datiEnte.cbill}
                    </Col>
                </Row>
                <Row style={{marginBottom: '5px'}}>
                    <Col>
                        <b>stazione:</b> {datiEnte.stazione}
                    </Col>
                </Row>
                <Row style={{marginBottom: '5px'}}>
                    <Col>
                        <b>aux digit:</b> {datiEnte.auxDigit}
                    </Col>
                </Row>
                <Row style={{marginBottom: '5px'}}>
                    <Col>
                        <b>autorizzazione stampa Poste Italiane:</b> {datiEnte.autStampaPosteItaliane}
                    </Col>
                </Row>
                <Row style={{marginBottom: '5px'}}>
                    <Col>
                        <b>abilitato:</b> {datiEnte.abilitato ? "SI" : "NO"}
                    </Col>
                </Row>
            </Card>
        </>
    );
}