import React, { useContext, useState } from 'react'
import 'materialize-css'
import { Row, Col, TextInput, Select, Button, Icon, Card } from 'react-materialize'
// custom hooks
import { useGeneraIdPendenza } from "../components/hooks/useGeneraIdPendenza"
import { useGetNomeServizio } from "../components/hooks/useGetNomeServizio"
import { useGetAnni } from "../components/hooks/useGetAnni"
import { useSigleNazioni } from "../components/hooks/useSigleNazioni"

import { GlobalContext } from '../context/GlobalState';

export const FormPagamentoDirittoFisso = (props) => {
    // const debug = process.env.REACT_APP_DEBUG === "true"
    const { 
        provincia, 
        ente, 
        servizio, 
        setNuovaPendenza, 
        carrello, 
        aggiornaPendenzaCarrello 
    } = useContext(GlobalContext)

    const getNomeServizio = useGetNomeServizio()
    const generaIdPendenza = useGeneraIdPendenza()
    const [ anno, anni ] = useGetAnni()
    const sigleNazioni = useSigleNazioni()

    const formIniziale = {
        idDominio: ente,
        codEntrata: servizio,
        tipo: "G",
        identificativo: "",
        cognome: "",
        nome: "",
        indirizzo: "",
        civico: "",
        cap: "",
        localita: "",
        provincia: "",
        nazione: "IT",
        causale: "",
        importo: "1.55",
        periodoRiferimento: "I QUADRIMESTRE",
        annoRiferimento: anno
    }

    const quotaDirittoFisso = 1.55


    const getFormData = () => {
        if (props?.modifica && carrello[props?.modifica] !== undefined) {
            return carrello[props?.modifica].datiForm
        } else {
            return formIniziale
        }
    }

    const [datiForm, setDatiForm] = useState(getFormData());
    const [numeroVeicoli, setNumeroVeicoli] = useState(1);

    const handleChange = (e) => {
        setDatiForm({
            ...datiForm,
            [e.target.id]: e.target.value
        });
    }

    const changeNumeroVeicoli = (e) => {
        let value = e.target.value
        let importo = (quotaDirittoFisso * value).toFixed(2)
        setNumeroVeicoli(value)
        setDatiForm({
            ...datiForm,
            importo
        });
    }
    

    const checkForm = () => {
        let valid = true

        if (!provincia.length || !ente.length || !servizio.length) valid = false
        if (datiForm.importo < 0.01) valid = false
        if (datiForm.identificativo.length !== 11) valid = false
        if (datiForm.cognome.length < 1) valid = false
        if (datiForm.civico.length && !datiForm.indirizzo.length) valid = false
        if (datiForm.provincia !== "" && datiForm.provincia.length !== 2) valid = false
        if (datiForm.cap !== "" && datiForm.cap.length !== 5) valid = false
        if (datiForm.nazione !== "" && datiForm.nazione.length !== 2) valid = false

        return valid
    }


    const submitForm = (e) => {
        e.preventDefault();

        let idA2A = "CPP"
        let idDominio = ente
        let idPendenza = generaIdPendenza('', false)
        let descrizione = getNomeServizio()
        let codEntrata = servizio
        let importo = Number(Number(datiForm.importo).toFixed(2))

        let soggettoPagatore = {
            tipo: datiForm.tipo,
            identificativo: datiForm.identificativo,
            anagrafica: datiForm.cognome.trim(),
        }

        if (datiForm.indirizzo.length) soggettoPagatore['indirizzo'] = datiForm.indirizzo
        if (datiForm.civico.length) soggettoPagatore['civico'] = datiForm.civico
        if (datiForm.cap.length) soggettoPagatore['cap'] = datiForm.cap
        if (datiForm.localita.length) soggettoPagatore['localita'] = datiForm.localita
        if (datiForm.provincia.length) soggettoPagatore['provincia'] = datiForm.provincia
        if (datiForm.nazione.length) soggettoPagatore['nazione'] = datiForm.nazione

        let datiPendenza = {
            idA2A: idA2A,
            idPendenza: idPendenza,
            idDominio: idDominio,
            causale: componiCausale(),
            soggettoPagatore: soggettoPagatore,
            importo: importo,
            annoRiferimento: datiForm.annoRiferimento,
            voci: [
                {
                    idVocePendenza: idPendenza,
                    importo: importo,
                    descrizione: descrizione,
                    codEntrata: codEntrata
                }
            ]
        }

        let pendenza = { datiForm, datiPendenza }

        
        if (props?.onSubmit) {
            setNuovaPendenza(pendenza)
            props.onSubmit()
        }
    }


    const componiCausale = () => {
        let causale = "DIRITTO FISSO - " + datiForm.periodoRiferimento + " - " + datiForm.annoRiferimento

        return causale
    }


    const aggiornaPendenza = (e) => {
        e.preventDefault();

        let pendenzaDaModificare = carrello[props.modifica]
        let importo = Number(Number(datiForm.importo).toFixed(2))

        let soggettoPagatore = {
            tipo: datiForm.tipo,
            identificativo: datiForm.identificativo,
            anagrafica: (datiForm.nome + " " + datiForm.cognome).trim(),
        }

        if (datiForm.indirizzo.length) soggettoPagatore['indirizzo'] = datiForm.indirizzo
        if (datiForm.civico.length) soggettoPagatore['civico'] = datiForm.civico
        if (datiForm.cap.length) soggettoPagatore['cap'] = datiForm.cap
        if (datiForm.localita.length) soggettoPagatore['localita'] = datiForm.localita
        if (datiForm.provincia.length) soggettoPagatore['provincia'] = datiForm.provincia
        if (datiForm.nazione.length) soggettoPagatore['nazione'] = datiForm.nazione

        datiForm.causale = componiCausale()
        pendenzaDaModificare.datiForm = datiForm
        pendenzaDaModificare.datiPendenza.causale = datiForm.causale
        pendenzaDaModificare.datiPendenza.importo = importo
        pendenzaDaModificare.datiPendenza.annoRiferimento = datiForm.annoRiferimento
        pendenzaDaModificare.datiPendenza.soggettoPagatore = soggettoPagatore
        pendenzaDaModificare.datiPendenza.voci[0].importo = importo

        let payload = {
            index: props.modifica,
            pendenza: pendenzaDaModificare
        }

        
        if (props?.onUpdate) {
            aggiornaPendenzaCarrello(payload)
            props.onUpdate()
        }
    }


    return (
        <>
            <Card title="Dati del pagamento">
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Numero veicoli acquisiti (*)" id="numeroVeicoli" type="number" value={numeroVeicoli.toString()} onChange={changeNumeroVeicoli} step="1" min="1" validate required />
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Euro (*)" id="importo" onChange={handleChange} value={datiForm.importo} type="number" step="0.01" min="0.01" disabled validate required />
                    </Col>
                    <Col s={12} l={6}>
                        <Select
                            s={12}
                            label="Periodo di Riferimento (*)"
                            id="periodoRiferimento"
                            multiple={false}
                            onChange={ handleChange }
                            options={{
                                classes: '',
                                dropdownOptions: {
                                    alignment: 'left',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: false,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250
                                }
                            }}
                            value={ datiForm.periodoRiferimento }
                            validate
                            required
                        >
                            <option value="I QUADRIMESTRE" key="I QUADRIMESTRE">I QUADRIMESTRE</option>
                            <option value="II QUADRIMESTRE" key="II QUADRIMESTRE">II QUADRIMESTRE</option>
                            <option value="III QUADRIMESTRE" key="III QUADRIMESTRE">III QUADRIMESTRE</option>
                        </Select>
                    </Col>                    
                    <Col s={12} l={6}>
                        <Select
                            s={12}
                            label="Anno di Riferimento (*)"
                            id="annoRiferimento"
                            multiple={false}
                            onChange={ handleChange }
                            options={{
                                classes: '',
                                dropdownOptions: {
                                    alignment: 'left',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: false,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250
                                }
                            }}
                            value={ datiForm.annoRiferimento }
                            validate
                            required
                        >
                            { anni.map((a) => (<option value={a} key={a}>{a}</option>)) }
                        </Select>
                    </Col>
                </Row>
                <Row>
                    <Col s={12}>
                        <TextInput s={12} label="Causale del Servizio (*)" id="causale" onChange={handleChange} value={componiCausale()} readOnly disabled />                        
                    </Col>
                </Row>
                <p>(*) CAMPI OBBLIGATORI</p>
            </Card>
            <Card title="Dati soggetto pagatore">
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Codice Fiscale / Partita IVA (*)" id="identificativo" onChange={handleChange} value={datiForm.identificativo} minLength="11" maxLength="11" validate required  />
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Ragione Sociale (*)" id="cognome" onChange={handleChange} value={datiForm.cognome} validate required />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Indirizzo" id="indirizzo" onChange={handleChange} value={datiForm.indirizzo} />
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Numero Civico (*)" id="civico" onChange={handleChange} value={datiForm.civico} validate required />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Località (*)" id="localita" onChange={handleChange} value={datiForm.localita} validate required />
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Sigla Provincia (*)" id="provincia" onChange={handleChange} value={datiForm.provincia} maxLength="2" validate required />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="CAP (*)" id="cap" onChange={handleChange} value={datiForm.cap} maxLength="5" validate required />
                    </Col>
                    <Col s={12} l={6}>
                        <Select
                            s={12}
                            label="Nazione (*)"
                            id="nazione"
                            multiple={false}
                            onChange={ handleChange }
                            options={{
                                classes: '',
                                dropdownOptions: {
                                    alignment: 'left',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: false,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250
                                }
                            }}
                            value={ datiForm.nazione }
                            validate
                            required
                        >
                            { sigleNazioni.map((a) => (<option value={a.sigla} key={a.sigla}>{a.nome}</option>)) }
                        </Select>
                    </Col>
                </Row>
                <p>(*) CAMPI OBBLIGATORI</p>
            </Card>
            <Row>
                <Col s={12}>
                    {
                        props?.modifica && carrello[props?.modifica] !== undefined ?
                        <Button type="submit" node="button" waves="light" style={{width:'100%'}} large onClick={aggiornaPendenza}>
                            AGGIORNA PENDENZA
                            <Icon right>send</Icon>
                        </Button> :
                        <Button type="submit" node="button" waves="light" style={{width:'100%'}} large onClick={submitForm} disabled={!checkForm()}>
                            PROCEDI
                            <Icon right>send</Icon>
                        </Button>
                    }
                </Col>
            </Row>
        </>
    )
}
