import React, { useContext } from 'react'
import 'materialize-css'
import { Select } from 'react-materialize'

import { GlobalContext } from '../context/GlobalState';

export const SelezionaGestioneEnte = ({onChange, elencoEnti, enteSelezionato}) => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const { ente, setEnte } = useContext(GlobalContext);
    
    if (debug) console.log('ELENCO ENTI', elencoEnti)

    const handleChange = (value) => {
        if (ente !== value) setEnte(value)
        if (onChange) onChange(value)
    }


    return (
        <Select
            s={12} 
            m={12}
            id="SelezionaEnte"
            multiple={false}
            onChange={ e => {handleChange(e.target.value)} }
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: false,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }}
            value={ enteSelezionato }
        >
            <option
                value=""
            >
                SELEZIONA UN ENTE DA AMMINISTRARE
            </option>
            {elencoEnti.map((item) => (<option key={item.idDominio} value={item.idDominio}>{item.ragioneSociale}</option>))}
        </Select>
    )
}
