import React, {useContext} from 'react'
import 'materialize-css'
import { Row, Col, Card, Button } from 'react-materialize'

import { useScaricaRicevuta } from '../hooks/useScaricaRicevuta'

import { GlobalContext } from '../../context/GlobalState';


export const GestioneCittadinoPagamentiDetail = ({ pagamento, redirect }) => {
    // const debug = process.env.REACT_APP_DEBUG === "true"
    const { cittadino } = useContext(GlobalContext);

    const scaricaRicevuta = useScaricaRicevuta(cittadino.cf)


    return (
        <>
            <Row>
                <Col>
                    <Button onClick={() => redirect('/gestione_cittadino/pagamenti')}>indietro</Button>
                </Col>
                <Col>
                    <h4 style={{marginTop: 0}}>Dettaglio Pagamento</h4>
                </Col>
            </Row>
            <Card>
                <h5 style={{marginTop: 0}}>{pagamento.nome} - €{pagamento.importo}</h5>
                <Row style={{marginBottom: 0}}>
                    <Col>
                        <b>ID:</b> {pagamento.id}
                    </Col>
                </Row>
                <Row style={{marginBottom: 0}}>
                    <Col>
                        <b>Stato:</b> {pagamento.stato}
                    </Col>
                </Row>
                <Row style={{marginBottom: 0}}>
                    <Col>
                        <b>Modello:</b> {pagamento.modello}
                    </Col>
                </Row>
                <Row style={{marginBottom: 0}}>
                    <Col>
                        <b>Data di pagamento:</b> {pagamento.rpp[0].pendenza.dataPagamento}
                    </Col>
                </Row>
            </Card>
            {
                pagamento.rpp.map(
                    (item, i) => (
                        <Card key={i}> 
                            <Row style={{marginBottom: 0}}>
                                <Col>
                                    <b>ID Pendenza:</b> {item.pendenza.idPendenza}
                                </Col>
                            </Row>
                            <Row style={{marginBottom: 0}}>
                                <Col>
                                    <b>Identificativo Univoco Versamento (IUV):</b> {item.pendenza.iuvPagamento}
                                </Col>
                            </Row>                               
                            <Row style={{marginBottom: 0}}>
                                <Col>
                                    <b>Beneficiario:</b> {item.pendenza.dominio.ragioneSociale}
                                </Col>
                            </Row>
                            <Row style={{marginBottom: 0}}>
                                <Col>
                                    <b>Causale:</b> {item.pendenza.causale}
                                </Col>
                            </Row>
                            <Row style={{marginBottom: 0}}>
                                <Col>
                                    <b>Servizio:</b> {item.pendenza.tipoPendenza.descrizione}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <b>Importo:</b> €{item.pendenza.importo}
                                </Col>
                            </Row>
                            <Row style={{marginBottom: 0}}>
                                <Col>
                                    <Button disabled={pagamento.stato !== 'ESEGUITO' || item.rt.datiPagamento.CodiceContestoPagamento === "n/a"} onClick={() => {scaricaRicevuta(item?.pendenza?.dominio?.idDominio, item?.pendenza?.iuvPagamento, item?.rt?.datiPagamento?.CodiceContestoPagamento)}}>SCARICA RICEVUTA PAGAMENTO</Button>
                                </Col>
                            </Row>
                        </Card>
                    )
                )
            }
        </>
    );
}