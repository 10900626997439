export const useScaricaAvviso = (richiedente) => {
    const debug = process.env.REACT_APP_DEBUG === "true"

    const scaricaAvviso = (idDominio, numeroAvviso) => {
        let url = process.env.REACT_APP_SERVER_URL + 'avvisi/' + idDominio + '/' + numeroAvviso + '?richiedente=' + richiedente
        if (debug) console.log(url)
        fetch(url, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/text'
            }
        })
            .then(res => res.text())
            .then(res => {
                if (debug) console.log(res)
                window.open(process.env.REACT_APP_SERVER_URL + 'pdf_avvisi/' + res)
            })
    }


    return scaricaAvviso
    
}