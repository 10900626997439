import React, { useContext, useState } from 'react'
import 'materialize-css'
import { Row, Col, TextInput, Select, Button, Icon, Card } from 'react-materialize'
// custom hooks
import { useGeneraIdPendenza } from "../components/hooks/useGeneraIdPendenza"
import { useGetNomeServizio } from "../components/hooks/useGetNomeServizio"
import { useGetAnni } from "../components/hooks/useGetAnni"
// import { useTokenCittadino } from "../components/hooks/useTokenCittadino"

import { GlobalContext } from '../context/GlobalState';

export const FormPagamentoArisgan = (props) => {
    // const debug = process.env.REACT_APP_DEBUG === "true"
    // const {token} = useTokenCittadino()
    const getNomeServizio = useGetNomeServizio()
    const generaIdPendenza = useGeneraIdPendenza()
    const [ anno, anni ] = useGetAnni()

    const { 
        provincia, 
        ente, 
        servizio, 
        setNuovaPendenza, 
        carrello, 
        aggiornaPendenzaCarrello 
    } = useContext(GlobalContext)


    const formIniziale = {
        idDominio: ente,
        codEntrata: servizio,
        tipo: "G",
        identificativo: "",
        cognome: "",
        nome: "",
        indirizzo: "",
        civico: "",
        cap: "",
        localita: "",
        provincia: "",
        nazione: "",
        causale: "",
        importo: "",
        provinciaPagamento: "L'AQUILA",
        meseRiferimento: "GENNAIO",
        codiceDitta: "",
        annoRiferimento: anno
    }

    const tipoPagamento = {
        'RA_ARISGAN_AVVISO_ACCERTAMENTO': 'ARISGAN - Avviso Accertamento',
        'RA_ARISGAN_DEPOSITO_CAUZIONALE': 'ARISGAN - Deposito Cauzionale',
        'RA_ARISGAN_RATEO_MENSILE': 'ARISGAN - Versamento Ordinario',
        'RA_ARISGAN_RAVVEDIMENTO_OPEROSO': 'ARISGAN - Ravvedimento Operoso',
        'RA_ARISGAN_SALDO_ANNO_PRECEDENTE': 'ARISGAN - Pagamento Saldo a Debito Anno Precedente'
    }

    const getFormData = () => {
        if (props?.modifica && carrello[props?.modifica] !== undefined) {
            return carrello[props?.modifica].datiForm
        } else {
            return formIniziale
        }
    }

    const [datiForm, setDatiForm] = useState(getFormData());

    const handleChange = (e) => {
        setDatiForm({
            ...datiForm,
            [e.target.id]: e.target.value
        });
    }


    const checkForm = () => {
        let valid = true

        if (!provincia.length || !ente.length || !servizio.length) valid = false
        if (datiForm.importo < 0.01) valid = false
        if (datiForm.identificativo.length !== 11) valid = false
        if (datiForm.codiceDitta.length !== 9) valid = false
        if (datiForm.cognome.length < 1) valid = false

        return valid
    }


    const submitForm = (e) => {
        e.preventDefault();

        let idA2A = "CPP"
        let idDominio = ente
        let idPendenza = generaIdPendenza('arisgan', false)
        let descrizione = getNomeServizio()
        let codEntrata = servizio
        let importo = Number(Number(datiForm.importo).toFixed(2))

        let soggettoPagatore = {
            tipo: datiForm.tipo,
            identificativo: datiForm.identificativo,
            anagrafica: datiForm.cognome.trim(),
        }

        if (datiForm.indirizzo.length) soggettoPagatore['indirizzo'] = datiForm.indirizzo
        if (datiForm.civico.length) soggettoPagatore['civico'] = datiForm.civico
        if (datiForm.cap.length) soggettoPagatore['cap'] = datiForm.cap
        if (datiForm.localita.length) soggettoPagatore['localita'] = datiForm.localita
        if (datiForm.provincia.length) soggettoPagatore['provincia'] = datiForm.provincia
        if (datiForm.nazione.length) soggettoPagatore['nazione'] = datiForm.nazione

        let datiPendenza = {
            idA2A: idA2A,
            idPendenza: idPendenza,
            idDominio: idDominio,
            causale: componiCausale(),
            soggettoPagatore: soggettoPagatore,
            importo: importo,
            annoRiferimento: datiForm.annoRiferimento,
            voci: [
                {
                    idVocePendenza: idPendenza,
                    importo: importo,
                    descrizione: descrizione,
                    codEntrata: codEntrata
                }
            ]
        }

        let pendenza = { datiForm, datiPendenza }

        
        if (props?.onSubmit) {
            setNuovaPendenza(pendenza)
            props.onSubmit()
        }
    }


    const componiCausale = () => {
        let tipoSplit = tipoPagamento[servizio].split('-')
        let tipoA = tipoSplit[0].trim()
        let tipoB = tipoSplit[1].trim()
        let causale = `${tipoA} - ${datiForm.codiceDitta} - ${tipoB} - ${datiForm.provinciaPagamento} - ${datiForm.meseRiferimento} ${datiForm.annoRiferimento}` 

        return causale
    }


    const aggiornaPendenza = (e) => {
        e.preventDefault();

        let pendenzaDaModificare = carrello[props.modifica]
        let importo = Number(Number(datiForm.importo).toFixed(2))

        let soggettoPagatore = {
            tipo: datiForm.tipo,
            identificativo: datiForm.identificativo,
            anagrafica: (datiForm.nome + " " + datiForm.cognome).trim(),
        }

        if (datiForm.indirizzo.length) soggettoPagatore['indirizzo'] = datiForm.indirizzo
        if (datiForm.civico.length) soggettoPagatore['civico'] = datiForm.civico
        if (datiForm.cap.length) soggettoPagatore['cap'] = datiForm.cap
        if (datiForm.localita.length) soggettoPagatore['localita'] = datiForm.localita
        if (datiForm.provincia.length) soggettoPagatore['provincia'] = datiForm.provincia
        if (datiForm.nazione.length) soggettoPagatore['nazione'] = datiForm.nazione

        datiForm.causale = componiCausale()
        pendenzaDaModificare.datiForm = datiForm
        pendenzaDaModificare.datiPendenza.causale = datiForm.causale
        pendenzaDaModificare.datiPendenza.importo = importo
        pendenzaDaModificare.datiPendenza.annoRiferimento = datiForm.annoRiferimento
        pendenzaDaModificare.datiPendenza.soggettoPagatore = soggettoPagatore
        pendenzaDaModificare.datiPendenza.voci[0].importo = importo

        let payload = {
            index: props.modifica,
            pendenza: pendenzaDaModificare
        }

        
        if (props?.onUpdate) {
            aggiornaPendenzaCarrello(payload)
            props.onUpdate()
        }
    }


    return (
        <>
            <Card title="Dati del pagamento">
                <Row>
                    <Col s={12} l={4}>
                        <TextInput s={12} label="Tipologia di pagamento" id="tipoPagamento" value={tipoPagamento[servizio]} type="text" disabled required />
                    </Col>
                    <Col s={12} l={4}>
                        <TextInput s={12} label="Codice Ditta (*)" id="codiceDitta" onChange={handleChange} value={datiForm.codiceDitta} type="text" validate required minLength="9" maxLength="9" />
                    </Col>
                    <Col s={12} l={4}>
                        <TextInput s={12} label="Euro (*)" id="importo" onChange={handleChange} value={datiForm.importo} type="number" step="0.01" min="0.01" validate required />
                    </Col> 
                    <Col s={12} l={4}>
                        <Select
                            s={12}
                            label="Provincia (*)"
                            id="provinciaPagamento"
                            multiple={false}
                            onChange={ handleChange }
                            options={{
                                classes: '',
                                dropdownOptions: {
                                    alignment: 'left',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: false,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250
                                }
                            }}
                            value={ datiForm.provinciaPagamento }
                            validate
                            required
                        >
                            <option value="L'AQUILA" key="L'AQUILA">L'AQUILA</option>
                            <option value="CHIETI" key="CHIETI">CHIETI</option>
                            <option value="PESCARA" key="PESCARA">PESCARA</option>
                            <option value="TERAMO" key="TERAMO">TERAMO</option>
                        </Select>
                    </Col> 
                    <Col s={12} l={4}>
                        <Select
                            s={12}
                            label="Mese di Riferimento (*)"
                            id="meseRiferimento"
                            multiple={false}
                            onChange={ handleChange }
                            options={{
                                classes: '',
                                dropdownOptions: {
                                    alignment: 'left',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: false,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250
                                }
                            }}
                            value={ datiForm.meseRiferimento }
                            validate
                            required
                        >
                            <option value="GENNAIO" key="GENNAIO">GENNAIO</option>
                            <option value="FEBBRAIO" key="FEBBRAIO">FEBBRAIO</option>
                            <option value="MARZO" key="MARZO">MARZO</option>
                            <option value="APRILE" key="APRILE">APRILE</option>
                            <option value="MAGGIO" key="MAGGIO">MAGGIO</option>
                            <option value="GIUGNO" key="GIUGNO">GIUGNO</option>
                            <option value="LUGLIO" key="LUGLIO">LUGLIO</option>
                            <option value="AGOSTO" key="AGOSTO">AGOSTO</option>
                            <option value="SETTEMBRE" key="SETTEMBRE">SETTEMBRE</option>
                            <option value="OTTOBRE" key="OTTOBRE">OTTOBRE</option>
                            <option value="NOVEMBRE" key="NOVEMBRE">NOVEMBRE</option>
                            <option value="DICEMBRE" key="DICEMBRE">DICEMBRE</option>
                        </Select>
                    </Col>                    
                    <Col s={12} l={4}>
                        <Select
                            s={12}
                            label="Anno di Riferimento (*)"
                            id="annoRiferimento"
                            multiple={false}
                            onChange={ handleChange }
                            options={{
                                classes: '',
                                dropdownOptions: {
                                    alignment: 'left',
                                    autoTrigger: true,
                                    closeOnClick: true,
                                    constrainWidth: false,
                                    coverTrigger: true,
                                    hover: false,
                                    inDuration: 150,
                                    onCloseEnd: null,
                                    onCloseStart: null,
                                    onOpenEnd: null,
                                    onOpenStart: null,
                                    outDuration: 250
                                }
                            }}
                            value={ datiForm.annoRiferimento }
                            validate
                            required
                        >
                            { anni.map((a) => (<option value={a} key={a}>{a}</option>)) }
                        </Select>
                    </Col>
                </Row>
                <Row>
                    <Col s={12}>
                        <TextInput s={12} label="Causale del Servizio (*)" id="causale" onChange={handleChange} value={componiCausale()} readOnly disabled />                        
                    </Col>
                </Row>
                <p>(*) CAMPI OBBLIGATORI</p>
            </Card>
            <Card title="Dati soggetto pagatore">
                <Row>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Codice Fiscale / Partita IVA (*)" id="identificativo" onChange={handleChange} value={datiForm.identificativo} minLength="11" maxLength="11" validate required  />
                    </Col>
                    <Col s={12} l={6}>
                        <TextInput s={12} label="Ragione Sociale (*)" id="cognome" onChange={handleChange} value={datiForm.cognome} validate required />
                    </Col>
                </Row>
                <p>(*) CAMPI OBBLIGATORI</p>
            </Card>
            <Row>
                <Col s={12}>
                    {
                        props?.modifica && carrello[props?.modifica] !== undefined ?
                        <Button type="submit" node="button" waves="light" style={{width:'100%'}} large onClick={aggiornaPendenza}>
                            AGGIORNA PENDENZA
                            <Icon right>send</Icon>
                        </Button> :
                        <Button type="submit" node="button" waves="light" style={{width:'100%'}} large onClick={submitForm} disabled={!checkForm()}>
                            PROCEDI
                            <Icon right>send</Icon>
                        </Button>
                    }
                </Col>
            </Row>
        </>
    )
}
