import React from 'react'
import 'materialize-css'
import { Select } from 'react-materialize'

export const SelezionaPaginaEnte = ({sezione, navigaVerso, onChange, allowedSections}) => {
    // const debug = process.env.REACT_APP_DEBUG === "true"

    const vaiAllaSezione = (sezione) => {
        let url = ""
        url = '/gestione_ente/' + sezione
        navigaVerso(url);
    }

    const nomeSezione = {
        anagrafica: 'Anagrafica Ente',
        contiaccredito: 'Conti di accredito',
        tipipendenza: 'Tipi pendenza',
        entrate: 'Entrate',
        pagamenti: 'Pagamenti ricevuti',
        pendenze: 'Pendenze caricate',
        inserisci_pendenza: 'Inserimento pendenza singola',
        flussi_pendenze: 'Flussi massivi pendenze',
        riconciliazioni: 'Riconciliazioni',
        rendicontazioni: 'Rendicontazioni',
        scarica_flusso_rendicontazioni: 'Scarica Flusso Rendicontazioni',
        converti_flusso: 'Converti flusso ridotto in flusso completo',
        riscossioni: 'Riscossioni',
        nuovo_ticket: 'Nuovo Ticket',
        storico_ticket: 'Storico Ticket',
    }

    return (
        <Select
            s={12} 
            m={12}
            id="SelezionaProvincia"
            multiple={false}
            onChange={ e => { vaiAllaSezione(e.target.value); if(onChange) onChange(e.target.value) } }
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: false,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }}
            value={ sezione }
        >
            {
                allowedSections.map(
                    (section, i) => (
                        <option value={section} key={i}>{nomeSezione[section]}</option>
                    )
                )
            }
        </Select>
    )
}
