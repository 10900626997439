import unitaOperativePredefinite from '../../json/unita_operative_predefinite.json'


export const useGetUnitaOperativaPredefinita = () => {
    const debug = process.env.REACT_APP_DEBUG === "true"

    const getUnitaOperativaPredefinita = (ente, servizio) => {
        let enti = Object.keys(unitaOperativePredefinite)

        if (enti.indexOf(ente) !== -1) {
            let servizi = unitaOperativePredefinite[ente]
            let unitaPredefinita = servizi[servizio]

            return unitaPredefinita ?? ''
        } 

        return ''
    }

    return getUnitaOperativaPredefinita
}