import React, { useContext, useState, useEffect } from 'react'
import 'materialize-css'
import { Row, Col, Card, Button, Pagination, Icon } from 'react-materialize'

import { LoadingSmall } from '../loadingSmall';
import { LoadingFull } from '../loadingFull';

import { GlobalContext } from '../../context/GlobalState';

import { useTokenCittadino } from "../hooks/useTokenCittadino"
import { useScaricaRicevuta } from '../hooks/useScaricaRicevuta';

import { CardFiltriEnte } from '../CardFiltriEnte'


export const GestioneEntePagamenti = ({dettaglio, redirect, match}) => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const { token } = useTokenCittadino()
    const { datiEnte, cittadino } = useContext(GlobalContext);
    const [ pagamenti, setPagamenti ] = useState(null);
    const [ pagamento, setPagamento ] = useState(null);
    const [ filter, setFilter ] = useState(null);
    const [ codiceEnte, setCodiceEnte ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    
    const scaricaRicevuta = useScaricaRicevuta(cittadino.cf)

    if (!codiceEnte) {
        setPagamenti(null)
        setCodiceEnte(datiEnte?.idDominio)
    } else if (codiceEnte !== datiEnte?.idDominio) {
        setCodiceEnte(null)
    }


    const getPagamenti = async (filterData, page) => {
        setLoading(true)
        let response = await fetch(process.env.REACT_APP_SERVER_URL + 'ente/pagamenti/' + codiceEnte, {
            method: 'POST',
            body: JSON.stringify({token, filter: filterData, page, richiedente: cittadino.cf})
        })

        let res = await response.json()

        if (debug) console.log(res)

        if (res.valid) {
            if (pagamenti !== res.result) setPagamenti(res.result)
            setLoading(false)
        } else {
            redirect("/")
        }        
    }


    const getDettaglioPagamento = async () => {
        if (pagamento?.id !== dettaglio) {
            let response = await fetch(process.env.REACT_APP_SERVER_URL + 'ente/pagamenti/' + codiceEnte + '/' + dettaglio, {
                method: 'POST',
                body: JSON.stringify({token, richiedente: cittadino.cf})
            })

            let res = await response.json()

            if (debug) console.log(res)
            if (res.valid) {
                setPagamento(res.result)
            } else {
                redirect("/")
            }        
        }
    }


    useEffect(() => {
        if (token) {
            if (dettaglio && pagamento?.id !== dettaglio) {
                getDettaglioPagamento()
            } else if (!dettaglio && !pagamenti) {
                getPagamenti(filter, 1)
            }
        } else {
            redirect("/")
        }
    }, [token, dettaglio, redirect, getPagamenti, getDettaglioPagamento])
 

    const visualizzaDettaglio = (idDettaglio) => {
        let url = '/gestione_ente/pagamenti/' + idDettaglio
        redirect(url)
    }

    
    const parseDateTime = (dateTime) => {
        let date = new Date(dateTime)

        let anno = date.getFullYear()
        let mese = date.getMonth() + 1
        let giorno = date.getDate()
        let ora = date.getHours()
        let minuti = date.getMinutes()
        let secondi = date.getSeconds()

        let result = giorno.toString().padStart(2, '0') + '/' + mese.toString().padStart(2, '0') + '/' + anno + ' - ' + ora.toString().padStart(2, '0') + ':' + minuti.toString().padStart(2, '0') + ':' + secondi.toString().padStart(2, '0')

        return result
    } 


    const filterSaved = (filterData) => {
        if (debug) console.log('filter saved', filterData)
        setFilter(filterData)
        getPagamenti(filterData, 1)
    } 




    return (
        <>
            { loading ? <LoadingFull /> : null }
            {
                dettaglio ?
                <>
                    <Row>
                        <Col>
                            <Button onClick={() => redirect('/gestione_ente/pagamenti')}>indietro</Button>
                        </Col>
                        <Col>
                            <h4 style={{marginTop: 0}}>Dettaglio Pagamento</h4>
                        </Col>
                    </Row>
                    {
                        pagamento ?
                        <>
                            <Card>
                                <h5 style={{marginTop: 0}}>{pagamento?.nome} - €{pagamento?.importo}</h5>
                                <Row style={{marginBottom: '5px'}}>
                                    <Col>
                                        <b>Identificativo richiesta:</b> {pagamento?.id}
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: '5px'}}>
                                    <Col>
                                        <b>Stato:</b> {pagamento?.stato}
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: '5px'}}>
                                    <Col>
                                        <b>Data richiesta:</b> {parseDateTime(pagamento?.dataRichiestaPagamento)}
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: '5px'}}>
                                    <Col>
                                        <b>CF Versante:</b> {pagamento?.soggettoVersante?.identificativo}
                                    </Col>
                                    <Col>
                                        <b>Nome Versante:</b> {pagamento?.soggettoVersante?.anagrafica}
                                    </Col>
                                </Row>
                            </Card> 
                            <h6>PENDENZE PAGATE</h6>
                            {
                                pagamento?.rpp?.map(
                                    (item) => (
                                        <Card key={item?.rpt?.identificativoMessaggioRichiesta}>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Servizio:</b> {item?.pendenza?.tipoPendenza?.descrizione}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Causale:</b> {item?.pendenza?.causale}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Importo:</b> {item?.pendenza?.importo}
                                                </Col>
                                            </Row>
                                            <h6>DEBITORE</h6>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Nominativo:</b> {item?.pendenza?.soggettoPagatore?.anagrafica}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>CF/PIVA:</b> {item?.pendenza?.soggettoPagatore?.identificativo}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Indirizzo:</b> {item?.pendenza?.soggettoPagatore?.indirizzo}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Civico:</b> {item?.pendenza?.soggettoPagatore?.civico}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>CAP:</b> {item?.pendenza?.soggettoPagatore?.cap}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Col>
                                                    <b>Località:</b> {item?.pendenza?.soggettoPagatore?.localita}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <b>Provincia:</b> {item?.pendenza?.soggettoPagatore?.provincia}
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: 0}}>
                                                <Col>
                                                    <Button disabled={pagamento.stato !== 'ESEGUITO' || item?.rt?.datiPagamento?.CodiceContestoPagamento === "n/a"} onClick={() => {scaricaRicevuta(item?.pendenza?.dominio?.idDominio, item?.pendenza?.iuvPagamento, item?.rt?.datiPagamento?.CodiceContestoPagamento)}}>SCARICA RICEVUTA PAGAMENTO</Button>
                                                </Col>
                                            </Row>
                                        </Card>
                                    )
                                )
                            }
                        </> :
                        <LoadingSmall />
                    }
                </> :
                <>
                    <Row>
                        <Col s={12}>
                            <h4 style={{marginTop: 0}}>Pagamenti</h4>
                            <p>Elenco delle operazioni di pagamento effettuate</p>
                            <CardFiltriEnte tipo="pagamenti" onFilterSaved={filterSaved} />
                            <h5>Pagamenti trovati: { pagamenti ? pagamenti?.numRisultati : "0" }</h5>
                        </Col>
                    </Row>
                    {
                        pagamenti ? 
                        <>
                            <Pagination
                                activePage={pagamenti.pagina}
                                items={pagamenti.numPagine}
                                leftBtn={<Icon>chevron_left</Icon>}
                                maxButtons={10}
                                rightBtn={<Icon>chevron_right</Icon>}
                                onSelect={(page) => getPagamenti(filter, page)}
                            />
                            {pagamenti?.risultati?.map(
                                (pagamento) => (<Card key={pagamento?.id}>
                                    <h5 style={{marginTop: 0}}>{pagamento?.nome} - €{pagamento?.importo}</h5>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <b>Identificativo richiesta:</b> {pagamento?.id}
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <b>Stato:</b> {pagamento?.stato}
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <b>Data richiesta:</b> {parseDateTime(pagamento?.dataRichiestaPagamento)}
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <b>CF Versante:</b> {pagamento?.soggettoVersante?.identificativo}
                                        </Col>
                                        <Col>
                                            <b>Nome Versante:</b> {pagamento?.soggettoVersante?.anagrafica}
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '5px'}}>
                                        <Col>
                                            <Button onClick={() => visualizzaDettaglio(pagamento?.id)}>DETTAGLI</Button>
                                        </Col>
                                    </Row>
                                </Card>)                    
                            )}
                        </> :
                        <LoadingSmall />
                    }
                </>
            }
        </> 
    );
}