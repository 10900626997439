import React, { useState, useEffect, useContext } from 'react'
import 'materialize-css'
import { Row, Col, Pagination, Icon } from 'react-materialize'

import { useTokenCittadino } from "../hooks/useTokenCittadino"

import { LoadingSmall } from '../loadingSmall';
import { LoadingFull } from '../loadingFull';

import { CardFiltriCittadino } from '../CardFiltriCittadino'
import { GestioneCittadinoPagamentiListItem } from './GestioneCittadinoPagamentiListItem'
import { GestioneCittadinoPagamentiDetail } from './GestioneCittadinoPagamentiDetail'

import { SelezionaDelegato } from "../SelezionaDelegato"

import { GlobalContext } from '../../context/GlobalState';


export const GestioneCittadinoPagamenti = ({redirect, dettaglio}) => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const { cittadino } = useContext(GlobalContext);

    const { token } = useTokenCittadino()
    const [ pagamenti, setPagamenti ] = useState(null);
    const [ pagamento, setPagamento ] = useState(null);
    const [ filter, setFilter ] = useState(null);
    const [ loading, setLoading ] = useState(false)
    const [ deleghe, setDeleghe ] = useState([])
    const [ delegaSelezionata, setDelegaSelezionata ] = useState("")


    if (!dettaglio && pagamento) {
        setPagamento(null)
    }

    
    const getPagamenti = (filterData, page) => {
        setLoading(true)
        if (debug) console.log("filterData", filterData)
        fetch(process.env.REACT_APP_SERVER_URL + 'cittadino/pagamenti', {
            method: 'POST',
            body: JSON.stringify({token, delegaSelezionata, filter: filterData, page, richiedente: cittadino.cf})
        })
            .then(res => res.json())
            .then(res => {
                if (debug) console.log(res)
                if (res.valid) {
                    if (pagamenti !== res.result) setPagamenti(res.result)
                    setLoading(false)
                } else {
                    redirect("/")
                }
            })        
    }


    const getDettaglioPagamento = () => {
        if (pagamento?.id !== dettaglio) {
            fetch(process.env.REACT_APP_SERVER_URL + 'cittadino/pagamenti/' + dettaglio, {
                method: 'POST',
                body: JSON.stringify({token, richiedente: cittadino.cf})
            })
                .then(res => res.json())
                .then(res => {
                    if (debug) console.log(res)
                    if (res.valid) {
                        setPagamento(res.result)
                    } else {
                        redirect("/")
                    }
                })         
        }
    }


    useEffect(() => {
        if (debug) console.log('USE EFFECT', dettaglio)
        if (token) {
            if (dettaglio) {
                getDettaglioPagamento()
            } else {
                getPagamenti(filter, 1)
            }
        } else {
            redirect("/")
        }
    }, [delegaSelezionata, dettaglio])


    useEffect(() => {
        let deleghe = []

        if (cittadino?.permessi) {
            deleghe = cittadino.permessi.filter(permesso => {
                                                                let d = permesso.RUOLI.filter(ruolo => ruolo.tipo === 'DelegatoStampa')
                                                                return d.length > 0
                                                            })
        }

        setDeleghe(deleghe)
    }, [cittadino])


    const filterSaved = (filterData) => {
        if (debug) console.log('filter saved', filterData)
        setFilter(filterData)
        getPagamenti(filterData, 1)
    } 


    const checkDelegato = () => {
        if (!cittadino?.permessi) {
            return false
        }

        let filter = cittadino.permessi.filter(permesso => {
                                                            let deleghe = permesso.RUOLI.filter(ruolo => ruolo.tipo === 'DelegatoStampa')
                                                            return deleghe.length > 0
                                                            })

        return filter.length > 0
    }


    const delegaChange = (event) => {
        console.log({event})
        setDelegaSelezionata(event)
    }


    return (
        <>
            { loading ? <LoadingFull /> : null }
            {
                dettaglio ?
                <>
                    {
                        pagamento ?
                        <GestioneCittadinoPagamentiDetail pagamento={ pagamento } redirect={ redirect } /> :
                        <LoadingSmall />
                    }
                </> :
                <>
                    <Row>
                        {
                            checkDelegato() ?
                            <Col s={12}>
                                <SelezionaDelegato deleghe={ deleghe } delegaSelezionata={ delegaSelezionata } onChange={ delegaChange } />
                            </Col> :
                            <></>
                        }
                        <Col s={12}>
                            <h4 style={{marginTop: 0}}>Pagamenti effettuati</h4>
                            <p>{ delegaSelezionata.length ? 'Elenco delle operazioni di pagamento effettuate per il delegante' : 'Elenco delle proprie operazioni di pagamento effettuate'}</p>
                            <CardFiltriCittadino tipo="pagamenti" onFilterSaved={filterSaved} />
                            <h5>Pagamenti trovati: { pagamenti ? pagamenti?.numRisultati : "0" }</h5>
                        </Col>
                    </Row>
                    {
                        pagamenti ? 
                        <>
                            <Pagination
                                activePage={pagamenti.pagina}
                                items={pagamenti.numPagine}
                                leftBtn={<Icon>chevron_left</Icon>}
                                maxButtons={10}
                                rightBtn={<Icon>chevron_right</Icon>}
                                onSelect={(page) => getPagamenti(filter, page)}
                            />
                            {pagamenti?.risultati?.map(
                                (item) => (<GestioneCittadinoPagamentiListItem key={ item.id } item={ item } redirect={ redirect } />)
                            )} 
                        </> :
                        <LoadingSmall />
                    }
                </>
            }
        </>
    );
}