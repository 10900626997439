import React, { useContext } from 'react'
import 'materialize-css'
import { Row, Col, Card, Button } from 'react-materialize'

import { GlobalContext } from '../../context/GlobalState';

export const GestioneEnteTipiPendenza = ({dettaglio, redirect, match}) => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const { datiEnte } = useContext(GlobalContext);

    const codiceEnte = datiEnte?.idDominio;

    if (codiceEnte && dettaglio) {   
        let url = '/gestione_ente/tipipendenza'
        redirect(url);
    }

    return (
        <>
            <h4 style={{marginTop: 0}}>Tipi di pendenza</h4>
            <p>Tipi Pendenza trovati: { datiEnte?.tipiPendenza ? datiEnte?.tipiPendenza?.length : "0" }</p>
            {
                datiEnte?.tipiPendenza?.map(
                    (item, i) => (<Card key={i}>
                        <Row style={{marginBottom: '5px'}}>
                            <Col>
                                <b>descrizione:</b>
                            </Col>
                            <Col>
                                {item.descrizione}
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '5px'}}>
                            <Col>
                                <b>idTipoPendenza:</b> {item.idTipoPendenza}
                            </Col>
                            <Col>
                                <b>pagabile da terzi:</b> {item.pagaTerzi ? "SI" : "NO"}
                            </Col>
                            <Col>
                                <b>abilitato:</b> {item.abilitato ? "SI" : "NO"}
                            </Col>
                        </Row>
                    </Card>)                
                )
            }
        </>
    );
}