import React, { useContext, useEffect } from 'react'
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import 'materialize-css'
import { Container, Card, Row, Col } from 'react-materialize'
import { HeaderCittadino } from '../components/HeaderCittadino'
import { SelezionaProvincia } from '../components/SelezionaProvincia'
import { SelezionaEnte } from '../components/SelezionaEnte'
import { SelezionaServizio } from '../components/SelezionaServizio'
import { FormPagamento } from '../components/FormPagamento'
import { FormPagamentoArisgan } from '../components/FormPagamentoArisgan'
import { FormPagamentoDPC032 } from '../components/FormPagamentoDPC032'
import { FormPagamentoDPG021 } from '../components/FormPagamentoDPG021'
import { FormPagamentoDPF003 } from '../components/FormPagamentoDPF003'
import { FormPagamentoDPE005 } from '../components/FormPagamentoDPE005'
import { FormPagamentoDPD023 } from '../components/FormPagamentoDPD023'
import { FormPagamentoDPC024_ScarichiIdrici } from '../components/FormPagamentoDPC024_ScarichiIdrici'
import { FormPagamentoDPD023fitosanitario } from '../components/FormPagamentoDPD023fitosanitario'
import { FormPagamentoDirittoFisso } from '../components/FormPagamentoDirittoFisso'
import { FormPagamentoAbilitazioneProfessionale } from '../components/FormPagamentoAbilitazioneProfessionale'
import { FormPagamentoEdiliziaSismica } from '../components/FormPagamentoEdiliziaSismica'
import { FormPagamentoEmissioneAtmosfera } from '../components/FormPagamentoEmissioneAtmosfera'
import { FormPagamentoTUA } from '../components/FormPagamentoTUA'
import { PageFooter } from "../components/PageFooter"
import { GlobalContext } from '../context/GlobalState'


export const PagamentoPage = () => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const match = useRouteMatch()
    const history = useHistory()
    const location = useLocation()

    const queryString = require('query-string')
    const parsed = queryString.parse(location.search)

    const { 
        strutturaSelettori,
        setStrutturaSelettori, 
        provincia, 
        ente, 
        servizio, 
        setProvincia, 
        setProvinciaEnte, 
        setProvinciaEnteServizio,
        carrello,
        cittadino
    } = useContext(GlobalContext);

    if (Object.keys(parsed).length && carrello[parsed?.modifica] === undefined) {
        history.push(location.pathname)
    }

    const provinciaIndex = match.params.provincia !== undefined ? match.params.provincia : ""
    const enteIndex = match.params.ente !== undefined ? match.params.ente : ""
    const servizioIndex = match.params.servizio !== undefined ? match.params.servizio : ""

    var params = {}

    
    if ((provinciaIndex !== provincia && provincia === "") && (enteIndex !== ente && ente === "") && (servizioIndex !== servizio && servizio === "")) {
        params = {
            provincia: provinciaIndex,
            ente: enteIndex,
            servizio: servizioIndex
        }

        setProvinciaEnteServizio(params);
    } else if ((provinciaIndex !== provincia && provincia === "") && (enteIndex !== ente && ente === "")) {
        params = {
            provincia: provinciaIndex,
            ente: enteIndex
        }

        setProvinciaEnte(params);
    } else if (provinciaIndex !== provincia && provincia === "") {
        setProvincia(provinciaIndex)
    }


    const checkModifica = () => {
        if (debug) console.log(parsed)
        if (parsed?.modifica) {
            return parsed.modifica
        } else {
            return null
        }
    }


    const confermaDati = () => {
        history.push('/pagamento/conferma_dati')
    }


    const inviaAggiornamento = () => {
        history.push('/carrello')
    }


    const getNomeProvincia = () => {
        return provincia !== null ? strutturaSelettori[provincia]?.nome : ""
    }


    const getNomeEnte = () => {
        return ente !== null ? strutturaSelettori[provincia].enti.filter(item => item.idDominio === ente)[0].ragioneSociale : ""
    }


    const getNomeServizio = () => {
        if (ente !== null && servizio !== null) {
            let enteObj = strutturaSelettori[provincia].enti.filter(item => item.idDominio === ente)[0]
            return enteObj.listaEntrate.filter(entrata => entrata.idEntrata === servizio)[0].tipoEntrata.descrizione
        } else {
            return ""
        }
    }


    const loadSelettori = () => {
        if (debug) console.log("strutturaSelettori PRE", strutturaSelettori)

        let richiedente = cittadino ? cittadino.cf : 'anonimo'
    
        fetch(process.env.REACT_APP_SERVER_URL + 'getStrutturaSelettori?richiedente=' + richiedente)
            .then(response => response.json())
            .then(data => {
                if (debug) console.log("strutturaSelettori POST", data)
                setStrutturaSelettori(data);
            })
    }


    const switchForm = (ente, servizio) => {
        if (servizio.indexOf("ARISGAN") !== -1) {
            return <FormPagamentoArisgan modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        }

        if (servizio.indexOf("_DPC032_") !== -1) {
            return <FormPagamentoDPC032 modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        }

        if (servizio.indexOf("_DPG021_") !== -1) {
            return <FormPagamentoDPG021 modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        }

        if (servizio.indexOf("_DPE005_") !== -1) {
            return <FormPagamentoDPE005 modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        }

        if (servizio === 'RA_FITO_DPD023_TAR_SANZ_FITO_SANIT') {
            return <FormPagamentoDPD023fitosanitario modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        }

        if (servizio.indexOf("_DPD023_") !== -1) {
            return <FormPagamentoDPD023 modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        }

        if (servizio === 'RA_CONCESSIONARI_AUTO_DIRITTO_FISSO') {
            return <FormPagamentoDirittoFisso modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        }

        if (servizio === 'RA_DSU_DPG021_001_TAS_ABI_ESER_PROF') {
            return <FormPagamentoAbilitazioneProfessionale modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        }

        if (servizio === 'PAREG_DIRITTI_SEGRETERIA_SISMICA') {
            return <FormPagamentoEdiliziaSismica modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        }

        if (servizio === 'RA_SI_DPC024_AUT_SCARICHI_IDRICI') {
            return <FormPagamentoDPC024_ScarichiIdrici modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        }
        
        if (servizio === 'RA_DPC025_006_EMISSIONI_ATMOSFERA') {
            return <FormPagamentoEmissioneAtmosfera modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        } 
        
        if (servizio === 'RA_SAF_DPF003_001_SERV_ASS_FARM') {
            return <FormPagamentoDPF003 modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        }
        
        if (ente === '00288240690') {
            return <FormPagamentoTUA modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        }


        // return <FormPagamentoArisgan modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        // return <FormPagamentoDPC032 modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        // return <FormPagamentoDPG021 modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        // return <FormPagamentoDPE005 modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        // return <FormPagamentoDPD023 modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        // return <FormPagamentoDPD023fitosanitario modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        // return <FormPagamentoDirittoFisso modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        // return <FormPagamentoAbilitazioneProfessionale modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        // return <FormPagamentoEdiliziaSismica modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        // return <FormPagamentoEmissioneAtmosfera modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        // return <FormPagamentoDPF003 modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        // return <FormPagamentoTUA modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        // return <FormPagamentoDPC024_ScarichiIdrici modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
        return <FormPagamento modifica={checkModifica()} onUpdate={inviaAggiornamento} onSubmit={confermaDati} />
    }


    useEffect(() => {
        if (strutturaSelettori == null) {
            loadSelettori()
        }
    }, [strutturaSelettori, loadSelettori])


    return (
        <Container>
            <HeaderCittadino history={history} />
            <Row>
                <Col s={12}>
                    <Card title="Dati del Servizio">
                        { provincia !== "" && provinciaIndex !== "" ? <h4>Provincia: {getNomeProvincia()}</h4> : <Row><Col s={12}><SelezionaProvincia /></Col></Row> }
                        { ente !== "" && enteIndex !== "" ? <h4>Ente: {getNomeEnte()}</h4> : <Row><Col s={12}><SelezionaEnte /></Col></Row> }
                        { servizio !== "" && servizioIndex !== "" ? <h4>Servizio: {getNomeServizio()}</h4> : <Row><Col s={12}><SelezionaServizio /></Col></Row> }
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col s={12}>
                    {
                        switchForm(ente, servizio)
                    }
                </Col>
            </Row>
            <PageFooter />
        </Container>
    )
}
