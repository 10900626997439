import React, { useContext } from 'react'
import 'materialize-css'
import { Row, Col, Card, Button } from 'react-materialize'

import { GlobalContext } from '../../context/GlobalState';

import { useScaricaAvviso } from '../hooks/useScaricaAvviso';
import { useScaricaRicevuta } from '../hooks/useScaricaRicevuta';


export const GestioneCittadinoPendenzeDetail = ({redirect, pendenza}) => {
    const debug = process.env.REACT_APP_DEBUG === "true"

    const { setNuovoAvviso, cittadino } = useContext(GlobalContext);

    const scaricaAvviso = useScaricaAvviso(cittadino.cf)
    const scaricaRicevuta = useScaricaRicevuta(cittadino.cf)

    const inviaAvviso = (idDominio, numeroAvviso, idDebitore) => {
        const avviso = {
            idDominio,
            numeroAvviso,
            idDebitore
        }

        setNuovoAvviso(avviso)
        redirect('/pagamento/verifica_avviso')
    }


    return (
        <>
            <Row>
                <Col>
                    <Button onClick={() => redirect('/gestione_cittadino/pendenze')}>indietro</Button>
                </Col>
                <Col>
                    <h4 style={{marginTop: 0}}>Dettaglio Pendenza</h4>
                </Col>
            </Row>
            <Card>
                <h5 style={{marginTop: 0}}>{pendenza.causale} - €{pendenza.importo}</h5>
                <Row style={{marginBottom: 0}}>
                    <Col>
                        <b>ID Pendenza:</b> {pendenza.idPendenza}
                    </Col>
                </Row>
                {
                    pendenza.iuvAvviso ?
                    <>
                        <Row style={{marginBottom: 0}}>
                            <Col>
                                <b>Tipo di Pagamento:</b> Dovuto
                            </Col>
                        </Row>
                        <Row style={{marginBottom: 0}}>
                            <Col>
                                <b>Identificativo Univoco Versamento (IUV):</b> {pendenza.iuvAvviso}
                            </Col>
                        </Row>
                        <Row style={{marginBottom: 0}}>
                            <Col>
                                <b>Codice Avviso:</b> {pendenza.numeroAvviso}
                            </Col>
                        </Row>
                    </> :
                    <>
                        <Row style={{marginBottom: 0}}>
                            <Col>
                                <b>Tipo di Pagamento:</b> Spontaneo
                            </Col>
                        </Row>
                        <Row style={{marginBottom: 0}}>
                            <Col>
                                <b>Identificativo Univoco Versamento (IUV):</b> {pendenza.iuvPagamento}
                            </Col>
                        </Row>
                    </>
                }                               
                <Row style={{marginBottom: 0}}>
                    <Col>
                        <b>Stato:</b> {pendenza.stato}
                    </Col>
                </Row>
                <Row style={{marginBottom: 0}}>
                    <Col>
                        <b>Beneficiario:</b> {pendenza.dominio.ragioneSociale} ({pendenza.dominio.idDominio})
                    </Col>
                </Row>
                <Row style={{marginBottom: 0}}>
                    <Col>
                        <b>Servizio:</b> {pendenza.tipoPendenza.descrizione}
                    </Col>
                </Row>
                <Row style={{marginBottom: 0}}>
                    <Col>
                        <b>Data di caricamento:</b> {pendenza.dataCaricamento}
                    </Col>
                </Row>
                <Row style={{marginBottom: 0}}>
                    <Col>
                        <b>Data di validità:</b> {pendenza.dataValidita}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <b>Data di pagamento:</b> {pendenza.dataPagamento}
                    </Col>
                </Row>
                <Row style={{marginBottom: 0}}>
                    <Col>
                        <Button disabled={pendenza.stato === 'ESEGUITA'} onClick={() => {inviaAvviso(pendenza?.dominio?.idDominio, pendenza?.numeroAvviso, pendenza?.soggettoPagatore?.identificativo)}}>PAGA ADESSO</Button>
                    </Col>
                    <Col>
                        <Button disabled={pendenza.stato === 'ESEGUITA'} onClick={() => {scaricaAvviso(pendenza?.dominio?.idDominio, pendenza?.numeroAvviso)}}>SCARICA AVVISO PAGAMENTO</Button>
                    </Col>
                    <Col>
                        <Button disabled={pendenza.stato === 'NON_ESEGUITA' || pendenza?.rpp[0]?.rt?.datiPagamento?.CodiceContestoPagamento === "n/a"} onClick={() => {scaricaRicevuta(pendenza?.dominio?.idDominio, pendenza?.iuvPagamento, pendenza?.rpp[0]?.rt?.datiPagamento?.CodiceContestoPagamento)}}>SCARICA RICEVUTA PAGAMENTO</Button>
                    </Col>
                </Row> 
            </Card>
        </>
    );
}