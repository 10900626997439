import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import 'materialize-css'
import { Container, Row, Col, Card, Button } from 'react-materialize'
import { HeaderCittadino } from '../components/HeaderCittadino'
import { PageFooter } from "../components/PageFooter"
import { GlobalContext } from '../context/GlobalState'
import { useTokenCittadino } from "../components/hooks/useTokenCittadino"

export const ConfermaDatiPagamentoPage = () => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const {token} = useTokenCittadino()
    const history = useHistory()
    const [autorizzato, setAutorizzato] = useState(false)
    const [elencoEnti, setElencoEnti] = useState([])

    const { 
        strutturaSelettori, 
        provincia, 
        ente, 
        nuovaPendenza, 
        carrello,
        addPendenzaCarrello,
        cittadino 
    } = useContext(GlobalContext)

    if (!nuovaPendenza) {
        // REDIRECT
    }

    useEffect(() => {
        let elenco = []
        let province = Object.keys(strutturaSelettori)
        province.forEach(provincia => {
            strutturaSelettori[provincia].enti.forEach(item => {
                elenco.push(item.idDominio)
            })
        })
        setElencoEnti(elenco)
    }, [strutturaSelettori])

    const checkAutorizzato = () => {
        fetch(process.env.REACT_APP_SERVER_URL + 'cittadino/check_autorizzato', {
            method: 'POST',
            body: JSON.stringify({
                token,
                piva: nuovaPendenza.datiPendenza.soggettoPagatore.identificativo
            })
        })
            .then(res => res.json())
            .then(res => { setAutorizzato(res) })
    }

    useEffect(() => {
        if (token && nuovaPendenza) {
            checkAutorizzato()
        }
    }, [token, nuovaPendenza, checkAutorizzato])

    const checkAmministratore = () => {
        if (!cittadino?.permessi) {
            return false
        }

        if (cittadino?.permessi[0]?.ENTE === "TUTTI" && cittadino?.permessi[0]?.RUOLI[0].tipo === 'Amministratore') {
            return elencoEnti.includes(nuovaPendenza.datiPendenza.soggettoPagatore.identificativo.toUpperCase())
        }
        
        let enti = cittadino?.permessi?.filter(item => item.RUOLI[0].tipo === 'Amministratore')?.map(item => item.CF_PIVA_ENTE)
        return enti.includes(nuovaPendenza.datiPendenza.soggettoPagatore.identificativo.toUpperCase())
    }

    const checkDelegato = () => {
        if (!cittadino?.permessi) {
            return false
        }

        let enti = cittadino?.permessi?.filter(item => item.RUOLI[0].tipo === 'DelegatoStampa')?.map(item => item.CF_PIVA_ENTE)
        return enti.includes(nuovaPendenza.datiPendenza.soggettoPagatore.identificativo.toUpperCase())
    }

    const autorizzaStampa = () => {
        console.log('autorizzato', autorizzato)
        console.log('checkAmministratore', checkAmministratore())
        console.log('checkDelegato', checkDelegato())
        let autorizza = token && (cittadino?.cf === nuovaPendenza.datiPendenza.soggettoPagatore.identificativo.toUpperCase() 
                                    || autorizzato 
                                    || checkAmministratore() 
                                    || checkDelegato())
        if (debug) console.log("AUTORIZZA", autorizza)
        return autorizza
    }

    const goForward = () => {
        addPendenzaCarrello(nuovaPendenza)
        history.push('/carrello')
    }

    const goBack = () => {
        history.goBack()
    }

    const scaricaAvviso = (idDominio, numeroAvviso) => {
        let url = process.env.REACT_APP_SERVER_URL + 'avvisi/' + idDominio + '/' + numeroAvviso + '?richiedente=' + cittadino.cf
        if (debug) console.log(url)
        fetch(url, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/text'
            }
        })
            .then(res => res.text())
            .then(res => {
                if (debug) console.log(res)
                window.open(process.env.REACT_APP_SERVER_URL + 'pdf_avvisi/' + res)
                history.push("/esito_avviso?esito=OK")
            })
    }

    const aggiungiPendenza = () => {
        fetch(process.env.REACT_APP_SERVER_URL + 'cittadino/carica_pendenza/' + nuovaPendenza.datiPendenza.idPendenza, {
            method: 'POST',
            body: JSON.stringify({token, pendenza: nuovaPendenza.datiPendenza, richiedente: cittadino.cf})
        })
            .then(response => response.json())
            .then(data => {                
                if (debug) console.log("AGGIUNGI PENDENZA", data)
                let { idDominio, numeroAvviso } = data.result
                if ( idDominio && numeroAvviso) {
                    scaricaAvviso(idDominio, numeroAvviso)
                } else {
                    history.push("/esito_avviso?esito=KO")
                }
            });
    }
    
    const getNomeEnte = () => {
        return ente !== null ? strutturaSelettori[provincia].enti.filter(item => item.idDominio === ente)[0].ragioneSociale : ""
    }

    return (
        <Container>
            <HeaderCittadino history={history} />
            {
                nuovaPendenza ?
                <>
                    <Row>
                        <Col s={12}>
                            <Card title="Soggetto pagatore">
                                <Row>
                                    <Col><b>CF / P.IVA:</b></Col>
                                    <Col>{nuovaPendenza.datiPendenza.soggettoPagatore.identificativo}</Col>
                                </Row>
                                <Row>
                                    <Col><b>Anagrafica:</b></Col>
                                    <Col>{nuovaPendenza.datiPendenza.soggettoPagatore.anagrafica}</Col>
                                </Row>
                                <Row>
                                    <Col><b>Indirizzo:</b></Col>
                                    {
                                        nuovaPendenza.datiPendenza.soggettoPagatore.indirizzo ?
                                        <Col>
                                            { nuovaPendenza.datiPendenza.soggettoPagatore.indirizzo + (nuovaPendenza.datiPendenza.soggettoPagatore.civico ? ", " + nuovaPendenza.datiPendenza.soggettoPagatore.civico : "") }
                                            <br />
                                            { (nuovaPendenza.datiPendenza.soggettoPagatore.cap ? nuovaPendenza.datiPendenza.soggettoPagatore.cap : "") + (nuovaPendenza.datiPendenza.soggettoPagatore.cap && nuovaPendenza.datiPendenza.soggettoPagatore.localita ? ", " : "") + (nuovaPendenza.datiPendenza.soggettoPagatore.localita ? nuovaPendenza.datiPendenza.soggettoPagatore.localita + " " : "") + (nuovaPendenza.datiPendenza.soggettoPagatore.provincia ? "(" + nuovaPendenza.datiPendenza.soggettoPagatore.provincia + ")" : "")}
                                        </Col> :
                                        <></>
                                    }
                                </Row>
                            </Card>
                            <Card title="Dati pagamento">
                                <Row>
                                    <Col><b>Ente:</b></Col>
                                    <Col>{getNomeEnte()}</Col>
                                </Row>
                                <Row>
                                    <Col><b>Descrizione:</b></Col>
                                    <Col>{nuovaPendenza.datiPendenza.voci[0].descrizione}</Col>
                                </Row>
                                <Row>
                                    <Col><b>Causale:</b></Col>
                                    <Col>{nuovaPendenza.datiPendenza.causale}</Col>
                                </Row>
                                <Row>
                                    <Col><b>Importo:</b></Col>
                                    <Col>€{nuovaPendenza.datiPendenza.importo.toFixed(2)}</Col>
                                </Row>
                            </Card>
                            {
                                token ? <></> : <h4>NB: utilizzando l'autenticazione SPID puoi generare un avviso di pagamento per il tuo Codice Fiscale/Partita IVA e pagarlo in un secondo momento dove preferisci.</h4>
                            }
                            {
                                autorizzaStampa() ?
                                <Row>
                                    <Col s={12} m={4}>
                                        <Button node="button" waves="light" style={{width:'100%'}} large onClick={goBack}>MODIFICA I DATI</Button>
                                    </Col>
                                    <Col s={12} m={4}>
                                        <Button node="button" waves="light" style={{width:'100%'}} large onClick={aggiungiPendenza}>GENERA AVVISO</Button>
                                    </Col>
                                    {
                                        carrello.length > 4 ?
                                        <Col s={12} m={4}>
                                            <Button node="button" style={{width:'100%'}} large disabled>CARRELLO PIENO</Button>
                                        </Col> :
                                        <Col s={12} m={4}>
                                            <Button node="button" waves="light" style={{width:'100%'}} large onClick={goForward}>AGGIUNGI AL CARRELLO</Button>
                                        </Col>
                                    }
                                </Row> :
                                <Row>
                                    <Col s={12} m={6}>
                                        <Button node="button" waves="light" style={{width:'100%'}} large onClick={goBack}>MODIFICA I DATI</Button>
                                    </Col>
                                    {
                                        carrello.length > 4 ?
                                        <Col s={12} m={6}>
                                            <Button node="button" style={{width:'100%'}} large disabled>CARRELLO PIENO</Button>
                                        </Col> :
                                        <Col s={12} m={6}>
                                            <Button node="button" waves="light" style={{width:'100%'}} large onClick={goForward}>AGGIUNGI AL CARRELLO</Button>
                                        </Col>
                                    }
                                </Row>
                            }
                        </Col>
                    </Row>
                </> :
                <Row>
                    <Col s={12}>
                        <Card>
                            <Row>
                                <Col>
                                    <p>NESSUN PAGAMENTO DA CONTROLLARE</p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            }
            <PageFooter />
        </Container>
    );
}