import React, { useState, useContext, useRef, forwardRef, useEffect } from 'react'
import 'materialize-css'
import { Modal, Button, Row, Col, ProgressBar, TextInput } from 'react-materialize'

import { GlobalContext } from '../context/GlobalState'

import { useTokenCittadino } from "./hooks/useTokenCittadino"

const CloseButton = forwardRef((props, ref) => {
    return <Button ref={ref} {...props} flat modal="close" node="button" waves="green">{props.children}</Button>
})


export const ModalConfermaIncasso = ({ redirect, rendicontazione, incassato }) => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const { datiEnte, cittadino } = useContext(GlobalContext);
    const { token } = useTokenCittadino()
    const [ loading, setLoading ] = useState(false)
    const [ done, setDone ] = useState(false)
    const [ modalOpen, setModalOpen ] = useState(false)

    const closeButtonRef = useRef()
    // const testRef = useRef()

    useEffect(() => {
        console.log(closeButtonRef.current)
        // console.log(testRef.current)
    }, [])

    const initialData = {
        importo: rendicontazione.importoTotale,
        dataValuta: rendicontazione.dataRegolamento,
        dataContabile: rendicontazione.dataRegolamento,        
        sct: rendicontazione.trn,
        idFlusso: rendicontazione.idFlusso
    }

    const [ riconciliazione, setRiconciliazione ] = useState(initialData)


    const handleChange = (e) => {
        setRiconciliazione({
            ...riconciliazione,
            [e.target.id]: e.target.value
        });
    }


    const inviaRiconciliazione = async () => {
        setLoading(true)
        console.log('SEND RICONCILIAZIONE', riconciliazione)
        console.log(closeButtonRef.current)
        let response = await fetch(process.env.REACT_APP_SERVER_URL + 'ente/riconciliazioni/' + datiEnte.idDominio, {
            method: 'POST',
            body: JSON.stringify({token, riconciliazione, richiedente: cittadino.cf})
        })

        let res = await response.json()

        if (debug) console.log(res)
        if (res.valid) {
            //if (riconciliazioni !== res.result) setRiconciliazioni(res.result)
            //redirect("/gestione_ente/rendicontazioni/" + rendicontazione.idFlusso)
            //console.log(closeButtonRef.current)
            setLoading(false)
            setDone(true)
        } else {
            redirect("/")
        }
    }


    return (
        <Modal
            actions={[
                // <button ref={closeButtonRef} flat modal="close" node="button" waves="green" onClick={ () => { setModalOpen(false) } }>CHIUDI</button>
                <Button flat modal="close" node="button" waves="green">CHIUDI</Button>
                // <CloseButton ref={closeButtonRef}>CHIUDI</CloseButton>,
                // <input ref={testRef} />
            ]}
            bottomSheet={false}
            fixedFooter={false}
            header="DATI DI INCASSO"
            id="Modal_DatiIncasso"
            open={modalOpen}
            options={{
                dismissible: true,
                endingTop: '10%',
                inDuration: 250,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                opacity: 0.5,
                outDuration: 250,
                preventScrolling: true,
                startingTop: '4%'
            }}
            root={document.body}
            trigger={<Button disabled={!incassato}>CONFERMA INCASSO</Button>}
        >
            <Row>
                <Col s={12}>
                    <TextInput s={12} label="IMPORTO" id="importo" onChange={handleChange} value={riconciliazione.importo} readOnly />
                </Col>
                <Col s={12}>
                    <TextInput s={12} label="DATA VALUTA" id="dataValuta" onChange={handleChange} value={riconciliazione.dataValuta} readOnly />
                </Col>
                <Col s={12}>
                    <TextInput s={12} label="DATA CONTABILE" id="dataContabile" onChange={handleChange} value={riconciliazione.dataContabile} readOnly />
                </Col>
                <Col s={12}>
                    <TextInput s={12} label="SCT" id="sct" onChange={handleChange} value={riconciliazione.sct} readOnly />
                </Col>
                <Col s={12}>
                    <TextInput s={12} label="ID FLUSSO" id="idFlusso" onChange={handleChange} value={riconciliazione.idFlusso} readOnly />
                </Col>
                {
                    done ?
                    <>
                        <h4>OPERAZIONE COMPLETATA</h4>
                    </> :
                    <Col s={12}>
                        <Button style={{width: '100%'}} onClick={inviaRiconciliazione} disabled={loading}>CONFERMA</Button>
                    </Col>
                }
            </Row>
            {
                loading ?
                <Row>
                    <Col s={12}>
                        <ProgressBar />
                    </Col>
                </Row> :
                <></>
            }
        </Modal>
    )
}
