import { useContext } from 'react'

import { useGeneraIdPendenza } from "../hooks/useGeneraIdPendenza"
import { useGetUnitaOperativaPredefinita } from '../hooks/useGetUnitaOperativaPredefinita'

import { GlobalContext } from '../../context/GlobalState'

export const useCreaFlussoGovPay = () => {
    const debug = process.env.REACT_APP_DEBUG === "true"

    const generaIdPendenza = useGeneraIdPendenza()
    const getUnitaOperativaPredefinita = useGetUnitaOperativaPredefinita()

    const { datiEnte } = useContext(GlobalContext);

    const elencoIdPendenza = []

    const flussoCompleto = []

    const chiaviFlussoCompleto = [
        "idA2A",
        "idPendenza",
        "idDominio",
        "numeroAvviso",
        "tipoPendenza",
        "idUnitaOperativa",
        "causale",
        "annoRiferimento",
        "cartellaPagamento",
        "datiAllegati",
        "direzione",
        "divisione",
        "importo",
        "dataValidita",
        "dataScadenza",
        "tassonomiaAvviso",
        "tipoSoggettoPagatore",
        "identificativoPagatore",
        "anagraficaPagatore",
        "indirizzoPagatore",
        "civicoPagatore",
        "capPagatore",
        "localitaPagatore",
        "provinciaPagatore",
        "nazionePagatore",
        "emailPagatore",
        "cellularePagatore",
        "idVoce1",
        "importoVoce1",
        "descrizioneVoce1",
        "ibanAccreditoVoce1",
        "ibanAppoggioVoce1",
        "tipoContabilitaVoce1",
        "codiceContabilitaVoce1",
        "tipoEntrataVoce1",
        "tipoBolloVoce1",
        "hashBolloVoce1",
        "provinciaBolloVoce1",
        "idVoce2",
        "importoVoce2",
        "descrizioneVoce2",
        "ibanAccreditoVoce2",
        "ibanAppoggioVoce2",
        "tipoContabilitaVoce2",
        "codiceContabilitaVoce2",
        "tipoEntrataVoce2",
        "tipoBolloVoce2",
        "hashBolloVoce2",
        "provinciaBolloVoce2",
        "idVoce3",
        "importoVoce3",
        "descrizioneVoce3",
        "ibanAccreditoVoce3",
        "ibanAppoggioVoce3",
        "tipoContabilitaVoce3",
        "codiceContabilitaVoce3",
        "tipoEntrataVoce3",
        "tipoBolloVoce3",
        "hashBolloVoce3",
        "provinciaBolloVoce3",
        "idVoce4",
        "importoVoce4",
        "descrizioneVoce4",
        "ibanAccreditoVoce4",
        "ibanAppoggioVoce4",
        "tipoContabilitaVoce4",
        "codiceContabilitaVoce4",
        "tipoEntrataVoce4",
        "tipoBolloVoce4",
        "hashBolloVoce4",
        "provinciaBolloVoce4",
        "idVoce5",
        "importoVoce5",
        "descrizioneVoce5",
        "ibanAccreditoVoce5",
        "ibanAppoggioVoce5",
        "tipoContabilitaVoce5",
        "codiceContabilitaVoce5",
        "tipoEntrataVoce5",
        "tipoBolloVoce5",
        "hashBolloVoce5",
        "provinciaBolloVoce5",
        "dataAvvisatura",
        "idDocumento",
        "descrizioneDocumento",
        "numeroRata",
        'linguaSecondaria'
    ]


    flussoCompleto.push(chiaviFlussoCompleto)


    const checkCampiFlussoRidotto = (row) => {
        row.forEach(campo => {
            if (chiaviFlussoCompleto.indexOf(campo) === -1) return false
        })

        return true
    }


    const parseData = (dataString) => {
        let parseData = ''
        
        if (typeof dataString === 'object') {
            let data = new Date(dataString);
    
            let anno = data.getFullYear()
            let mese = data.getMonth().toString().padStart(2, '0')
            let giorno = data.getDate().toString().padStart(2, '0')
    
            parseData = anno + '-' + mese + '-' + giorno
        }

        return parseData
    }


    const creaFlussoGovPay = (rows, servizio) => {
        let chiaviFlussoRidotto = rows[0]
        let checkFlusso = checkCampiFlussoRidotto(chiaviFlussoRidotto)
        let unitaOperativa = getUnitaOperativaPredefinita(datiEnte.idDominio, servizio)

        if (debug) console.log('checkFlusso', checkFlusso)

        if (checkFlusso) {
            for (let i = 0; i < rows.length; i++) {
                if (i !== 0) {
                    const row = rows[i];
    
                    const sourceRecord = {}
                    const targetRecord = {}
    
                    for (let k = 0; k < row.length; k++) {
                        const value = row[k]                    
                        sourceRecord[chiaviFlussoRidotto[k]] = value
                    }
    
                    for (let y = 0; y < chiaviFlussoCompleto.length; y++) {
                        const key = chiaviFlussoCompleto[y];
                        let value = sourceRecord[key] ? sourceRecord[key] : ""
                        console.log('value', value)

                        if (typeof value === 'string' || value instanceof String) value.replaceAll(',', '').replaceAll('"', "'")

                        if (key === 'dataValidita' || key === 'dataScadenza') value = parseData(value)

                        targetRecord[key] = value
                    }
    
                    let importo = Number(Number(sourceRecord["importo"]).toFixed(2))
                    let idPendenza = generaIdPendenza('', true)

                    while (elencoIdPendenza.indexOf(idPendenza) != -1) {
                        idPendenza = generaIdPendenza('', true)
                    }

                    elencoIdPendenza.push(idPendenza)
    
                    if (debug) console.log("ID PENDENZA", idPendenza)
    
                    targetRecord["idA2A"] = "CPP"
                    targetRecord["idDominio"] = datiEnte.idDominio
                    targetRecord["idPendenza"] = idPendenza
                    targetRecord["importo"] = importo
                    targetRecord["idVoce1"] = idPendenza
                    targetRecord["tipoEntrataVoce1"] = servizio.id
                    targetRecord["descrizioneVoce1"] = servizio.descrizione
                    targetRecord["importoVoce1"] = importo

                    if (targetRecord["idUnitaOperativa"] === "" && unitaOperativa.length) {
                        targetRecord["idUnitaOperativa"] = unitaOperativa
                    }
    
                    flussoCompleto.push(targetRecord)
                }            
            }

            var csv = ""

            for (var i = 0; i < flussoCompleto.length; i++) {
        
                var line = ""
                var row = flussoCompleto[i]
        
                if (i === 0) {
                    row.forEach(item => {
                        line += (item + ",")
                    });
                } else {
                    chiaviFlussoCompleto.forEach(key => {
                        line += (row[key] + ",")
                    });            
                }
        
                line += "\r\n"
        
                csv += line
            }

            return { csv, success: true, message: 'FLUSSO CONVERTITO' }
        } else {
            return { flussoCompleto, success: false, message: 'CAMPI NEL FLUSSO DI ORIGINE NON CONFORMI' }
        }
    }


    return creaFlussoGovPay
}