import React, { useContext } from 'react'
import 'materialize-css'
import { Row, Col, Card, Button } from 'react-materialize'

import { GlobalContext } from '../../context/GlobalState';

export const GestioneEnteContiAccredito = ({dettaglio, redirect, match}) => {
    // const debug = process.env.REACT_APP_DEBUG === "true"

    
    const { datiEnte } = useContext(GlobalContext);

    const codiceEnte = datiEnte?.idDominio;

    if (codiceEnte && dettaglio) {   
        let url = '/gestione_ente/contiaccredito'
        redirect(url);
    }

    return (
        <>
            <Row>
                <Col>
                    <h4 style={{marginTop: 0}}>Conti di accredito</h4>
                    <p>Conti trovati: { datiEnte?.contiAccredito ? datiEnte?.contiAccredito?.length : "0" }</p>
                </Col>
            </Row>
            {
                datiEnte?.contiAccredito?.map(
                    (item, i) => (<Card key={i}>
                        <Row style={{marginBottom: '5px'}}>
                            <Col>
                                <b>IBAN:</b>
                            </Col>
                            <Col>
                                {item.iban}
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '5px'}}>
                            <Col>
                                <b>mybank:</b> {item.mybank ? "SI" : "NO"}
                            </Col>
                            <Col>
                                <b>postale:</b> {item.postale ? "SI" : "NO"}
                            </Col>
                            <Col>
                                <b>abilitato:</b> {item.abilitato ? "SI" : "NO"}
                            </Col>
                        </Row>
                    </Card>) 
                )
            }
        </>
    );
}