import React, { useContext, useEffect, useState, useCallback, forwardRef } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import 'materialize-css'
import { Container, Card, Row, Col, Button } from 'react-materialize'
import { HeaderCittadino } from '../components/HeaderCittadino'
import { PageFooter } from '../components/PageFooter'
import { GlobalContext } from '../context/GlobalState'
import { GestioneCittadinoAnagrafica } from '../components/cittadino/GestioneCittadinoAnagrafica'
import { GestioneCittadinoPagamenti } from '../components/cittadino/GestioneCittadinoPagamenti'
import { GestioneCittadinoPendenze } from '../components/cittadino/GestioneCittadinoPendenze'
import { useTokenCittadino } from "../components/hooks/useTokenCittadino"
import { useCheckToken } from "../components/hooks/useCheckToken"
import { SelezionaPaginaCittadino } from "../components/SelezionaPaginaCittadino"


export const GestioneCittadinoPage = () => {
    const debug = process.env.REACT_APP_DEBUG === "true"
    const { token } = useTokenCittadino()
    const checkToken = useCheckToken()
    const { cittadino, datiEnte } = useContext(GlobalContext)
    const  [ amministratore, setAmministratore ] = useState(false)

    const history = useHistory()
    const match = useRouteMatch()

    const sezione = match.params.sezione
    const dettaglio = match.params.dettaglio

    const vaiAllaSezione = (url) => {
        history.push(url);
    }


    const checkEnteSingolo = async (permesso) => {
        console.log('caso boh', permesso)

        if (permesso.ENTE === 'TUTTI') return true

        let ruoli = permesso?.RUOLI
        let ammininistratore = false

        for (let i = 0; i < ruoli.length; i++) {
            let ruolo = ruoli[0]
            if (ruolo.tipo !== 'DelegatoStampa') {
                let idDominio = permesso?.CF_PIVA_ENTE
                let response = await fetch(process.env.REACT_APP_SERVER_URL + 'domini/' + idDominio + '?richiedente=' + cittadino.cf)
                let res = await response.json()
                if (res.abilitato) {
                    ammininistratore = true
                }
            }
        }

        return ammininistratore
    }


    useEffect(() => {
        if (token) {
            checkToken()
        } else {
            localStorage.clear()
            history.push("/")
        }
    }, [token])


    if (debug) console.log('CITTADINO', cittadino)


    const getAmministratore = useCallback(async () => {
        if (cittadino) {
            console.log('caso 0')
            if (!cittadino.permessi) {
                setAmministratore(false)
            } else if (cittadino.permessi.length === 1) {
                if (cittadino.permessi[0].ENTE === 'TUTTI') {
                    setAmministratore(true)
                } else {
                    let caso = await checkEnteSingolo(cittadino.permessi[0])
                    console.log({caso})
                    setAmministratore(caso)
                } 
            } else {
                let filter = []

                for (let i = 0; i < cittadino.permessi.length; i++) {
                    let permesso = cittadino.permessi[i]
                    if (permesso.RUOLI.length === 1) {
                        let caso = await checkEnteSingolo(permesso)
                        console.log({caso})
                        if (caso) filter.push(permesso)
                    } else {
                        return filter.push(permesso)
                    }
                }

                console.log('caso', filter)

                return setAmministratore(filter.length > 0) 
            }
        }
    }, [cittadino])


    useEffect(() => {
        getAmministratore()
    }, [getAmministratore])


    const checkContent = () => {
        if (sezione) {
            switch (sezione) {
                case 'anagrafica':
                    return (<GestioneCittadinoAnagrafica dettaglio={dettaglio} redirect={vaiAllaSezione} />)
                case 'pagamenti':
                    return (<GestioneCittadinoPagamenti dettaglio={dettaglio} redirect={vaiAllaSezione} />)
                case 'pendenze':
                    return (<GestioneCittadinoPendenze dettaglio={dettaglio} redirect={vaiAllaSezione} />)               
                default:
                    history.push('/')
            }               
        } else {
            return (<p>DEFAULT</p>)
        }
    }


    return (
        <Container>
            <HeaderCittadino history={history} />
            <Row>
                <Col s={12} l={9}>
                    <h2>{cittadino?.nome} {cittadino?.cognome}</h2>
                </Col>                
                {
                    amministratore ?
                    <Col s={12} l={3}>
                        <Button style={{width: '100%'}} onClick={() => history.push('/gestione_ente/anagrafica')}>AMMINISTRAZIONE</Button>
                    </Col> :
                    <></>
                }               
                <Col s={12}>
                    <Card>
                        <p>Tutti i dati sono reperibili anche sull'<b>APP IO</b> alla sezione "PORTAFOGLIO/LE TUE OPERAZIONI"</p>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col s={12}>
                    <Card className="teal lighten-2" title="SELEZIONA UNA SEZIONE">
                        <Row style={{margin: 0}}>
                            <Col s={12}>
                                <SelezionaPaginaCittadino sezione={sezione} navigaVerso={vaiAllaSezione} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col s={12}>
                    { checkContent() }
                </Col>
            </Row>

            <PageFooter />
        </Container>
    );
}