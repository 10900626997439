import React from 'react'
import 'materialize-css'
import { Select } from 'react-materialize'

export const SelezionaPaginaCittadino = ({sezione, navigaVerso, onChange}) => {
    // const debug = process.env.REACT_APP_DEBUG === "true"

    const vaiAllaSezione = (sezione) => {
        let url = ""
        if (sezione === 'paga') {
            url = '/pagamento'
        } else {
            url = '/gestione_cittadino/' + sezione
        }
        navigaVerso(url);
    }

    return (
        <Select
            s={12} 
            m={12}
            id="SelezionaProvincia"
            multiple={false}
            onChange={ e => { vaiAllaSezione(e.target.value); if(onChange) onChange(e.target.value) } }
            options={{
                classes: '',
                dropdownOptions: {
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: false,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                }
            }}
            value={ sezione }
        >
            <option value="anagrafica">Anagrafica</option>
            <option value="paga">Effettua un pagamento</option>
            <option value="pagamenti">Pagamenti effettuati</option>
            <option value="pendenze">Pendenze</option>
        </Select>
    )
}
